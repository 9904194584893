import React from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import { useNodeWorkload } from '../../hooks';

interface PodData {
  name: string;
  phase: string;
}

function parsePodName(name: string) {
  const [namespace, pod] = name.split('/');
  return pod ?? namespace; // If no namespace, the name is the pod name
}

export const PodTable = ({ data, isLoading }: { data: PodData[]; isLoading: boolean }) => {
  const columns: TableColumn<PodData>[] = [
    { title: 'Name', field: 'name', highlight: true },
    { title: 'Phase', field: 'phase' },
  ];

  const showPagination = data.length > 20;

  return (
    <div>
      <Table
        title="Workload"
        columns={columns}
        data={data}
        isLoading={isLoading}
        options={{
          search: true,
          paging: showPagination,
          pageSize: 20,
          padding: 'dense',
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </div>
  );
};

export const NodePodsComponent = ({ id }: { id: string }) => {
  const { data: workload, error, isLoading } = useNodeWorkload(id);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const data = Object.entries(workload ?? {}).map(([name, pod]) => ({
    name: parsePodName(name), // do not show namespaces in the pod name
    phase: pod.phase,
  }));

  return <PodTable data={data} isLoading={isLoading} />;
};
