import useAsyncRetry from 'react-use/lib/useAsyncRetry';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '../api/metrics';
import { PrometheusMatrixResultArray } from '../types';
import { isSuccessPrometheusResult, isPrometheusResultType } from '../utils';
import _ from 'lodash';

export interface MetricsData {
  data: any[];
  series: string[];
  labels: { [seriesName: string]: { [labelKey: string]: string } };
}

function resultToGraphData(result: PrometheusMatrixResultArray, dimension?: string): MetricsData {
  const series: string[] = [];
  const labels: { [k: string]: any } = {};
  if (result.length === 0) {
    return { series: series, labels: labels, data: [] };
  }

  const grouper =
    dimension && result.every(it => Object.keys(it.metric).includes(dimension))
      ? dimension
      : Object.keys(result[0].metric)[0];

  const data = _(result)
    .flatMap(it => {
      const dimensionValue = it.metric[grouper] || 'default';
      if (!series.includes(dimensionValue)) {
        series.push(dimensionValue);
      }
      if (!labels[dimensionValue]) {
        labels[dimensionValue] = it.metric;
      }
      return it.values.map(val => ({
        time: val[0],
        [dimensionValue]: parseFloat(val[1]),
      }));
    })
    .groupBy('time')
    .map(_.spread(_.assign))
    .value();
  return { data, series, labels };
}

export function useRangeMetrics({
  query,
  range,
  step,
  dimension,
}: {
  query: string;
  range: {
    hours?: number;
    minutes?: number;
  };
  step?: number;
  dimension?: string;
}) {
  const prometheusApi = useApi(metricsApiRef);

  const {
    value: metrics,
    loading,
    error,
    retry,
  } = useAsyncRetry(async () => {
    const value = await prometheusApi.rangeQuery({ query, range, step });
    if (!isSuccessPrometheusResult(value) || !isPrometheusResultType(value.data, 'matrix')) {
      throw new Error(`Unknown result type: ${value.data?.resultType}`);
    }
    return resultToGraphData(value.data.result, dimension);
  });

  return {
    metrics,
    loading,
    error,
    retry,
  };
}
