import { GroupCalibrationSpecSchema } from '@netinsight/group-calibration-api';
import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import z from 'zod';
import { WrappedErrorSchema } from './types';

const c = initContract();

export const groupCalibrationApi = c.router({
  getGroupCalibrationSpec: {
    method: 'GET',
    path: '/cluster/calibration',
    summary: 'Get calibration schedule',
    responses: {
      [StatusCodes.OK]: GroupCalibrationSpecSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
  updateGroupCalibrationSpec: {
    method: 'POST',
    path: '/cluster/calibration',
    summary: 'Set calibration schedule',
    body: GroupCalibrationSpecSchema,
    responses: {
      [StatusCodes.OK]: GroupCalibrationSpecSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
});
