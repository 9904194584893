import React, { useMemo } from 'react';
import { merge } from 'ts-deepmerge';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useTimeTransferConfig, useTimeNode } from '@netinsight/management-app-common-react';
import { HICC_DEFAULT_CONFIG, HICC_DEFAULT_CONFIG_VIRTUAL_EDGE, HiccConfig } from '@netinsight/crds';
import { TimeNodeHiccConfigForm } from './TimeNodeHiccConfigForm';

export const TimeNodeHiccTab = ({ nodeId }: { nodeId: string }) => {
  const { data: config, isLoading: isConfigLoading, error: configError } = useTimeTransferConfig(nodeId);
  const { data: node, isLoading: isNodeLoading, error: nodeError } = useTimeNode(nodeId);

  const defaultConfig = useMemo(
    () => (node?.spec.nodeType === 'virtual-edge' ? HICC_DEFAULT_CONFIG_VIRTUAL_EDGE : HICC_DEFAULT_CONFIG),
    [node?.spec.nodeType],
  );

  const initialValues = useMemo(
    () => merge(defaultConfig, config?.hicc ?? {}),
    [defaultConfig, config?.hicc],
  ) as HiccConfig;

  const error = configError ?? nodeError;

  if (isConfigLoading || isNodeLoading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="Time transfer configuration">
      <TimeNodeHiccConfigForm nodeId={nodeId} initialValues={initialValues} defaultValues={defaultConfig} />
    </InfoCard>
  );
};
