import React, { FunctionComponent } from 'react';
import { StatusCondition, TimeNodeManifestStatus, TimeNodeLifecycleState } from '@netinsight/crds';
import OfflineIcon from '@material-ui/icons/CloudOff';
import OnlineIcon from '@material-ui/icons/Cloud';
import UnknownIcon from '@material-ui/icons/CloudQueue';
import UpgradeIcon from '@material-ui/icons/CloudUpload';
import RebootIcon from '@material-ui/icons/Loop';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Chip, makeStyles, SvgIconTypeMap } from '@material-ui/core';
import { DateTime } from 'luxon';

const NEVER = new Date(0).toISOString();

export type NodeOnlineStatusIconProps = {
  lifecycleState?: TimeNodeLifecycleState;
  timeNodeStatus?: TimeNodeManifestStatus;
  iconOnly?: boolean;
  offlineOnly?: boolean;
};

type StatusEnum = StatusCondition['status'] | Exclude<TimeNodeLifecycleState, 'Up'>;

const Icons: Record<StatusEnum, OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>> = {
  True: OnlineIcon,
  False: OfflineIcon,
  Unknown: UnknownIcon,
  Offboarding: OfflineIcon,
  Onboarding: OnlineIcon,
  Upgrading: UpgradeIcon,
  Rebooting: RebootIcon,
  Rollback: UpgradeIcon,
};

const IconLabels: Record<StatusEnum, string> = {
  True: 'Online',
  False: 'Offline',
  Unknown: 'Unknown status',
  Offboarding: 'Offboarding',
  Onboarding: 'Onboarding',
  Upgrading: 'Upgrading',
  Rebooting: 'Rebooting',
  Rollback: 'Rollback',
};

const useStyles = makeStyles(theme => ({
  offlineIcon: {
    fill: theme.palette.error.main,
  },
  onlineIcon: {
    fill: theme.palette.success.main,
  },
  unknownIcon: {
    fill: theme.palette.grey[500],
  },
  offboardingIcon: {
    fill: theme.palette.grey[400],
  },
  onboardingIcon: {
    fill: theme.palette.grey[300],
  },
  upgradingIcon: {
    fill: theme.palette.info.main,
  },
  rebootingIcon: {
    fill: theme.palette.warning.main,
  },
  chip: {
    margin: theme.spacing(1, 0, 0),
  },
}));

function calculateHeartbeatStatus(heartbeat?: any, lifecycleState?: string): StatusEnum {
  if (lifecycleState === 'Up') {
    if (heartbeat) {
      return heartbeat.status;
    }
  } else if (lifecycleState) {
    return lifecycleState as StatusEnum;
  }
  return 'Unknown';
}

export const NodeOnlineStatusIcon: FunctionComponent<NodeOnlineStatusIconProps> = ({
  timeNodeStatus,
  lifecycleState,
  iconOnly = false,
  offlineOnly = false,
}) => {
  const styles = useStyles();
  const heartbeat = timeNodeStatus?.conditions?.find(condition => condition.type === 'TimeNodeHeartbeat');
  const heartbeatStatus = calculateHeartbeatStatus(heartbeat, lifecycleState);
  const Icon = Icons[heartbeatStatus] ?? Icons.Unknown;

  const iconStyles: Record<StatusCondition['status'], Record<TimeNodeLifecycleState, string>> = {
    True: {
      Up: styles.onlineIcon,
      Onboarding: styles.onboardingIcon,
      Offboarding: styles.offboardingIcon,
      Upgrading: styles.upgradingIcon,
      Rebooting: styles.onlineIcon,
      Rollback: styles.upgradingIcon,
    },
    False: {
      Up: styles.offlineIcon,
      Onboarding: styles.rebootingIcon,
      Offboarding: styles.rebootingIcon,
      Upgrading: styles.rebootingIcon,
      Rebooting: styles.rebootingIcon,
      Rollback: styles.rebootingIcon,
    },
    Unknown: {
      Up: styles.unknownIcon,
      Onboarding: styles.unknownIcon,
      Offboarding: styles.unknownIcon,
      Upgrading: styles.unknownIcon,
      Rebooting: styles.unknownIcon,
      Rollback: styles.unknownIcon,
    },
  };

  const iconLabel = IconLabels[heartbeatStatus] ?? IconLabels.Unknown;
  const onlineStatus = heartbeat?.status === 'True' ? 'Online' : 'Offline';
  const statusSuffix = {
    True: '',
    False: '',
    Unknown: '',
    Offboarding: ` (${onlineStatus})`,
    Onboarding: ` (${onlineStatus})`,
    Upgrading: ` (${onlineStatus})`,
    Rebooting: ` (${onlineStatus})`,
    Rollback: ` (${onlineStatus})`,
  };
  const heartbeatTime = heartbeat?.lastHeartbeatTime;
  let lastObservation = 'N/A';
  if (heartbeatTime && heartbeatTime !== NEVER) {
    lastObservation = DateTime.fromISO(heartbeatTime).toRelative() ?? 'N/A';
  }
  const iconProps = {
    className: iconStyles[heartbeat?.status ?? 'Unknown'][lifecycleState ?? 'Up'],
    titleAccess: `${iconLabel}, last observation: ${lastObservation}${statusSuffix[heartbeatStatus]}`,
    ['data-heartbeat-status']: heartbeatStatus,
  };

  if (offlineOnly === true && !['False', 'Offboarding', 'Onboarding', 'Unknown'].includes(heartbeatStatus)) {
    return null;
  }

  return iconOnly ? (
    <Icon {...iconProps} />
  ) : (
    <Chip
      variant="default"
      color="default"
      className={styles.chip}
      icon={<Icon {...iconProps} />}
      label={iconLabel}
      title={iconProps.titleAccess}
    />
  );
};
