import useSwr from 'swr';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { alarmsApiRef } from '../api/alarms';

export function useAlertGroups({ labels }: { labels?: Record<string, string> } = {}) {
  const alarmsApi = useApi(alarmsApiRef);

  const fetcher = useCallback(
    async ([labelParams]: any) => {
      const filter = Object.entries(labelParams ?? {}).map(([key, value]) => `${key}=${value}`);
      const groups = await alarmsApi.getAlertGroups({ filter });
      return groups.map(x => ({
        ...x,
        alerts: x.alerts.filter(a => a.labels.severity !== 'none' && a.labels.severity !== 'indeterminate'),
      }));
    },
    [alarmsApi],
  );

  return useSwr([labels, 'alertGroups'], fetcher);
}
