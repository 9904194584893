import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { NodeManagerConfig } from '@netinsight/management-app-common-api';
import { DEFAULT_CONFIG } from '@netinsight/node-manager-schema';
import { NodeManagerSpec } from '@netinsight/crds';
import { errorFromWrappedError, k8sConfigApiRef } from '../api';

export { DEFAULT_CONFIG };

export const useNodeManagerConfig = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const configApi = useApi(k8sConfigApiRef);

  const getConfig = useCallback(
    async ([nodeIdParam]: any): Promise<NodeManagerSpec> => {
      const result = await configApi.getNodeManagerConfig({ params: { nodeId: nodeIdParam } });
      let config;
      if (result.status === StatusCodes.OK) {
        config = result.body.config;
      } else if (result.status === StatusCodes.NOT_FOUND) {
        config = DEFAULT_CONFIG;
      } else {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
      return config;
    },
    [configApi],
  );

  const {
    mutate,
    data: config,
    error,
    isLoading: loading,
  } = useSwr([nodeId, 'nodeManagerConfig'], getConfig, swrConfig);

  const uploadConfig = useCallback(
    async (newConfig: NodeManagerConfig) => {
      const result = await configApi.setNodeManagerConfig({ params: { nodeId }, body: newConfig });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
      await mutate();
    },
    [configApi, nodeId, mutate],
  );

  return {
    config,
    error,
    loading,
    uploadConfig,
  };
};
