import React, { useMemo } from 'react';
import { InfoCard, Progress, ResponseErrorPanel, StructuredMetadataTable } from '@backstage/core-components';
import { useSystemSoftware } from '../../hooks';
import { Grid } from '@material-ui/core';

export const VersionsTab = () => {
  const { data, error, loading } = useSystemSoftware();
  const softwareVersions = useMemo(() => {
    return Object.fromEntries(
      Object.entries(data?.services ?? {}).map(([key, { tag }]) => {
        return [key, tag];
      }),
    );
  }, [data]);

  return (
    <div>
      {error && <ResponseErrorPanel error={error} />}
      {loading && <Progress />}
      {data && (
        <Grid container direction="row">
          <Grid item>
            <InfoCard title="Overview">
              <StructuredMetadataTable metadata={data.version} dense />
            </InfoCard>
          </Grid>
          <Grid item>
            <InfoCard title="Components" variant="gridItem">
              <StructuredMetadataTable metadata={softwareVersions} dense />
            </InfoCard>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
