import React from 'react';
import { makeStyles } from '@material-ui/core';
import NetiLogoIcon from '../../assets/logo-icon.svg';

const useStyles = makeStyles({
  logo: {
    width: '4rem',
  },
});

const LogoIcon = () => {
  const classes = useStyles();
  return <img className={classes.logo} src={NetiLogoIcon} alt="Zyntai Director logo" title="Zyntai Director" />;
};

export default LogoIcon;
