import React, { FunctionComponent } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTheme } from '@material-ui/core';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { LogLevelCommon } from '@netinsight/crds';

export const LogLevelWarning: FunctionComponent<{ name: string; control: Control<any, any> }> = ({ name, control }) => {
  const theme = useTheme();
  const field: LogLevelCommon = useWatch({ name, control });
  return field === 'debug' || field === 'trace' ? <WarningRounded htmlColor={theme.palette.warning.main} /> : null;
};
