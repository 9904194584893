import useSwr, { SWRConfiguration } from 'swr';
import { merge as deepMerge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { G } from '@mobily/ts-belt';
import { groupMetrics } from '@netinsight/management-app-common-react';
import { metricsApiRef } from '@netinsight/management-app-common-react';

const CacheKey = 'usePortMetrics';

const toDbm = (input?: string) => {
  if (G.isNullable(input)) {
    return undefined;
  }
  const val = parseFloat(input);
  if (isNaN(val)) {
    return undefined;
  }
  return val === 0 ? 0 : 10 * Math.log10(val / 1e-3);
};

const getMetricUid = (metric: any) => metric.uid;

export const usePortMetrics = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  return useSwr(
    [nodeId, CacheKey],
    async ([nodeIdParam]) => {
      const [
        sfpTempResult,
        sfpTxBiasResult,
        sfpTxPwrResult,
        sfpRxPwrResult,
        sfpRxPacketCountResult,
        macRxPacketErrorCountResult,
        macRxPacketDroppedCountResult,
        macTxPacketCountResult,
        macTxPacketErrorCountResult,
        macTxPacketDroppedCountResult,
      ] = await Promise.all([
        metricsApi.instantQuery({
          query: `neti_sfp_temp{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_sfp_tx_bias{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_sfp_tx_pwr{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_sfp_rx_pwr{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_rx_packet_count{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_rx_error_count{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_rx_dropped_count{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_tx_packet_count{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_tx_error_count{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({
          query: `neti_tx_dropped_count{nodeid="${nodeIdParam}"}`,
        }),
      ]);

      const result = deepMerge(
        groupMetrics(sfpTempResult, getMetricUid, val => ({ transceiverTemp: parseFloat(val) })),
        groupMetrics(sfpTxBiasResult, getMetricUid, val => ({ transmitterBias: parseFloat(val) })),
        groupMetrics(sfpTxPwrResult, getMetricUid, val => ({ transmitterPower: toDbm(val) })),
        groupMetrics(sfpRxPwrResult, getMetricUid, val => ({ receiverPower: toDbm(val) })),
        groupMetrics(sfpRxPacketCountResult, getMetricUid, val => ({ receivedPacketCount: parseInt(val, 10) })),
        groupMetrics(macRxPacketErrorCountResult, getMetricUid, val => ({
          receivedPacketErrorCount: parseInt(val, 10),
        })),
        groupMetrics(macRxPacketDroppedCountResult, getMetricUid, val => ({
          receivedPacketDroppedCount: parseInt(val, 10),
        })),
        groupMetrics(macTxPacketCountResult, getMetricUid, val => ({ transmittedPacketCount: parseInt(val, 10) })),
        groupMetrics(macTxPacketErrorCountResult, getMetricUid, val => ({
          transmittedPacketErrorCount: parseInt(val, 10),
        })),
        groupMetrics(macTxPacketDroppedCountResult, getMetricUid, val => ({
          transmittedPacketDroppedCount: parseInt(val, 10),
        })),
      );
      return result;
    },
    swrConfig,
  );
};

export type PortMetrics = Exclude<ReturnType<typeof usePortMetrics>['data'], undefined>[string];
