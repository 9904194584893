import { createPlugin, createComponentExtension } from '@backstage/core-plugin-api';

export const timeTransferPlugin = createPlugin({
  id: 'node-manager',
});

export const GlobalLinkOptionsPage = timeTransferPlugin.provide(
  createComponentExtension({
    name: 'GlobalLinkOptionsPage',
    component: {
      lazy: () => import('./components/GlobalLinkOptions').then(m => m.GlobalLinkOptionsForm),
    },
  }),
);

export const LinksPage = timeTransferPlugin.provide(
  createComponentExtension({
    name: 'LinksPage',
    component: {
      lazy: () => import('./components/LinksPage').then(m => m.LinksPage),
    },
  }),
);

export const LinkDetailForm = timeTransferPlugin.provide(
  createComponentExtension({
    name: 'LinkDetailForm',
    component: {
      lazy: () => import('./components/LinkDetail').then(m => m.LinkDetailForm),
    },
  }),
);

export const LinkEndpointsDrawer = timeTransferPlugin.provide(
  createComponentExtension({
    name: 'LinkDetail',
    component: {
      lazy: () => import('./components/LinkEndpoints').then(m => m.LinkEndpointsDrawer),
    },
  }),
);

export const NodeCalibration = timeTransferPlugin.provide(
  createComponentExtension({
    name: 'NodeCalibration',
    component: {
      lazy: () => import('./components/NodeCalibration').then(m => m.NodeCalibration),
    },
  }),
);
