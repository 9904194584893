import React from 'react';
import { Page, TabbedLayout } from '@backstage/core-components';
import { HeaderWithAlarms } from '../HeaderWithAlarms/HeaderWithAlarms';
import { AlarmsHistoryTableCard, AlarmsTableCard } from '../AlarmsTableCard';
import { AlarmSubscriptionsList } from '../AlarmSubscription/AlarmSubscriptionsList';

export const AlarmsPage = () => {
  return (
    <Page themeId="home">
      <HeaderWithAlarms title="Alarms" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Active Alarms" tabProps={{ id: 'alarms-active-alarms-tab' }}>
          <AlarmsTableCard />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/history" title="History" tabProps={{ id: 'alarms-history-tab' }}>
          <AlarmsHistoryTableCard />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/subscriptions" title="Subscriptions" tabProps={{ id: 'alarms-subscription-tab' }}>
          <AlarmSubscriptionsList />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
