import { useEffect, useState } from 'react';
import { ProviderContext, useSnackbar } from 'notistack';
import { ISnackActions, SnackActions, SnackActionsNoop } from '../utils';

const genSnackActions = (hook: ProviderContext, noop?: boolean) =>
  noop ? new SnackActionsNoop() : new SnackActions(hook);

export function useSnackbarHelper(noop?: boolean) {
  const snackbarHook = useSnackbar();
  const [snackbar, setSnackbar] = useState<ISnackActions>(genSnackActions(snackbarHook, noop));

  useEffect(() => {
    setSnackbar(genSnackActions(snackbarHook, noop));
  }, [snackbarHook, noop]);

  return {
    snackbar,
  };
}
