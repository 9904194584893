import React from 'react';
import Grid from '@mui/material/Grid';
import { InventoryKinds } from '@netinsight/management-app-common-react';
import { InventoryDBStateView } from './InventoryDBStateView';

export const TimeNodeInventoryDBStateTab = ({ nodeId }: { nodeId: string }) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={4}>
        <InventoryDBStateView nodeId={nodeId} kind={InventoryKinds.SyncStatus} />
      </Grid>
      <Grid item lg={4}>
        <InventoryDBStateView nodeId={nodeId} kind={InventoryKinds.GNSS} />
      </Grid>
      <Grid item lg={4}>
        <InventoryDBStateView nodeId={nodeId} kind={InventoryKinds.SyncdProfileRequest} />
      </Grid>
      <Grid item lg={4}>
        <InventoryDBStateView nodeId={nodeId} kind={InventoryKinds.SyncdProfileDelete} />
      </Grid>
      <Grid item lg={4}>
        <InventoryDBStateView nodeId={nodeId} kind={InventoryKinds.NodeManager} />
      </Grid>
    </Grid>
  );
};
