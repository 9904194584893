import useSwr from 'swr';
import { errorFromWrappedError, k8sNodeApiRef, k8sOnboardingApiRef } from '@netinsight/management-app-common-react';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';
import { OnboardingRequest } from '@netinsight/management-app-common-api';

export type ResolvedOnboardingRequest = OnboardingRequest & { status?: { nodeName?: string } };

export const useOnboardingRequests = () => {
  const onboardingApi = useApi(k8sOnboardingApiRef);
  const nodeApi = useApi(k8sNodeApiRef);

  const fetcher = useCallback(async (): Promise<ResolvedOnboardingRequest[]> => {
    const [onboardingRequestsResponse, nodesResponse] = await Promise.all([
      onboardingApi.listOnboardingRequests({}),
      nodeApi.listNodes({}),
    ]);
    if (onboardingRequestsResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(onboardingRequestsResponse.status, onboardingRequestsResponse.body);
    }
    if (nodesResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(nodesResponse.status, nodesResponse.body);
    }
    return onboardingRequestsResponse.body.map(request => {
      if (request.status.nodeId) {
        const nodeName = nodesResponse.body.items.find(node => node.id === request.status.nodeId)?.spec?.name;
        return { ...request, status: { ...request.status, nodeName: nodeName ?? request.status.nodeId } };
      }
      return request;
    });
  }, [onboardingApi, nodeApi]);

  const result = useSwr('onboardingRequests', fetcher, {
    refreshInterval: 0,
  });

  return result;
};
