import { useCallback, useMemo } from 'react';
import StatusCodes from 'http-status-codes';
import useSwr from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { TimeTransferSpec, TIMETRANSFER_DEFAULT_CONFIG } from '@netinsight/crds';
import { errorFromWrappedError, k8sConfigApiRef } from '../api';
import { useInventory } from './useInventory';
import { NodeState } from '@netinsight/node-manager-schema';
import { InventoryKinds } from '@netinsight/management-app-common-react';

function getPortCount(nodeManagerData?: NodeState.NodeState) {
  const articleNumber = nodeManagerData?.ipmcInventory?.productArticleNumber;
  if (articleNumber === 'NPQ0043-XW01') return 10;
  if (articleNumber === 'NPQ0044-XW01') return 4;

  // virtual nodes shall behave similar to 10 port nodes
  return 10;
}
function getMaxConnections(portCount: number) {
  if (portCount === 4) return 256;
  // portCount === 10
  return 1024;
}

export const useTimeTransferConfig = (nodeId: string) => {
  const configApi = useApi(k8sConfigApiRef);

  const { data: nodeManagerState, isLoading: nodeManagerIsLoading } = useInventory<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });

  const maxConnectionsTotal = useMemo(
    () => getMaxConnections(getPortCount(nodeManagerState?.data)),
    [nodeManagerState],
  );

  const getNodeConfig = useCallback(
    async ([nodeIdParam]: any): Promise<TimeTransferSpec> => {
      const result = await configApi.getNodeTimeTransferConfig({ params: { nodeId: nodeIdParam } });
      if (result.status === StatusCodes.OK) {
        return { ...TIMETRANSFER_DEFAULT_CONFIG, ...result.body.config };
      } else if (result.status === StatusCodes.NOT_FOUND) {
        return TIMETRANSFER_DEFAULT_CONFIG;
      }
      throw errorFromWrappedError(result.status, (result as any).body);
    },
    [configApi],
  );

  const { data, error, isLoading, isValidating } = useSwr([nodeId, 'TimeTransferConfig'], getNodeConfig);

  return {
    data,
    error: error,
    isLoading: isLoading ?? nodeManagerIsLoading,
    isValidating,
    maxConnectionsTotal,
  };
};
