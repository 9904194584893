import useSwr from 'swr';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { alarmsApiRef } from '../api/alarms';
import { AlertMetadata, useSnackbarHelper } from '@netinsight/management-app-common-react';

const key = 'alertMetadataList';

export const useAlertMetadataUpdate = () => {
  const alarmsApi = useApi(alarmsApiRef);
  const { snackbar } = useSnackbarHelper();

  const update = async (_url: string, { arg }: { arg: AlertMetadata[] }) => {
    return alarmsApi.updateAlertMetadata(arg);
  };

  return useSwrMutation(key, update, {
    onSuccess: () => {
      snackbar.success('Acknowledgement update successful');
    },
    onError: error => {
      snackbar.notifyError('Acknowledgement update failed', error.response, null);
    },
  });
};

export function useAlertMetadata() {
  const alarmsApi = useApi(alarmsApiRef);
  return useSwr<AlertMetadata[]>(key, () => {
    return alarmsApi.getAlertMetadata({});
  });
}
