import cx from 'classnames';
import prettyBytes from 'pretty-bytes';
import React, { useMemo } from 'react';
import { Link, ResponseErrorPanel, Table, TableProps, TableColumn } from '@backstage/core-components';
import { makeStyles, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import {
  DurationFormatter,
  GrafanaLink,
  InventoryKinds,
  Meter,
  NodeOnlineStatusIcon,
  SyncActiveStatusIcon,
  ValueOf,
  useAlerts,
  useAllInventories,
  useGrafanaDashboards,
  useTableState,
  useTimeNetworkConfigs,
} from '@netinsight/management-app-common-react';
import { TimeNodeWithManifest, TimeNodesMetrics, useTimeNodes, useTimeNodesMetrics } from '../../hooks';
import { TimeTransferSpec, DEFAULT_SYNC_REGION } from '@netinsight/crds';
import { PpsOutputSource } from '@netinsight/syncd-schema';
import {
  SYNC_SOURCE_LABELS,
  SYNC_SOURCE_NAMES,
  SyncdStatus,
  isSyncSourceUsed,
} from '@netinsight/plugin-sync-inputs-ui';
import {
  SyncRegionSelector,
  compareSyncRegion,
  useFilterBySyncRegion,
  useSyncRegions,
} from '@netinsight/plugin-sync-region-ui';
import { PortStates } from '@netinsight/plugin-ptp-config-ui';
import { G } from '@mobily/ts-belt';

const getTimeNodeRow = ({
  timeNode: {
    id,
    spec: { nodeType, name, syncRegion, lifecycleState },
    manifest,
  },
  timeNodesMetrics,
  alertsCount,
  syncdStatuses,
  timeNetworkConfigs,
}: {
  timeNode: TimeNodeWithManifest;
  timeNodesMetrics?: TimeNodesMetrics;
  alertsCount?: Record<string, number>;
  syncdStatuses?: Record<
    string,
    {
      [InventoryKinds.SyncStatus]: {
        data: SyncdStatus;
      };
    }
  >;
  timeNetworkConfigs?: Record<string, TimeTransferSpec>;
}) => {
  return {
    id,
    name: name ?? id,
    syncRegion: syncRegion ?? DEFAULT_SYNC_REGION,
    nodeType,
    ...(timeNodesMetrics?.[id] ?? {}),
    config: timeNetworkConfigs?.[id] ?? {},
    alertsCount: alertsCount?.[id] ?? 0,
    syncdStatus: syncdStatuses?.[id]?.[InventoryKinds.SyncStatus]?.data,
    lifecycleState,
    heartbeatStatus: manifest?.status,
    heartbeatStatusText: manifest?.status?.conditions?.find(c => c.type === 'TimeNodeHeartbeat')?.status,
  };
};

type TimeNodeRow = ReturnType<typeof getTimeNodeRow>;

const getSorter = (name: ValueOf<typeof SYNC_SOURCE_NAMES>) => (nodeA: TimeNodeRow, nodeB: TimeNodeRow) => {
  const selector = (n: TimeNodeRow) => n.timeErrors?.[name] ?? NaN;
  return selector(nodeA) - selector(nodeB) || Number(isNaN(selector(nodeA))) - Number(isNaN(selector(nodeB)));
};

const ppsErrorSorter = getSorter(SYNC_SOURCE_NAMES.ppsIn);
const gnssErrorSorter = getSorter(SYNC_SOURCE_NAMES.gnss);
const ptp1ErrorSorter = getSorter(SYNC_SOURCE_NAMES.ptp1);
const ptp2ErrorSorter = getSorter(SYNC_SOURCE_NAMES.ptp2);

const CONTROL_STATE_NAMES = {
  '0': 'Holdover',
  '1': 'Abstime Symmetric',
  '2': 'Reltime',
  '3': 'Abstime',
} as const;

const cellStyle = {
  padding: '8px 8px',
};

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  cellIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  warningIcon: {
    fill: theme.palette.warning.main,
  },
  statusWarningIcon: {
    width: '1.25rem',
    marginLeft: '0.125rem',
  },
  okIcon: {
    fill: theme.palette.success.main,
  },
  errorIcon: {
    fill: theme.palette.error.main,
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  cellNoWrap: {
    flexWrap: 'nowrap',
  },
}));

export const TimeNodeListTable = () => {
  const styles = useStyles({});
  const { data: timeNodes, error: timeNodesError, isLoading: isTimeNodesLoading } = useTimeNodes({ interval: 30_000 });
  const { data: syncRegions } = useSyncRegions();
  const { data: timeNetworkConfigs } = useTimeNetworkConfigs();
  const { data: alerts } = useAlerts();
  const { data: timeNodesMetrics } = useTimeNodesMetrics({
    refreshInterval: 30_000,
  });
  const { data: syncdStatuses } = useAllInventories<{ [InventoryKinds.SyncStatus]: { data: SyncdStatus } }>(
    {
      nodeIds: timeNodes?.map(n => n.id) ?? [],
      kinds: [InventoryKinds.SyncStatus],
    },
    {
      refreshInterval: 30_000,
    },
  );
  const { data: grafanaDashboards } = useGrafanaDashboards();
  const controlStateIcons = useMemo(
    () => ({
      '0': <ErrorOutlineIcon className={cx(styles.cellIcon, styles.errorIcon)} />,
      '1': <ScheduleOutlinedIcon className={cx(styles.cellIcon, styles.warningIcon)} />,
      '2': <ScheduleOutlinedIcon className={cx(styles.cellIcon, styles.warningIcon)} />,
      '3': <CheckCircleOutlineIcon className={cx(styles.cellIcon, styles.okIcon)} />,
    }),
    [styles],
  );

  const columns: TableColumn<TimeNodeRow>[] = useMemo(
    () => [
      {
        title: <span style={{ paddingLeft: '12px' }}>Name</span>,
        field: 'name',
        highlight: true,
        defaultSort: 'asc',
        width: '25rem',
        render: row => (
          <Link style={{ whiteSpace: 'nowrap' }} to={`info/${row.id}`} data-testid="btn-node-name">
            {row.name}
          </Link>
        ),
      },
      {
        title: 'Region',
        field: 'syncRegion',
        defaultSort: 'asc',
        customSort: (row1, row2) => compareSyncRegion(row1.syncRegion, row2.syncRegion),
        width: '8rem',
        cellStyle,
      },
      {
        title: 'Online',
        field: 'heartbeatStatusText',
        width: '5rem',
        render: row => (
          <NodeOnlineStatusIcon lifecycleState={row.lifecycleState} timeNodeStatus={row.heartbeatStatus} iconOnly />
        ),
      },
      {
        title: 'Alarms',
        field: 'alertsCount',
        cellStyle,
        width: '5rem',
        render: row =>
          (row.alertsCount ?? 0) > 0 ? (
            <div className={styles.cellContent}>
              <WarningIcon className={styles.warningIcon} />
              <Typography component="span" variant="body1">
                &times; {row.alertsCount}
              </Typography>
            </div>
          ) : null,
      },
      {
        title: 'Inputs',
        cellStyle,
        sorting: false,
        width: '6rem',
        field: 'inputs',
        render: row => (
          <div className={styles.cellContent}>
            {isSyncSourceUsed(row.config?.ppsIn?.usage) && (
              <SyncActiveStatusIcon
                label={SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ppsIn]}
                isActive={row.syncdStatus?.pps_in_active}
              />
            )}
            {isSyncSourceUsed(row.config?.gnss?.usage) && (
              <SyncActiveStatusIcon
                label={SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.gnss]}
                isActive={row.syncdStatus?.gnss_active}
              />
            )}
            {row.config?.syncIn?.selectable && (
              <SyncActiveStatusIcon
                label={SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.syncIn]}
                isActive={row.syncdStatus?.sync_in_active}
              />
            )}
            {isSyncSourceUsed(row.config?.ptpReceiver?.instances?.[0]?.usage) ? (
              <SyncActiveStatusIcon
                label={SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp1]}
                isActive={row.ptpStates?.[SYNC_SOURCE_NAMES.ptp1] === PortStates.TimeReceiver}
              />
            ) : null}
            {isSyncSourceUsed(row.config?.ptpReceiver?.instances?.[1]?.usage) ? (
              <SyncActiveStatusIcon
                label={SYNC_SOURCE_LABELS[SYNC_SOURCE_NAMES.ptp2]}
                isActive={row.ptpStates?.[SYNC_SOURCE_NAMES.ptp2] === PortStates.TimeReceiver}
              />
            ) : null}
            {}
          </div>
        ),
      },
      {
        title: 'Outputs',
        cellStyle,
        sorting: false,
        width: '6rem',
        field: 'outputs',
        render: row => (
          <div className={styles.cellContent}>
            {(row.config?.ptpGm?.instances?.length ?? 0) > 0 ? (
              <SyncActiveStatusIcon label="PTP" showIcon={false} />
            ) : null}
            {row.config?.ppsOut?.source !== undefined && row.config.ppsOut.source !== PpsOutputSource.LogicZero ? (
              <SyncActiveStatusIcon label="PPS" showIcon={false} />
            ) : null}
          </div>
        ),
      },
      {
        title: 'Control State',
        field: 'controlState',
        width: '12.5rem',
        cellStyle,
        render: row => (
          <div className={cx(styles.cellContent, styles.cellNoWrap)}>
            {controlStateIcons[row.controlState as keyof typeof controlStateIcons]
              ? controlStateIcons[row.controlState as keyof typeof controlStateIcons]
              : null}
            <Typography component="span" style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
              {CONTROL_STATE_NAMES[row.controlState as keyof typeof CONTROL_STATE_NAMES] ?? row.controlState}
            </Typography>
          </div>
        ),
      },
      {
        title: 'PPS Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.ppsIn}`,
        customSort: ppsErrorSorter,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.ppsIn])
            ? DurationFormatter.fromSeconds(row.timeErrors[SYNC_SOURCE_NAMES.ppsIn]).toMicroSeconds(3)
            : '',
      },
      {
        title: 'GNSS Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.gnss}`,
        customSort: gnssErrorSorter,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.gnss])
            ? DurationFormatter.fromSeconds(row.timeErrors[SYNC_SOURCE_NAMES.gnss]).toMicroSeconds(3)
            : '',
      },
      {
        title: 'PTP1 Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.ptp1}`,
        customSort: ptp1ErrorSorter,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp1])
            ? DurationFormatter.fromSeconds(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp1]).toMicroSeconds(3)
            : '',
      },
      {
        title: 'PTP2 Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.ptp2}`,
        customSort: ptp2ErrorSorter,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp2])
            ? DurationFormatter.fromSeconds(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp2]).toMicroSeconds(3)
            : '',
      },
      {
        title: 'CPU',
        field: 'systemCpuTime',
        type: 'numeric',
        cellStyle,
        align: 'center',
        width: '10rem',
        hidden: true,
        render: row =>
          G.isNumber(row.systemCpuTime) ? (
            <Meter value={row.systemCpuTime}>{`${(row.systemCpuTime * 100).toFixed(2)}%`}</Meter>
          ) : null,
      },
      {
        title: 'Memory',
        field: 'systemMemoryUsage',
        type: 'numeric',
        cellStyle,
        align: 'center',
        width: '10rem',
        hidden: true,
        render: row =>
          G.isNumber(row.systemMemoryUsage) ? (
            <Meter value={Math.min(row.systemMemoryUsage / 1073741824, 1)}>{prettyBytes(row.systemMemoryUsage)}</Meter>
          ) : null,
      },
      {
        title: 'Temp',
        field: 'boardTemp',
        type: 'numeric',
        cellStyle,
        align: 'center',
        width: '10rem',
        hidden: true,
        render: row =>
          G.isNumber(row.boardTemp) ? (
            <Meter value={Math.min(row.boardTemp / 90, 1)}>{`${row.boardTemp}°C`}</Meter>
          ) : null,
      },
    ],
    [controlStateIcons, styles.cellContent, styles.cellNoWrap, styles.warningIcon],
  );

  const rows: TimeNodeRow[] = useMemo(() => {
    const alertsCount =
      alerts?.reduce(
        (acc, current) => ({ ...acc, [current.labels.nodeid]: (acc[current.labels.nodeid] ?? 0) + 1 }),
        {} as Record<string, number>,
      ) ?? {};

    return (
      timeNodes?.map(timeNode =>
        getTimeNodeRow({ timeNode, timeNetworkConfigs, timeNodesMetrics, syncdStatuses, alertsCount }),
      ) ?? []
    );
  }, [alerts, timeNetworkConfigs, timeNodes, timeNodesMetrics, syncdStatuses]);

  const { items: filteredRows, onSyncRegionChange, syncRegion } = useFilterBySyncRegion(rows, row => [row.syncRegion]);

  const options: TableProps['options'] = useMemo(
    () => ({
      actionsColumnIndex: -1,
      columnsButton: true,
      headerStyle: {
        ...cellStyle,
        textTransform: 'uppercase',
      },
      loadingType: 'linear',
      padding: 'dense',
      pageSize: 20,
      pageSizeOptions: [20, 50, 100],
      paging: (timeNodes?.length ?? 0) > 20,
      showEmptyDataSourceMessage: !isTimeNodesLoading,
      thirdSortClick: false,
      tableLayout: 'fixed' as const,
    }),
    [isTimeNodesLoading, timeNodes],
  );

  const tableStates = useTableState<TimeNodeRow>(columns, options, 'time-node-list-table');

  return (
    <>
      {timeNodesError && <ResponseErrorPanel error={timeNodesError} />}
      <div style={{ display: 'flex' }}>
        <SyncRegionSelector currentValue={syncRegion} syncRegions={syncRegions ?? []} onChange={onSyncRegionChange} />
        <GrafanaLink
          dashboardUrl={grafanaDashboards?.['Zyntai Metrics Overview/time-transfer-multiple-nodes']}
          syncRegions={syncRegion}
        />
      </div>
      <Table<TimeNodeRow>
        isLoading={isTimeNodesLoading}
        data={filteredRows}
        emptyContent={<Typography className={styles.empty}>No timenodes</Typography>}
        {...tableStates}
      />
    </>
  );
};
