import React from 'react';
import { merge as deepMerge } from 'ts-deepmerge';
import { G, S, A, D, pipe, F } from '@mobily/ts-belt';
import { PtpProfileLabels, TT_LINK_DEFAULT_PORT, TimeTransferSpec } from '@netinsight/crds';
import {
  AdminStatus,
  Configuration,
  EthMacSpeed,
  NodeState,
  PhysicalInterfaceDefinition,
  RouteDefinition,
  VlanInterfaceDefinition,
} from '@netinsight/node-manager-schema';
import { ClusterNodeNetwork, NodeManagerConfig, PersistedLinkTarget } from '@netinsight/management-app-common-api';
import type {
  InterfaceInfo,
  InterfaceStates,
  InterfaceUsage,
  NetworkInterfaceStateWithVlan,
  NetworkNamespace,
  NetworkNamespaceConfig,
  NodeManagerConfigWarning,
  NodeManagerInterfacesFormValue,
  RoutingRule,
} from './types';
import {
  DEFAULT_NAMESPACES,
  DEFAULT_NAMESPACE_LABELS,
  ELECTRICAL_IFACES,
  MANAGEMENT_IFACE,
  NodeManagerConfigWarningCodes,
} from './constants';
import { isNullableOrEmpty } from '@netinsight/management-app-common-react';

type NetworkInterfaceConfig = Configuration['interfaces'][number];

const normalizeNamespace = (ns: string) =>
  G.isNullable(ns) || ns === DEFAULT_NAMESPACES.management ? DEFAULT_NAMESPACES.default : ns;

const formatVlan = (ifaceName: string, vlanId: number) => `${ifaceName}.${vlanId}`;

export const formatInterfaceName = (ifaceName: string, vlanId?: number) =>
  G.isNotNullable(vlanId) ? formatVlan(ifaceName, vlanId) : ifaceName;

export const reconcileInterfaceConfigStates = (
  interfaceConfigs?: NetworkInterfaceConfig[],
  sfpStates?: NodeState.Transceiver[],
): NetworkInterfaceConfig[] => {
  if (G.isNullable(sfpStates) || sfpStates.length === 0) {
    return interfaceConfigs ?? [];
  }
  const interfaceConfigsMap = Object.fromEntries(
    interfaceConfigs?.map(ifaceConfig => [
      ifaceConfig.name,
      {
        ...ifaceConfig,
        vlanInterfaces:
          ifaceConfig.vlanInterfaces?.map(vlanConfig => ({
            ...vlanConfig,
            prio: vlanConfig.prio ?? 0,
          })) ?? [],
      },
    ]) ?? [],
  );
  return sfpStates
    .filter(xcvr => G.isNotNullable(xcvr.interfaceName) && S.isNotEmpty(xcvr.interfaceName))
    .map(
      xcvr =>
        interfaceConfigsMap[xcvr.interfaceName] ?? {
          name: xcvr.interfaceName,
          vlanInterfaces: [],
          ipAddress: undefined,
          networkNamespace: DEFAULT_NAMESPACES.default,
          adminStatus: AdminStatus.Down,
          linkSpeed: EthMacSpeed.Speed10G,
        },
    );
};

export const getInterfaceStateMap = (state?: NodeState.NodeState): Record<string, InterfaceStates> => {
  const states = Object.fromEntries(
    state?.interfaces?.map(is => [
      is.name,
      {
        state: {
          ...is,
          vlanStates: Object.fromEntries(
            state?.vlanInterfaces?.filter(vlan => vlan.parentInterfaceUid === is.uid)?.map(vlan => [vlan.name, vlan]) ??
              [],
          ),
        } as NetworkInterfaceStateWithVlan,
      },
    ]) ?? [],
  );
  const xcvrStates = Object.fromEntries(
    state?.transceivers?.map(is => [is.interfaceName, { sfpStates: is as NodeState.SfpTransceiver }]) ?? [],
  );
  return deepMerge(states, xcvrStates);
};

export const getRouteStateByNamespaces = (state?: NodeState.NodeState) =>
  A.groupBy(state?.routes?.routes ?? [], r => r.networkNamespace ?? DEFAULT_NAMESPACES.default) as Record<
    string,
    ReadonlyArray<NodeState.RouteInfo>
  >;

export const getDefaultNamespaceOptions = () =>
  React.createElement(
    React.Fragment,
    {},
    [DEFAULT_NAMESPACES.default, DEFAULT_NAMESPACES.access].map(ns =>
      React.createElement('option', { key: ns, value: ns }, DEFAULT_NAMESPACE_LABELS[ns]),
    ),
  );

export const getNamespaceConfig = (
  interfaceInfos: InterfaceInfo[],
  nodeManagerConfig?: NodeManagerConfig,
): NetworkNamespaceConfig => {
  const interfaceByNamespaces = A.groupBy(interfaceInfos, iface => normalizeNamespace(iface.networkNamespace));
  const allNamespaces = [
    ...new Set<string>(
      interfaceInfos
        .map(iface => normalizeNamespace(iface.networkNamespace))
        .concat(nodeManagerConfig?.namespaces?.map(normalizeNamespace) ?? [])
        .concat([DEFAULT_NAMESPACES.default, DEFAULT_NAMESPACES.access]),
    ).values(),
  ].sort((ns1, ns2) => ns1.localeCompare(ns2));
  const routingConfigsByNamespace = A.groupBy(nodeManagerConfig?.routes ?? [], r =>
    normalizeNamespace(r.networkNamespace),
  );

  return {
    networks: allNamespaces.map<NetworkNamespace>(ns => ({
      name: ns,
      displayName: DEFAULT_NAMESPACE_LABELS[ns] ?? ns,
      interfaces: interfaceByNamespaces[ns]?.map(iface => iface.id) ?? [],
      routes:
        routingConfigsByNamespace[ns]?.map<RoutingRule>(({ network, gateway }) => ({
          network,
          gateway,
        })) ?? [],
    })),
  };
};

export function formatNodeManagerConfig(config: Configuration): Configuration {
  return {
    ...config,
    interfaces: config.interfaces.map(iface => {
      const hasIpAddress = G.isString(iface.ipAddress) && S.isNotEmpty(iface.ipAddress);
      const isNotUsingDHCP = iface.useDhcp !== true;
      return {
        ...iface,
        ipAddress: hasIpAddress && isNotUsingDHCP ? iface.ipAddress : undefined,
      };
    }),
  };
}

export function getNodeManagerConfig(
  config: Configuration,
  { networks: formValues }: NetworkNamespaceConfig,
): Configuration {
  const nsByIfaceNameMap = Object.fromEntries(
    formValues.flatMap(({ name: namespaceName, interfaces }) => interfaces.map(iface => [iface, namespaceName])),
  );
  return {
    ...config,
    interfaces: config.interfaces.map(iface => ({
      ...iface,
      networkNamespace: nsByIfaceNameMap[iface.name] ?? DEFAULT_NAMESPACES.default,
      vlanInterfaces: iface.vlanInterfaces?.map(vlan => ({
        ...vlan,
        networkNamespace: nsByIfaceNameMap[formatVlan(iface.name, vlan.id)] ?? DEFAULT_NAMESPACES.default,
      })),
    })),
    routes: formValues.flatMap(({ name, routes }) =>
      routes.map<RouteDefinition>(({ network, gateway }) => ({ network, gateway, networkNamespace: name })),
    ),
    namespaces: formValues.map(({ name }) => name).filter(n => n !== DEFAULT_NAMESPACES.default),
  };
}

export const isElectrical = (name: string) => ELECTRICAL_IFACES.includes(name);
export const isManagementNamespace = (networkNamespace?: string) =>
  G.isNullable(networkNamespace) ||
  networkNamespace === DEFAULT_NAMESPACES.management ||
  networkNamespace === DEFAULT_NAMESPACES.default;
export const isManagement = ({ networkNamespace, name }: Pick<InterfaceInfo, 'networkNamespace' | 'name' | 'vlanId'>) =>
  isManagementNamespace(networkNamespace) || name === MANAGEMENT_IFACE;

export const areNamespacesEquivalent = (namespace1?: string, namespace2?: string): boolean => {
  return (isManagementNamespace(namespace1) && isManagementNamespace(namespace2)) || namespace1 === namespace2;
};

const mapInterfaces =
  <T>(mapper: (iface: PhysicalInterfaceDefinition, vlanIface?: VlanInterfaceDefinition) => T) =>
  (nodeManagerConfig?: Pick<NodeManagerConfig, 'interfaces'>): T[] =>
    nodeManagerConfig?.interfaces?.flatMap(iface => [
      mapper(iface),
      ...(iface.vlanInterfaces?.map(vlanIface => mapper(iface, vlanIface)) ?? []),
    ]) ?? [];

export const getNodeInterfacePortUsage = (linkTargets?: PersistedLinkTarget[]) =>
  pipe(
    linkTargets ?? [],
    A.groupBy(({ source: { iface, vlanId } }) => formatInterfaceName(iface, vlanId)),
    D.map(lts => lts?.map(({ source: { port = TT_LINK_DEFAULT_PORT } }) => port) ?? []),
  );

export const getNodeInterfaceUsage = ({
  linkTargets,
  nodeManagerConfig,
  timeTransferConfig,
}: {
  linkTargets?: PersistedLinkTarget[];
  nodeManagerConfig?: NodeManagerConfig;
  timeTransferConfig?: TimeTransferSpec;
}): Record<string, InterfaceUsage> => {
  const linkUsage = pipe(
    linkTargets ?? [],
    A.groupBy(({ source: { iface, vlanId } }) => formatInterfaceName(iface, vlanId)),
    D.map(lts => ({
      links:
        lts?.map(({ linkId, name = linkId, source: { port = TT_LINK_DEFAULT_PORT } }) => ({ linkId, port, name })) ??
        [],
    })),
  );
  const synceUsage = pipe(
    nodeManagerConfig,
    mapInterfaces(
      (iface, vlanIface) =>
        [
          formatInterfaceName(iface.name, vlanIface?.id),
          (vlanIface?.useSynce ?? iface.useSynce) === true ? { syncE: true } : {},
        ] as const,
    ),
    D.fromPairs,
  );
  const ptpTransmitterUsage = pipe(
    timeTransferConfig?.ptpGm?.instances ?? [],
    A.map(ptpGmInstance => [ptpGmInstance.interface, { ptpTransmitter: ptpGmInstance.profile }] as const),
    D.fromPairs,
  );
  const ptpReceiverUsage = pipe(
    timeTransferConfig?.ptpReceiver?.instances ?? [],
    A.filter(instance => !isNullableOrEmpty(instance.interface)),
    A.map(client => [client.interface!, { ptpReceiver: client.profile }] as const),
    D.fromPairs,
  );
  return deepMerge(linkUsage, synceUsage, ptpTransmitterUsage, ptpReceiverUsage);
};

export const getInterfaceUsageLinkProps = (
  id: string,
  nodeId: string,
  { links = [], ptpReceiver, ptpTransmitter, syncE }: InterfaceUsage = {},
) => {
  return {
    links: links.map(({ linkId, name }) => ({
      to: `/network/links/${linkId}/endpoints`,
      children: name,
    })),
    ptpReceiver: G.isNotNullable(ptpReceiver)
      ? {
          to: `/nodes/info/${nodeId}/inputs/ptp#${id}`,
          children: PtpProfileLabels[ptpReceiver] ?? ptpReceiver,
        }
      : undefined,
    ptpTransmitter: G.isNotNullable(ptpTransmitter)
      ? {
          to: `/nodes/info/${nodeId}/outputs/ptp#${id}`,
          children: PtpProfileLabels[ptpTransmitter] ?? ptpTransmitter,
        }
      : undefined,
    syncE: syncE === true ? { to: `/nodes/info/${nodeId}/outputs/synce#${id}`, children: 'Enabled' } : undefined,
  };
};

export const isInterfaceUsed = (usageMap?: InterfaceUsage) =>
  G.isNotNullable(usageMap) &&
  ((G.isArray(usageMap.links) && usageMap.links.length > 0) ||
    G.isNotNullable(usageMap.ptpReceiver) ||
    G.isNotNullable(usageMap.ptpTransmitter) ||
    usageMap.syncE === true);

export const getInterfaceInfos = (
  nodeManagerConfig: NodeManagerConfig,
  nodeManagerState?: NodeState.NodeState,
  interfaceUsageMap?: Record<string, InterfaceUsage>,
): InterfaceInfo[] => {
  const interfaceDisplayNameMap = getInterfaceDisplayNameMap(nodeManagerState);
  const interfaceStateMap = D.fromPairs(
    (
      nodeManagerState?.interfaces.map(
        ({ name, operationalStatus, speed }) => [name, { speed, operationalStatus }] as const,
      ) ?? []
    ).concat(
      nodeManagerState?.vlanInterfaces.map(
        ({ name, operationalStatus }) => [name, { operationalStatus, speed: EthMacSpeed.Unknown }] as const,
      ) ?? [],
    ),
  );
  return mapInterfaces((iface, vlanIface) => {
    const interfaceDisplayName = interfaceDisplayNameMap[iface.name] ?? iface.name;
    const id = formatInterfaceName(iface.name, vlanIface?.id);
    return {
      id,
      name: iface.name,
      vlanId: vlanIface?.id,
      displayName: formatInterfaceName(interfaceDisplayName, vlanIface?.id),
      adminStatus: vlanIface?.adminStatus ?? iface.adminStatus,
      networkNamespace: vlanIface?.networkNamespace ?? iface.networkNamespace,
      ip: vlanIface?.ipAddress?.replace(/\/.+/, '') ?? iface.ipAddress?.replace(/\/.+/, ''),
      usage: interfaceUsageMap?.[id] ?? {},
      speed: interfaceStateMap[id]?.speed,
      operationalStatus: interfaceStateMap[id]?.operationalStatus,
    };
  })(nodeManagerConfig);
};

export const getInterfaceDisplayNameMap = (nodeManagerState: NodeState.NodeState | undefined): Record<string, string> =>
  Object.fromEntries(
    nodeManagerState?.transceivers?.map(({ interfaceName, displayName }) => [interfaceName, displayName]) ?? [],
  );

export const getConfigWarnings = ({
  updatedConfig,
  currentNodeId,
  currentConfig,
  timeTransferConfig,
  linkTargets,
  clusterNetwork,
}: {
  updatedConfig: NodeManagerInterfacesFormValue;
  currentNodeId: string;
  currentConfig: NodeManagerConfig;
  timeTransferConfig: TimeTransferSpec;
  linkTargets: PersistedLinkTarget[];
  clusterNetwork: ClusterNodeNetwork;
}): NodeManagerConfigWarning[] => {
  const mapper = (iface: PhysicalInterfaceDefinition, vlanIface?: VlanInterfaceDefinition) =>
    [
      formatInterfaceName(iface.name, vlanIface?.id),
      {
        name: iface.name,
        vlanId: vlanIface?.id,
        adminStatus: vlanIface?.adminStatus ?? iface.adminStatus,
        networkNamespace: vlanIface?.networkNamespace ?? iface.networkNamespace,
        ipAddress: vlanIface?.ipAddress ?? iface.ipAddress,
      },
    ] as const;
  type MappedInterfaceInfo = ReturnType<typeof mapper>[1];
  const currentInterfaces = pipe(currentConfig, mapInterfaces(mapper), D.fromPairs);
  const currentUsageMap = getNodeInterfaceUsage({
    linkTargets,
    nodeManagerConfig: currentConfig,
    timeTransferConfig,
  });
  const newInterfaces = pipe(updatedConfig, mapInterfaces(mapper), D.fromPairs);
  const disabledOrMovedWarnings: NodeManagerConfigWarning[] = pipe(
    newInterfaces,
    D.filterWithKey(
      (id, { adminStatus, networkNamespace }) =>
        ((adminStatus === AdminStatus.Down && currentInterfaces[id]?.adminStatus === AdminStatus.Up) ||
          !areNamespacesEquivalent(networkNamespace, currentInterfaces[id]?.networkNamespace)) &&
        isInterfaceUsed(currentUsageMap[id]),
    ),
    F.coerce<Record<string, MappedInterfaceInfo>>,
    D.values,
    A.map(({ name, vlanId }) => ({
      code: NodeManagerConfigWarningCodes.DisableOrMoved,
      name,
      vlanId,
    })),
    F.toMutable,
  );
  const removedVlanWarnings = pipe(
    currentInterfaces,
    D.filterWithKey(id => isInterfaceUsed(currentUsageMap[id]) && G.isNullable(newInterfaces[id])),
    F.coerce<Record<string, MappedInterfaceInfo>>,
    D.values,
    A.map(({ name, vlanId }) => ({
      code: NodeManagerConfigWarningCodes.Removed,
      name,
      vlanId,
    })),
    F.toMutable,
  );
  const duplicatedIpAddressWarnings: NodeManagerConfigWarning[] = pipe(
    newInterfaces,
    D.filter(cfg => !isNullableOrEmpty(cfg.ipAddress)),
    F.coerce<Record<string, MappedInterfaceInfo>>,
    D.values,
    A.map(({ name: currentName, vlanId: curentVlanId, ipAddress: currentIpAddress }) =>
      pipe(
        clusterNetwork,
        D.mapWithKey((nodeId, { interfaces }) =>
          nodeId !== currentNodeId
            ? pipe(
                interfaces,
                A.filter(({ ip }) => !isNullableOrEmpty(ip) && ip === currentIpAddress?.replace(/\/.+/, '')),
                A.map(({ name, vlanId, ip: ipAddress }) => ({
                  code: NodeManagerConfigWarningCodes.SameIp,
                  name: currentName,
                  vlanId: curentVlanId,
                  ipAddress: currentIpAddress!.replace(/\/.+/, ''),
                  other: {
                    name,
                    vlanId,
                    nodeId,
                    ipAddress: ipAddress!,
                  },
                })),
              )
            : [],
        ),
        D.values,
        A.flat,
        F.toMutable,
      ),
    ),
    A.flat,
    F.toMutable,
  );
  const remainingManagementInterfaces = pipe(
    newInterfaces,
    D.filter(newConfig => isManagement(newConfig) && newConfig.adminStatus === AdminStatus.Up),
    D.keys,
  ).length;

  return [
    ...disabledOrMovedWarnings,
    ...removedVlanWarnings,
    ...duplicatedIpAddressWarnings,
    ...(remainingManagementInterfaces === 0 ? [{ code: NodeManagerConfigWarningCodes.ManagementAccess }] : []),
  ];
};
