import useSWRInfinite from 'swr/infinite';
import { useApi } from '@backstage/core-plugin-api';
import { AlertHistoryOpts, alarmsApiRef } from '../api/alarms';
import { AlertHistoryResponse } from '@netinsight/management-app-common-react';

function getKey(opts: AlertHistoryOpts, shouldFetch: boolean) {
  return (pageIndex: number, previousPageData: AlertHistoryResponse) => {
    if (!shouldFetch) return null;
    if (pageIndex === 0) return opts;

    const { limitDate } = previousPageData;

    const newOpts = {
      ...opts,
      end: limitDate,
    };

    return newOpts;
  };
}

export function useAlertHistory(opts: AlertHistoryOpts, shouldFetch = true) {
  const alarmsApi = useApi(alarmsApiRef);

  const result = useSWRInfinite<AlertHistoryResponse>(getKey(opts, shouldFetch), (keyOpts: any) =>
    alarmsApi.getAlertHistory(keyOpts),
  );

  return result;
}
