import React, { FunctionComponent, ReactNode } from 'react';
import { UseControllerReturn } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import { F, G } from '@mobily/ts-belt';
import { InputTooltip } from '../InputTooltip';

export const useCheckboxFieldStyles = makeStyles(
  (theme: Theme) => ({
    checkboxLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'Neti' },
);

export type CheckboxFieldProps = CheckboxProps & {
  label: ReactNode;
  fieldProps: UseControllerReturn<any, any>;
  fromValue?: (val: any) => boolean | undefined;
  toValue?: (checked?: boolean) => any;
  description?: ReactNode;
};

export const CheckboxField: FunctionComponent<CheckboxFieldProps> = ({
  fieldProps,
  label,
  description,
  fromValue = F.identity,
  toValue = F.identity,
  className,
  ...extraProps
}) => {
  const styles = useCheckboxFieldStyles();
  return (
    <FormControlLabel
      className={className}
      classes={{
        label: styles.checkboxLabel,
      }}
      control={
        <Checkbox
          color="primary"
          {...fieldProps.field}
          checked={fromValue(fieldProps.field.value)}
          onChange={evt => fieldProps.field.onChange(toValue(evt.target.checked))}
          indeterminate={fromValue(fieldProps.field.value) === undefined}
          {...extraProps}
        />
      }
      label={
        <>
          {G.isString(label) ? <span>{label}</span> : label}
          {G.isNotNullable(description) ? <InputTooltip text={description} /> : null}
        </>
      }
    />
  );
};
