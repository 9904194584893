import z from 'zod';
import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import { Configuration, ConfigurationSchema as NodeManagerConfigSchema } from '@netinsight/node-manager-schema';
import { ServiceConfigSchema, WrappedErrorSchema } from './types';
import {
  GlobalLinkOptionSchema,
  TimeTransferSpecSchema,
  TimeNodeSpecSchema,
  GlobalLinkOptions,
} from '@netinsight/crds';

export type { TimeNodeSpec } from '@netinsight/crds';
export { NodeManagerConfigSchema };
export type NodeManagerConfig = Configuration;

const c = initContract();

// TODO: separate SSH keys schema in @netinsight/node-manager-schema and use it here and scheduler service
export const SSHKeysSchema = NodeManagerConfigSchema.shape.system.unwrap().shape.sshKeys.unwrap();
export const SSHKeysConfigSchema = z.object({
  sshKeys: SSHKeysSchema,
});
// define GlobalSSHConfigSchema as an extension in case we need to add more fields in the future
export const GlobalSSHConfigSchema = SSHKeysConfigSchema.extend({});

export type SSHKeysConfig = z.infer<typeof SSHKeysConfigSchema>;
export type GlobalSSHConfig = z.infer<typeof GlobalSSHConfigSchema>;
export const GLOBAL_SSH_DEFAULT_CONFIG: GlobalSSHConfig = { sshKeys: [] };
export const GLOBAL_SSH_CONFIG_NAME = 'global-ssh';
export const GLOBAL_SSH_CONFIG_KEY = 'config';

export const GLOBAL_LINK_OPTIONS: GlobalLinkOptions = {};
export const GLOBAL_LINK_OPTIONS_NAME = 'global-link-options';
export const GLOBAL_LINK_OPTIONS_KEY = 'config';

export const configApi = c.router({
  getNodeManagerConfig: {
    method: 'GET',
    path: '/nodes/:nodeId/node-manager/config',
    summary: 'Get node network config',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: NodeManagerConfigSchema }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
  setNodeManagerConfig: {
    method: 'PUT',
    path: '/nodes/:nodeId/node-manager/config',
    summary: 'Set node network config',
    body: NodeManagerConfigSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getNodeTimeTransferConfig: {
    method: 'GET',
    path: '/nodes/:nodeId/timetransfer/config',
    summary: 'Get node TimeTransfer config',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: TimeTransferSpecSchema }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
  setNodeTimeTransferConfig: {
    method: 'PUT',
    path: '/nodes/:nodeId/timetransfer/config',
    summary: 'Set node TimeTransfer config',
    body: TimeTransferSpecSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getNodeTimeNodeConfig: {
    method: 'GET',
    path: '/nodes/:nodeId/timenode/config',
    summary: 'Get time node config',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: TimeNodeSpecSchema }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  setNodeTimeNodeConfig: {
    method: 'PUT',
    path: '/nodes/:nodeId/timenode/config',
    summary: 'Set time node config',
    body: TimeNodeSpecSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getGlobalSSHConfig: {
    method: 'GET',
    path: '/cluster/ssh',
    summary: 'Get global SSH config for nodes',
    responses: {
      [StatusCodes.OK]: GlobalSSHConfigSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  setGlobalSSHConfig: {
    method: 'PUT',
    path: '/cluster/ssh',
    summary: 'Set global SSH config for nodes',
    body: GlobalSSHConfigSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getGlobalLinkOptions: {
    method: 'GET',
    path: '/cluster/link-options',
    summary: 'Get global options for all links',
    responses: {
      [StatusCodes.OK]: GlobalLinkOptionSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  setGlobalLinkOptions: {
    method: 'PUT',
    path: '/cluster/link-options',
    summary: 'Set global options for all links',
    body: GlobalLinkOptionSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
});
