import { scaleOrdinal } from 'd3-scale';

export const fixModeScale = scaleOrdinal([0, 1, 2, 3], ['Unknown', 'No fix', '2D', '3D']);

export const fixStatusScale = scaleOrdinal(
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  ['Unknown', 'Normal', 'DGPS', 'RTK Fixed', 'RTK Floating', 'DR', 'GNSSDR', 'Time (surveyed)', 'Simulated', 'P(Y)'],
);

export const antennaStatusScale = scaleOrdinal([0, 1, 2, -1], ['Normal', 'Open', 'Short circuit', 'Uninitialized']);

export const traimSolutionScale = scaleOrdinal([0, 1, 2], ['Good', 'Bad', 'Unknown']);

export const parseIntBase10 = (val: string) => parseInt(val, 10);
