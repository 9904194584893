import { TimeNodeSpec } from '@netinsight/management-app-common-api';

// Remove optionality for position
type Position = Required<TimeNodeSpec>['position'];

export function isTimeNodePosition(position: TimeNodeSpec['position'] | undefined): position is Position {
  if ((position?.latitude || position?.latitude === 0) && (position?.longitude || position?.longitude === 0)) {
    return true;
  }

  return false;
}

// Decide if ui debug mode on depending on the query parameter debug
export function isUiDebugModeOn(): boolean {
  return new URLSearchParams(window.location.search).has('debug');
}
