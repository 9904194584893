import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sConfigApiRef, useSnackbarHelper } from '@netinsight/management-app-common-react';
import { SyncRegionSwrKey } from './useSyncRegions';

export const useNodeSyncRegionUpdate = () => {
  const configApi = useApi(k8sConfigApiRef);

  const { snackbar } = useSnackbarHelper();

  const update = useCallback(
    async (_: string, { arg: { nodeIds, syncRegionName } }: { arg: { nodeIds: string[]; syncRegionName: string } }) => {
      for (const nodeId of nodeIds) {
        const configResponse = await configApi.getNodeTimeNodeConfig({ params: { nodeId } });
        if (configResponse.status !== StatusCodes.OK) {
          throw errorFromWrappedError(configResponse.status, configResponse.body);
        }
        const setConfigResponse = await configApi.setNodeTimeNodeConfig({
          body: {
            ...(configResponse.body.config ?? {}),
            syncRegion: syncRegionName,
          },
          params: {
            nodeId: nodeId,
          },
        });
        if (setConfigResponse.status !== StatusCodes.OK) {
          throw errorFromWrappedError(setConfigResponse.status, setConfigResponse.body);
        }
      }
    },
    [configApi],
  );

  return useSwrMutation(SyncRegionSwrKey, update, {
    revalidate: true,
    onSuccess: () => {
      snackbar.notifySuccess('Update');
    },
    onError: error => {
      snackbar.notifyError('Update', error.response, null);
    },
  });
};
