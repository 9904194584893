import React from 'react';
import { makeStyles } from '@material-ui/core';
import NetiLogoFull from '../../assets/logo-full.svg';

const useStyles = makeStyles({
  logo: {
    maxWidth: '10rem',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.logo} src={NetiLogoFull} alt="Zyntai Director logo" title="Zyntai Director" />;
};

export default LogoFull;
