import { createPlugin, createComponentExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const syncInputsUiPlugin = createPlugin({
  id: 'sync-inputs-config-ui',
  routes: {
    root: rootRouteRef,
  },
});

export const SyncInputsUiPage = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'SyncInputsUiPage',
    component: {
      lazy: () => import('./components/').then(module => module.SyncInputsPage),
    },
  }),
);

export const SyncStatusOverview = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'SyncStatusOverview',
    component: {
      lazy: () => import('./components/').then(module => module.SyncStatusOverview),
    },
  }),
);

export const HoldoverConfig = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'Holdover',
    component: {
      lazy: () => import('./components/').then(module => module.HoldoverConfig),
    },
  }),
);

export const SyncInConfig = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'SyncInConfig',
    component: {
      lazy: () => import('./components/').then(module => module.SyncInConfig),
    },
  }),
);

export const GnssConfig = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'GnssConfig',
    component: {
      lazy: () => import('./components/').then(module => module.GnssConfig),
    },
  }),
);

export const GnssMetricsDrawer = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'GnssMetricsDrawer',
    component: {
      lazy: () => import('./components/').then(module => module.GnssMetricsDrawer),
    },
  }),
);

export const PPSInConfigCard = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'PPSInConfigCard',
    component: {
      lazy: () => import('./components/').then(module => module.PPSInConfigCard),
    },
  }),
);

export const NtpClientConfigCard = syncInputsUiPlugin.provide(
  createComponentExtension({
    name: 'NtpClientConfigCard',
    component: {
      lazy: () => import('./components/').then(module => module.NtpClientConfigCard),
    },
  }),
);
