import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { getScalarResultFromPromise, metricsApiRef } from '@netinsight/management-app-common-react';

export const useNtpTimeStatuses = ({ nodeId }: { nodeId: string }, options?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  return useSwr(
    [nodeId, 'useNtpTimeStatuses'],
    async ([nodeIdParam]) => {
      const query = (...metrics: string[]) =>
        metrics.map(metric =>
          metricsApi.instantQuery({
            query: `last_over_time(neti_ntp_time_${metric}{nodeid="${nodeIdParam}"}[30s])`,
          }),
        );
      const [estErrorResult, timeFrequencyResult, intervalsResult, maxErrorResult, offsetResult, precisionResult] =
        await Promise.allSettled(query('est_error', 'frequency', 'intervals', 'max_error', 'offset', 'precision'));

      return {
        ...getScalarResultFromPromise(val => ({ estError: parseFloat(val) }))(estErrorResult),
        ...getScalarResultFromPromise(val => ({ timeFrequency: parseFloat(val) }))(timeFrequencyResult),
        ...getScalarResultFromPromise(val => ({ intervals: parseFloat(val) }))(intervalsResult),
        ...getScalarResultFromPromise(val => ({ maxError: parseFloat(val) }))(maxErrorResult),
        ...getScalarResultFromPromise(val => ({ offset: parseFloat(val) }))(offsetResult),
        ...getScalarResultFromPromise(val => ({ precision: parseFloat(val) }))(precisionResult),
      };
    },
    options,
  );
};
