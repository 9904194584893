import { ConfigApi, createApiRef, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { Alert, AlertGroup, AlertHistoryResponse, AlertMetadata } from '../types';
import { operations } from '../types/alertmanager';
import { makeSearchParams } from './utils';
import { DateTime } from 'luxon';

const DEFAULT_PROXY_PATH = '/alarms/api';

export const alarmsApiRef = createApiRef<AlarmsApi>({
  id: 'plugin.alarms.service',
});

type Options = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  configApi: ConfigApi;
};

export interface AlertHistoryOpts {
  start: DateTime;
  end?: DateTime;
  search?: string;
  limit: number;
}

export interface AlertMetadataOpts {
  search?: string;
}

export class AlarmsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  /**
   * Path to use for requests via the proxy
   */
  private readonly proxyPath: string;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.proxyPath = options.configApi.getOptionalString('alarms.proxyPath') || DEFAULT_PROXY_PATH;
  }

  private async getApiUrl() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${this.proxyPath}`;
  }

  async getAlerts(query: operations['getAlerts']['parameters']['query'] = {}): Promise<Alert[]> {
    const apiUrl = await this.getApiUrl();

    const params = new URLSearchParams(query as any);
    params.delete('filter');
    query?.filter?.forEach(filter => params.append('filter', filter));

    const response = await this.fetchApi.fetch(`${apiUrl}/alerts?${params.toString()}`);
    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    return response.json();
  }

  async getAlertGroups(query: operations['getAlertGroups']['parameters']['query'] = {}): Promise<AlertGroup[]> {
    const apiUrl = await this.getApiUrl();

    const params = new URLSearchParams(query as any);
    params.delete('filter');
    query?.filter?.forEach(filter => params.append('filter', filter));

    const response = await this.fetchApi.fetch(`${apiUrl}/alerts/groups?${params.toString()}`);
    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    return response.json();
  }

  async getAlertHistory(queryParams: AlertHistoryOpts): Promise<AlertHistoryResponse> {
    const apiUrl = await this.discoveryApi.getBaseUrl('alert');
    const params = makeSearchParams({ ...queryParams });
    const response = await this.fetchApi.fetch(`${apiUrl}/history?${params.toString()}`);

    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    return response.json();
  }

  async getAlertMetadata(queryParams: AlertMetadataOpts): Promise<AlertMetadata[]> {
    const apiUrl = await this.discoveryApi.getBaseUrl('alert');
    const params = makeSearchParams({ ...queryParams });
    const response = await this.fetchApi.fetch(`${apiUrl}/metadata?${params.toString()}`);

    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    return response.json();
  }

  async updateAlertMetadata(metadataList: AlertMetadata[]): Promise<void> {
    const apiUrl = await this.discoveryApi.getBaseUrl('alert');
    const response = await this.fetchApi.fetch(`${apiUrl}/metadata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metadataList),
    });

    if (!response.ok) {
      throw new Error(`failed to update metadata, status ${response.status}: ${response.statusText}`);
    }

    return response.json();
  }
}
