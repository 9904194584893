import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '../api/metrics';
import { isSuccessPrometheusResult, isPrometheusResultType } from '../utils';

export function useInstantMetrics({
  query,
  options = { refreshInterval: 5000, revalidateOnFocus: false },
}: {
  query: string;
  options?: SWRConfiguration;
}) {
  const prometheusApi = useApi(metricsApiRef);

  const { data, mutate, error, isLoading } = useSwr(
    [query],
    async ([queryParam]) => {
      const value = await prometheusApi.instantQuery({ query: queryParam });
      if (!isSuccessPrometheusResult(value) || !isPrometheusResultType(value.data, 'vector')) {
        throw new Error(`Unknown result type: ${value.data?.resultType}`);
      }
      return value.data.result;
    },
    options,
  );

  return {
    metrics: data,
    loading: isLoading,
    error,
    retry: mutate,
  };
}
