import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import { useInventory } from '@netinsight/management-app-common-react';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export const InventoryDBStateView = ({ nodeId, kind }: { nodeId: string; kind: string }) => {
  const { data, error, isLoading } = useInventory(
    {
      nodeId,
      kind,
    },
    { shouldRetryOnError: false },
  );

  if (isLoading) {
    return <Progress />;
  }

  return (
    <InfoCard title={kind} noPadding>
      <JsonView data={data ?? { error: error.toString() }} />
    </InfoCard>
  );
};
