import React, { Ref, useCallback, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { diagnosticsApiRef } from '@netinsight/management-app-common-react';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { Box, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { DateTime, Duration } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { TimeNodeWithManifest, useTimeNodes } from '../../hooks';
import { CookieAuthRefreshProvider } from '@backstage/plugin-auth-react';
import Typography from '@mui/material/Typography';

const getNodeName = (timenode: TimeNodeWithManifest) => timenode.spec?.name ?? timenode.id;

interface FormQueryParams {
  timenodes: TimeNodeWithManifest[];
  start: DateTime;
  end: DateTime;
  mask: boolean;
  label: string;
}

const validateDates = (startDate: DateTime, endDate: DateTime) => {
  if (startDate > endDate) {
    return 'Start date should be earlier than end date';
  } else if (endDate.diff(startDate).as('hours') > 24) {
    return 'Date difference should be under 24 hours';
  }

  return null;
};

const formatUrlBasename = (label: string, start: DateTime, end: DateTime) => {
  const label_dash = label ? `${label}-` : '';
  const fmt = (dt: DateTime) => dt.toFormat("yyyyMMdd'T'HHmm");
  return `${label_dash}${fmt(start)}-${fmt(end)}`;
};

const DatePicker = ({
  label,
  value,
  ref,
  onChange,
  error,
}: {
  label: string;
  value: DateTime<boolean>;
  ref?: Ref<HTMLInputElement>;
  onChange: () => void;
  error?: FieldError;
}) => {
  const minDate = DateTime.now().plus(Duration.fromObject({ days: -15 }));
  const maxDate = DateTime.now();
  const utcLabel = (dt: DateTime) =>
    dt.toUTC().set({ second: 0, millisecond: 0 }).toISO({ suppressSeconds: true, suppressMilliseconds: true });

  const locale = navigator.languages?.length ? navigator.languages[0] : navigator.language;

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      <Box px="0.5rem">
        <DesktopDateTimePicker
          label={`${label} (${value.offsetNameShort}, UTC${value.toFormat('Z')})`}
          ampm={false}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          inputRef={ref}
          onChange={onChange}
          slotProps={{
            textField: {
              id: 'start-date',
              error: !!error,
              variant: 'standard',
              helperText: error ? error.message : '',
            },
          }}
        />
        <Box mt="0.5rem">
          <Typography component="div" variant="caption">
            Date shown in Browser Time.
          </Typography>
          <Typography component="div" variant="caption">
            UTC: {utcLabel(value)}
          </Typography>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export const DiagnosticsCard = () => {
  const diagnosticsApi = useApi(diagnosticsApiRef);
  const { data, error: timenodesFetchError, isLoading } = useTimeNodes({ interval: 30_000 });
  const [logsUrl, setLogsUrl] = React.useState<string>('');
  const [metricsUrl, setMetricsUrl] = React.useState<string>('');
  const timenodesList = data ?? [];

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { isValid },
  } = useForm<FormQueryParams>({
    mode: 'onChange',
    defaultValues: {
      timenodes: [],
      start: DateTime.now().plus(Duration.fromObject({ hours: -3 })),
      end: DateTime.now(),
      mask: false,
      label: '',
    },
  });
  const values = watch();

  const buildDownloadLogsLink = useCallback(
    (config: FormQueryParams, zip?: boolean) => {
      const { label, start, end, timenodes, mask } = config;
      const basename = formatUrlBasename(label, start, end);
      const query = `{nodeid=~"${timenodes.map(node => node.id).join('|')}"}`;
      return diagnosticsApi.getLogsUrl({ basename, query, start, end, mask }, zip);
    },
    [diagnosticsApi],
  );
  const buildDownloadMetricsLink = useCallback(
    (config: FormQueryParams, zip?: boolean) => {
      const { label, start, end, timenodes, mask } = config;
      const basename = formatUrlBasename(label, start, end);
      return diagnosticsApi.getMetricsUrl(
        { basename, timenodes: timenodes.map(node => node.id), start, end, mask },
        zip,
      );
    },
    [diagnosticsApi],
  );

  useEffect(() => {
    const dateErrorText = validateDates(values.start, values.end);
    if (dateErrorText) {
      // show error on start date field only
      setError('start', { message: dateErrorText });
    } else {
      clearErrors('start');
    }
  }, [values.start, values.end, setError, clearErrors]);

  useEffect(() => {
    const zip = true; // this could be a checkbox in the form if needed
    // this useEffect is only needed because could not find a way to initilize exportApi factory
    void buildDownloadLogsLink(values, zip).then(url => setLogsUrl(url));
    void buildDownloadMetricsLink(values, zip).then(url => setMetricsUrl(url));
  }, [values, buildDownloadLogsLink, buildDownloadMetricsLink]);

  if (isLoading) {
    return <Progress />;
  } else if (timenodesFetchError) {
    return <ResponseErrorPanel error={timenodesFetchError} />;
  }

  return (
    <InfoCard title="Download Metrics & Logs">
      <form>
        <Box width="500px">
          <Controller
            name="timenodes"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                multiple
                id="timenodes"
                options={timenodesList}
                getOptionLabel={option => getNodeName(option)}
                getOptionSelected={(option, value) => option.id === value.id}
                filterSelectedOptions
                onChange={(_evt, fieldValues) => {
                  field.onChange(fieldValues);
                  setValue('timenodes', fieldValues);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Timenodes"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Select timenodes"
                    error={!!error}
                    helperText={error ? 'Must select at least 1 timenode' : ''}
                  />
                )}
              />
            )}
          />
          <Box marginTop={2} display="flex" justifyContent="space-between">
            <Box>
              <Controller
                name="start"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label="Start Date"
                    value={field.value}
                    ref={field.ref}
                    error={error}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="end"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label="End Date"
                    value={field.value}
                    ref={field.ref}
                    error={error}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
          </Box>
          <Box marginTop={2} display="flex">
            <Controller
              name="mask"
              control={control}
              render={({ field }) => (
                <Tooltip title="Mask sensitive data in exported metrics and logs for enhanced security">
                  <FormControlLabel
                    control={
                      <Switch {...field} checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    }
                    label="Mask sensitive data"
                  />
                </Tooltip>
              )}
            />
          </Box>
          <Box marginTop={2} display="flex">
            <Controller
              name="label"
              control={control}
              rules={{
                maxLength: { value: 12, message: 'Max 12 characters' },
                validate: value => {
                  if (value === '') return undefined;
                  if (value.match(/^[A-Za-z0-9_-]*$/)) return undefined;
                  return 'Only alphanumeric characters and -, _';
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label="Label file downloads"
                />
              )}
            />
          </Box>
          <Box marginTop={2}>
            <Button
              href={metricsUrl}
              download
              target="_blank"
              disabled={!isValid}
              color="primary"
              variant="contained"
              data-testid="btn-download-metrics"
            >
              Download Metrics
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              href={logsUrl}
              download
              target="_blank"
              disabled={!isValid}
              color="primary"
              variant="contained"
              data-testid="btn-download-logs"
            >
              Download Logs
            </Button>
          </Box>
        </Box>
      </form>
    </InfoCard>
  );
};

export const DiagnosticsPage = () => {
  return (
    <CookieAuthRefreshProvider pluginId="diagnostics">
      <DiagnosticsCard />
    </CookieAuthRefreshProvider>
  );
};
