import { scaleOrdinal } from 'd3-scale';
import { UnionFieldSchemaType, getOptionsAndDescriptionsFromSchema } from './zodSchemaUtils';
import { A } from '@mobily/ts-belt';

export const booleanScale = scaleOrdinal([false, true], ['No', 'Yes']).unknown(null);

export const onOffScale = scaleOrdinal([false, true], ['Off', 'On']).unknown(null);

export const unionSchemaScale = <T extends { toString(): string }>(
  schema: UnionFieldSchemaType<T>,
  additional: [T, string][] = [],
) => {
  const descs = getOptionsAndDescriptionsFromSchema(schema).map(
    ([val, desc]) => [val, desc ?? val?.toString() ?? ''] as [T, string],
  );
  return scaleOrdinal(...A.unzip([...descs, ...additional])).unknown(null);
};
