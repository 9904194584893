import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { k8sNodeApiRef, isOkResponse, isFulfilled } from '@netinsight/management-app-common-react';
import { ClusterNodeNetwork, TimeNode } from '@netinsight/management-app-common-api';
import { useCallback } from 'react';

export const useClusterNodeNetwork = (swrConfig?: SWRConfiguration) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const fetcher = useCallback(async () => {
    const [clusterNetworkResponse, listNodesResponse] = await Promise.allSettled([
      nodeApi.getClusterNetwork({}),
      nodeApi.listNodes({}),
    ]);
    return {
      network:
        isFulfilled(clusterNetworkResponse) && isOkResponse<ClusterNodeNetwork>(clusterNetworkResponse.value)
          ? clusterNetworkResponse.value.body
          : {},
      nodes:
        isFulfilled(listNodesResponse) &&
        isOkResponse<{ items: TimeNode[] }>(listNodesResponse.value) &&
        listNodesResponse.value.body.items
          ? listNodesResponse.value.body.items
              .map(node => ({ id: node.id, name: node.spec.name ?? node.id }))
              .sort((a, b) => a.name.localeCompare(b.name))
          : [],
    };
  }, [nodeApi]);
  return useSwr('clusterNodeNetwork', fetcher, swrConfig);
};
