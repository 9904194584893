import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import {
  Button,
  MenuItem,
  ButtonProps,
  ListItemText,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  useTheme,
} from '@material-ui/core';
import { A, F } from '@mobily/ts-belt';

export type MenuButtonProps = {
  options?: [string, any][];
  selectedOption?: any;
  onOptionSelected?: (selectedValue: any) => void;
  buttonTextFormatter?: (selectedValue: any, selectedLabel: string) => string;
} & Omit<ButtonProps, 'onClick' | 'aria-haspopup' | 'ref'>;

export const MenuButton: FunctionComponent<MenuButtonProps> = ({
  buttonTextFormatter = F.identity,
  selectedOption,
  onOptionSelected = F.ignore,
  options = [],
  ...buttonProps
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleSelect = useCallback(
    (key: string) => {
      onOptionSelected(key);
      setOpen(false);
    },
    [onOptionSelected],
  );
  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback((event: any) => {
    if (anchorRef.current?.contains(event.target) ?? false) {
      return;
    }
    setOpen(false);
  }, []);

  const [values, labels] = A.unzip(options);
  const reverseLookup = scaleOrdinal(values, labels).unknown('');
  return (
    <>
      <Button aria-haspopup="true" onClick={handleToggle} ref={anchorRef} {...buttonProps}>
        {buttonTextFormatter(selectedOption, reverseLookup(selectedOption))}
      </Button>
      <Popper
        style={{ zIndex: theme.zIndex.modal }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top left',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper>
                <MenuList id="split-button-menu" variant="menu">
                  {options.map(([key, desc]) => (
                    <MenuItem key={key} selected={key === selectedOption} onClick={() => handleSelect(key)}>
                      <ListItemText primary={desc} />
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};
