import { useLayoutEffect, useMemo, useRef } from 'react';
import { F } from '@mobily/ts-belt';

export type ResizeObserverCallback<T> = (target: T, entry: ResizeObserverEntry) => void;

export const useResizeObserver = <T extends HTMLElement>(callback: ResizeObserverCallback<T>, delay = 150) => {
  const ref = useRef<T>(null);

  const debouncedCallback = useMemo(() => F.debounce(callback, delay), [callback, delay]);

  useLayoutEffect(() => {
    const element = ref?.current;

    if (!element) {
      return F.ignore;
    }

    const observer = new ResizeObserver(entries => {
      debouncedCallback(element, entries[0]);
    });

    observer.observe(element);
    return () => observer.disconnect();
  }, [debouncedCallback, ref]);

  return ref;
};
