import { deserializeError, ResponseError, SerializedError } from '@backstage/errors';
import { getStatusText } from 'http-status-codes';

export function errorFromWrappedError(statusCode: number, body: any): Error {
  try {
    const phrase = getStatusText(statusCode);
    if ('error' in body && body.error) {
      return new (ResponseError as any)({
        message: `[${statusCode} ${phrase}] ${body.error.name}`,
        undefined,
        data: body,
        cause: deserializeError(body.error as SerializedError),
      });
    }
    return new Error(`[${statusCode} ${phrase}]`);
  } catch (err) {
    return new Error(`[${statusCode} Unknown error]`);
  }
}
