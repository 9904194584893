import { ConfigApi, createApiRef, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { DateTime, Duration } from 'luxon';
import { PrometheusQueryResponse } from '../types';

const DEFAULT_PROXY_PATH = '/prometheus/api';

export const metricsApiRef = createApiRef<MetricsApi>({
  id: 'plugin.metrics.service',
});

type Options = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  configApi: ConfigApi;
};

export class MetricsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  /**
   * Path to use for requests via the proxy, defaults to /prometheus/api
   */
  private readonly proxyPath: string;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.proxyPath = options.configApi.getOptionalString('prometheus.proxyPath') || DEFAULT_PROXY_PATH;
  }

  private async getApiUrl() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${this.proxyPath}`;
  }

  async instantQuery({ query }: { query: string }): Promise<PrometheusQueryResponse> {
    const apiUrl = await this.getApiUrl();

    const params = new URLSearchParams({ query });
    const response = await this.fetchApi.fetch(`${apiUrl}/query?${params.toString()}`);
    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    const body = await response.json();
    if (body.status !== 'success') {
      throw new Error(`Prometheus error response ${body.errorType}: ${body.error}`);
    }
    return body as PrometheusQueryResponse;
  }

  async rangeQuery({
    query,
    range,
    step,
  }: {
    query: string;
    range: {
      hours?: number;
      minutes?: number;
    };
    step?: number;
  }): Promise<PrometheusQueryResponse> {
    const apiUrl = await this.getApiUrl();

    const end = DateTime.now().toSeconds().toString();
    const start = DateTime.now().minus(Duration.fromObject(range)).toSeconds().toString();
    const params = new URLSearchParams({ query, start, end });
    if (step !== undefined) params.append('step', step.toString());
    const response = await this.fetchApi.fetch(`${apiUrl}/query_range?${params.toString()}`);
    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    const body = await response.json();
    if (body.status !== 'success') {
      throw new Error(`Prometheus error response ${body.errorType}: ${body.error}`);
    }
    return body as PrometheusQueryResponse;
  }
}
