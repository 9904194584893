import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { AppRouter, initClient, InitClientReturn, InitClientArgs, ApiFetcherArgs } from '@ts-rest/core';
import { DateTime } from 'luxon';

type ApiFetcher = (args: ApiFetcherArgs) => Promise<{ status: number; body: unknown; headers: Headers }>;

export function createClient<T extends AppRouter>(
  router: T,
  options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  },
): InitClientReturn<T, InitClientArgs> {
  const fetchApi: ApiFetcher = async ({ path, method, headers, body }) => {
    const { token: idToken } = await options.identityApi.getCredentials();
    const baseUrl = await options.discoveryApi.getBaseUrl('k8s');
    const result = await fetch(`${baseUrl}${path}`, {
      method,
      headers: { ...headers, ...(idToken && { Authorization: `Bearer ${idToken}` }) },
      body,
    });
    const contentType = result.headers.get('content-type');

    if (contentType?.includes('application/json')) {
      return { status: result.status, body: await result.json(), headers: result.headers };
    }

    if (contentType?.includes('text/plain')) {
      return { status: result.status, body: await result.text(), headers: result.headers };
    }

    return { status: result.status, body: await result.blob(), headers: result.headers };
  };
  return initClient<T, InitClientArgs>(router, {
    baseUrl: '/v1alpha1',
    baseHeaders: {},
    api: fetchApi,
  });
}

export function makeSearchParams(opts: Record<string, string | string[] | number | boolean | DateTime | undefined>) {
  const params = new URLSearchParams();
  Object.entries(opts).forEach(([key, value]) => {
    if (value !== undefined) {
      params.append(key, Array.isArray(value) ? value.join(',') : String(value));
    }
  });
  return params;
}
