import { Page, TabbedLayout } from '@backstage/core-components';
import React from 'react';
import { OnboardingList } from '../OnboardingList/OnboardingList';
import { HeaderWithAlarms } from '../HeaderWithAlarms/HeaderWithAlarms';
import { TimeNodeListTable } from '../TimeNodeListTable/TimeNodeListTable';
import { TimeNodeUpgradeTable } from '../TimeNodeUpgradeTable/TimeNodeUpgradeTable';
import { TimeNodeGlobalSettings } from '../TimeNodeGlobalSettings/TimeNodeGlobalSettings';

export const TimeNodeListPage = () => {
  return (
    <Page themeId="home">
      <HeaderWithAlarms title="Zyntai TimeNodes" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Overview" tabProps={{ id: 'time-nodes-overview-tab' }}>
          <TimeNodeListTable />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/onboarding" title="Onboarding" tabProps={{ id: 'time-nodes-onboarding-tab' }}>
          <OnboardingList />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/upgrade" title="Upgrade" tabProps={{ id: 'time-nodes-upgrade-tab' }}>
          <TimeNodeUpgradeTable />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/globalsettings" title="Global settings" tabProps={{ id: 'time-nodes-global-tab' }}>
          <TimeNodeGlobalSettings />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
