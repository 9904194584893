import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { alarmsApiRef } from '../api/alarms';

export function useAlerts({ labels }: { labels?: { [key: string]: string } } = {}, config?: SWRConfiguration) {
  const alarmsApi = useApi(alarmsApiRef);

  return useSwr(
    [labels, 'getAlerts'],
    ([labelParams]) => {
      const filter = Object.entries(labelParams ?? {}).map(([key, value]) => `${key}=${value}`);
      return alarmsApi.getAlerts({ filter });
    },
    config,
  );
}
