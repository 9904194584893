import { useApi } from '@backstage/core-plugin-api';
import { useCallback } from 'react';
import { timenetManagerApiRef } from '../api/timenet-manager';
import useSwr, { SWRConfiguration } from 'swr';

export function useGroupCalibrationDetailedResult(jobId: number, options?: SWRConfiguration) {
  const timenetManagerApi = useApi(timenetManagerApiRef);
  const fetcher = useCallback(
    async ([jobIdParam]: any) => timenetManagerApi.getDetailedResult(jobIdParam),
    [timenetManagerApi],
  );
  return useSwr([jobId, 'useGroupCalibrationDetailedResult'], fetcher, options);
}
