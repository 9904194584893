import useSwr, { SWRConfiguration } from 'swr';
import StatusCodes from 'http-status-codes';
import { errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { useApi } from '@backstage/core-plugin-api';

export const useTTLinkConfig = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  return useSwr(
    [nodeId, 'getNodeLinkTargets'],
    async ([nodeIdParam]) => {
      const response = await ttLinksApi.getNodeLinkTargets({ params: { nodeId: nodeIdParam } });
      if (response.status !== StatusCodes.OK) {
        throw errorFromWrappedError(response.status, response.body);
      }
      return response.body.config;
    },
    swrConfig,
  );
};
