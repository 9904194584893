import React, { FunctionComponent, PropsWithChildren, ReactNode, useCallback, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  makeStyles,
  PopperPlacementType,
  PaperProps,
} from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    container: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'stretch',
      gap: theme.spacing(1),
      width: '20rem',
    },
  }),
  { name: 'Neti' },
);

export type PopButtonProps = {
  label: string;
  placement?: PopperPlacementType;
  render?: (onClose: VoidFunction) => ReactNode;
  containerProps?: PaperProps;
} & Omit<ButtonProps, 'onClick' | 'aria-haspopup' | 'ref'>;

export const PopButton: FunctionComponent<PropsWithChildren<PopButtonProps>> = ({
  children,
  label,
  render,
  placement = 'bottom',
  containerProps,
  ...buttonProps
}) => {
  const styles = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback((event: any) => {
    if (anchorRef.current?.contains(event.target) ?? false) {
      return;
    }
    setOpen(false);
  }, []);

  const handleChildrenClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button {...buttonProps} aria-haspopup="true" onClick={handleToggle} ref={anchorRef}>
        {label}
      </Button>
      <Popper
        className={styles.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={placement}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top left',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={styles.container} {...containerProps}>
                {typeof render === 'function' ? render(handleChildrenClose) : children}
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};
