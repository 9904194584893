import React from 'react';
import { Grid } from '@material-ui/core';
import { NodePodsComponent } from '../NodePodsComponent';

export const TimeNodeWorkloadTab = ({ nodeId }: { nodeId: string }) => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={12}>
        <NodePodsComponent id={nodeId} />
      </Grid>
    </Grid>
  );
};
