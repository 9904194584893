import { createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const syncRegionUiPlugin = createPlugin({
  id: 'sync-region-ui',
  routes: {
    root: rootRouteRef,
  },
});

export const SyncRegionConfigPage = syncRegionUiPlugin.provide(
  createComponentExtension({
    name: 'SyncRegionConfigPage',
    component: {
      lazy: () => import('./components').then(m => m.SyncRegionConfig),
    },
  }),
);

export const SyncRegionStatusPage = syncRegionUiPlugin.provide(
  createComponentExtension({
    name: 'SyncRegionStatusPage',
    component: {
      lazy: () => import('./components').then(m => m.SyncRegionStatus as any),
    },
  }),
);
