import { makeStyles, Theme } from '@material-ui/core';

export const useTableStyles = makeStyles(
  (theme: Theme) => ({
    table: {
      width: '100%',
      ['border-collapse']: 'collapse',
      ['& caption']: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: theme.typography.h6.fontSize,
        marginBottom: theme.spacing(1),
      },
      ['& td, & th']: {
        ...theme.typography.body2,
        border: '1px solid',
        borderColor: theme.palette.divider,
      },

      ['& thead']: {
        ['& th']: {
          fontWeight: 'bold',
          padding: theme.spacing(1, 1),
          ['&.numeric']: {
            textAlign: 'right',
          },
        },
      },

      ['& tbody']: {
        ['& th']: {
          fontWeight: 'bold',
          padding: theme.spacing(1, 1),
          textAlign: 'left',
          width: '8rem',
        },
        ['& td']: {
          textAlign: 'left',
          padding: theme.spacing(1, 1),
          fontVariantNumeric: 'tabular-nums',
          ['& ul, & li']: {
            listStyle: 'none',
            margin: '0',
            padding: '0',
          },
        },
        ['& td.input']: {
          minWidth: '8rem',
          width: '12rem',
        },
        ['& td.numeric']: {
          textAlign: 'right',
        },
        ['& td.icon']: {
          textAlign: 'center',
          width: '3rem',
          ['& label']: {
            margin: 0,
          },
        },
        ['& td.error-container']: {
          position: 'relative',
        },
        ['& td.error-container > .cell-error']: {
          position: 'absolute',
          top: 0,
          right: 0,
          width: 0,
          height: 0,
          borderTop: `1.25rem solid ${theme.palette.error.main}`,
          borderLeft: `1.25rem solid transparent`,
          color: theme.palette.error.contrastText,
        },
        ['& td.action']: {
          width: '10rem',
          padding: theme.spacing(0.5),
          textAlign: 'center',
        },
        ['& td.masked']: {
          color: theme.palette.text.disabled,
          textAlign: 'center',
        },
      },
    },
  }),
  { name: 'Neti' },
);
