import React, { FunctionComponent, ReactNode } from 'react';
import { Tooltip, TooltipProps, Typography, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(
  theme => ({
    text: {
      padding: theme.spacing(1),
      maxWidth: '33vw',
    },
    icon: {
      transform: `translate(0, 5px)`,
    },
  }),
  { name: 'Neti' },
);

export const InfoTooltip: FunctionComponent<{ text: ReactNode } & Omit<TooltipProps, 'title' | 'children'>> = ({
  text,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Tooltip
      title={
        <Typography variant="body2" className={styles.text}>
          {text}
        </Typography>
      }
      {...props}
    >
      <InfoIcon fontSize="small" color="disabled" className={styles.icon} tabIndex={0} focusable />
    </Tooltip>
  );
};
