import React from 'react';
import { Page } from '@backstage/core-components';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Paper, Typography } from '@material-ui/core';
import { HeaderWithAlarms } from './HeaderWithAlarms/HeaderWithAlarms';

export const NotFoundErrorPage = () => (
  <Page themeId="service">
    <HeaderWithAlarms title="" />
    <Paper elevation={-1} style={{ width: '100%', padding: '4rem', gridColumn: '1 / -1', gridRow: '2 / -1' }}>
      <Alert variant="outlined" severity="warning" style={{ fontSize: '1.5rem' }} icon={<></>}>
        <AlertTitle>Not found</AlertTitle>
        <Typography variant="body1">The page you're trying to access is unavailable.</Typography>
      </Alert>
    </Paper>
  </Page>
);
