import React, { FunctionComponent, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';

const useMeterStyles = makeStyles(theme => ({
  meterBar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: '8rem',
    padding: theme.spacing(1),
    position: 'relative',
    backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 900 : 300],
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      width: '100%',
      background: `linear-gradient(90deg, ${theme.palette.success[theme.palette.type]} 0%, ${
        theme.palette.success[theme.palette.type]
      } 10%,  ${theme.palette.warning[theme.palette.type]} 45%,${theme.palette.warning[theme.palette.type]} 55%, ${
        theme.palette.error[theme.palette.type]
      } 90%, ${theme.palette.error[theme.palette.type]} 100%)`,
      clipPath: ({ value }: { value: number }) => `polygon(0 0, ${value * 100}% 0, ${value * 100}% 100%, 0 100%)`,
    },
  },
  meterLabel: {
    position: 'relative',
    fontWeight: 'bold',
    color: theme.palette.common[theme.palette.type === 'dark' ? 'white' : 'black'],
    zIndex: 2,
  },
}));

export const Meter: FunctionComponent<PropsWithChildren<{ value: number }>> = ({ value, children }) => {
  const styles = useMeterStyles({ value });

  return (
    <div className={styles.meterBar}>
      <span className={styles.meterLabel}>{children}</span>
    </div>
  );
};
