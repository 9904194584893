import { TTLinkEndpoint, TT_LINK_DEFAULT_PORT } from '@netinsight/crds';
import { isNullableOrEmpty } from '@netinsight/management-app-common-react';

const getIfaceNumericId = ({ iface }: TTLinkEndpoint): number => {
  const numericPart = /(\d+)$/.exec(iface)?.[1];
  const portIndex = numericPart ? parseInt(numericPart, 10) : 0;
  // eslint-disable-next-line no-nested-ternary
  const offset = iface.startsWith('eth-t') ? 24 : iface.startsWith('eth') ? 16 : 0;
  return portIndex + offset;
};

// Bit allocations: [0-5] iface, [6-21] port, [22-32] vlan
const getEndpointHash = (endpoint: TTLinkEndpoint): number => {
  const ifaceNum = getIfaceNumericId(endpoint) << 0; // 5 bits (2^5 => 32)
  const portPart = (endpoint.port ?? TT_LINK_DEFAULT_PORT) << 5; // 16 bits (2^16 => 65536)
  // vlan part becomes negative when larger than 1 << 31 so use >>> 0 to convert it to unsigned
  const vlanIdPart = ((1 + (endpoint.vlanId ?? -1)) << (5 + 16)) >>> 0; // 11 bits (2^11 => 2048)
  return ifaceNum + portPart + vlanIdPart;
};

export const generateLinkName = (
  endpointA: TTLinkEndpoint,
  endpointB: TTLinkEndpoint,
  nodeNameMap: Record<string, string> = {},
) => {
  if ([endpointA.node, endpointB.node, endpointA.iface, endpointB.iface].some(isNullableOrEmpty)) {
    return undefined;
  }
  const aName = nodeNameMap?.[endpointA.node] ?? endpointA.node;
  const aHash = getEndpointHash(endpointA);
  const bName = nodeNameMap?.[endpointB.node] ?? endpointB.node;
  const bHash = getEndpointHash(endpointB);
  return `${aName}-${bName}_${aHash?.toString(32).toLocaleUpperCase()}${bHash?.toString(32).toLocaleUpperCase()}`;
};
