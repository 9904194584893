import React, { useCallback, useMemo, useState } from 'react';
import {
  useSnackbarHelper,
  errorFromWrappedError,
  k8sOnboardingApiRef,
  useTableState,
} from '@netinsight/management-app-common-react';
import { Progress, ResponseErrorPanel, Link, Table, TableColumn, TableProps } from '@backstage/core-components';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useApi } from '@backstage/core-plugin-api';
import { DateTime } from 'luxon';
import { ResolvedOnboardingRequest, useOnboardingRequests } from '../../hooks';
import {
  SyncRegionSelector,
  compareSyncRegion,
  useFilterBySyncRegion,
  useSyncRegionByNodeIdLookup,
  useSyncRegions,
} from '@netinsight/plugin-sync-region-ui';
import { DEFAULT_SYNC_REGION } from '@netinsight/crds';

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface OnboardingRow {
  id: string;
  nodeId?: string;
  nodeName?: string;
  syncRegion?: string;
  hostname: string;
  message?: string;
  lastTransitionTime: DateTime | null;
}

const toRow = (
  model: ResolvedOnboardingRequest,
  syncRegionLookup: (nodeId: string) => string | undefined,
): OnboardingRow => {
  const condition = model.status.conditions?.find((c: any) => c.type === 'Accepted');
  return {
    id: model.id,
    nodeId: model.status.nodeId ?? '',
    nodeName: model.status.nodeName ?? '',
    syncRegion: syncRegionLookup(model.status.nodeId ?? ''),
    hostname: model.spec.hostname,
    message: condition?.message ?? '',
    lastTransitionTime:
      condition && condition.lastTransitionTime ? DateTime.fromISO(`${condition.lastTransitionTime}`) : null,
  };
};

const columns: TableColumn<OnboardingRow>[] = [
  {
    title: 'Name',
    field: 'nodeName',
    highlight: true,
    sorting: true,
    defaultSort: 'asc',
    render: (rowData: any) => {
      return (
        <Link data-testid="row-node-name" to={`/nodes/info/${rowData.nodeId}`}>
          {rowData.nodeName}
        </Link>
      );
    },
  },
  {
    title: 'Region',
    field: 'syncRegion',
    customSort: (row1, row2) =>
      compareSyncRegion(row1.syncRegion ?? DEFAULT_SYNC_REGION, row2.syncRegion ?? DEFAULT_SYNC_REGION),
  },
  { title: 'Id', field: 'nodeId' },
  { title: 'Hostname', field: 'hostname', hidden: true },
  { title: 'Message', field: 'message' },
  {
    title: 'Last Changed',
    field: 'lastTransitionTime',
    render: (rowData: any) => rowData.lastTransitionTime?.toRelative(),
    customSort: (a: OnboardingRow, b: OnboardingRow) =>
      a.lastTransitionTime && b.lastTransitionTime ? a.lastTransitionTime.diff(b.lastTransitionTime).valueOf() : 0,
  },
];

export const OnboardingList = () => {
  const classes = useStyles();
  const onboardingApi = useApi(k8sOnboardingApiRef);
  const { data, error, isLoading } = useOnboardingRequests();
  const { data: syncRegions } = useSyncRegions();
  const syncRegionLookup = useSyncRegionByNodeIdLookup(syncRegions);
  const rows = useMemo(() => data?.map(item => toRow(item, syncRegionLookup)) ?? [], [data, syncRegionLookup]);
  const { snackbar } = useSnackbarHelper();
  const [open, setOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const handleClose = useCallback(() => {
    setOpen(false);
    setToken('');
  }, []);

  const handleCopy = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(token);
    snackbar.info('Copied to clipboard');
  }, [snackbar, token]);

  const generateToken = useCallback(() => {
    setOpen(true);
    onboardingApi
      .createOnboardingToken()
      .then(result => {
        if (result.status !== 200) {
          throw errorFromWrappedError(result.status, result.body);
        }
        setToken(result.body.access_token);
      })
      .catch(err => {
        snackbar.error(err.message);
        setOpen(false);
      });
  }, [onboardingApi, snackbar]);

  const actions: TableProps<OnboardingRow>['actions'] = [
    {
      icon: () => <VpnKeyIcon />,
      tooltip: 'Generate New Token',
      isFreeAction: true,
      onClick: () => generateToken(),
    },
  ];

  const showPagination = data && data.length > 20;

  const tableOptions = useMemo(
    () => ({
      paging: showPagination,
      pageSize: 20,
      actionsColumnIndex: -1,
      loadingType: 'linear',
      showEmptyDataSourceMessage: !isLoading,
      padding: 'dense',
      pageSizeOptions: [20, 50, 100],
      thirdSortClick: false,
    }),
    [showPagination, isLoading],
  );

  const {
    items: filteredRows,
    onSyncRegionChange,
    syncRegion,
  } = useFilterBySyncRegion(rows, row => [row.syncRegion ?? '']);
  const tableStates = useTableState<OnboardingRow>(columns as any, tableOptions as any, 'onboarding-table');

  return (
    <div>
      {error && <ResponseErrorPanel error={error} />}
      {data && (
        <>
          <SyncRegionSelector currentValue={syncRegion} syncRegions={syncRegions ?? []} onChange={onSyncRegionChange} />
          <Table<OnboardingRow>
            isLoading={isLoading}
            title="Onboarding Requests"
            data={filteredRows}
            actions={actions}
            emptyContent={<Typography className={classes.empty}>No onboarding requests</Typography>}
            {...tableStates}
          />
        </>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>New onboarding token</DialogTitle>
        <DialogContent>
          {!token && <Progress />}
          {token && (
            <DialogContentText data-testid="token" style={{ overflowWrap: 'break-word' }}>
              {token}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <IconButton title="Copy to clipboard" disabled={!token} size="small" onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
