import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import z from 'zod';
import { WrappedErrorSchema } from './types';

const c = initContract();

export const grafanaDashboardsApi = c.router({
  getGrafanaDashboards: {
    method: 'GET',
    path: '/grafana/dashboards',
    summary: 'Get grafana dashboards',
    responses: {
      [StatusCodes.OK]: z.record(z.string()),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
});
