import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ResponseErrorPanel, Progress, InfoCard } from '@backstage/core-components';
import { CircularProgress, TextField, Button, Grid, Box, Divider, Typography } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { NodeManagerConfig } from '@netinsight/management-app-common-api';
import {
  useSnackbarHelper,
  useNodeManagerConfig,
  InputTooltip,
  useFormStyles,
} from '@netinsight/management-app-common-react';
import { SSHKeysConfigForm } from '../SSHKeysConfigForm';

interface TimeNodeAuthenticationConfigProps {
  nodeId: string;
}

type TimeNodeSystemAuthenticationConfig = Required<
  Pick<NonNullable<NodeManagerConfig['system']>, 'sshKeys' | 'adminPassword'>
>;

function mergeNodeManagerConfig(
  config: NodeManagerConfig,
  sshKeysConfig: TimeNodeSystemAuthenticationConfig,
): NodeManagerConfig {
  const { system: oldSystem } = config;
  const sshKeys = sshKeysConfig.sshKeys.map(sshKeyConfig => {
    const { expiryTime, ...rest } = sshKeyConfig;
    // omit null values that may appear due to clearing expiry date field
    return {
      ...rest,
      expiryTime: expiryTime || undefined,
    };
  });
  const { adminPassword } = sshKeysConfig;
  const system = { ...oldSystem, adminPassword, sshKeys };
  return {
    ...config,
    system,
  };
}

export const TimeNodeAuthenticationConfig = ({ nodeId }: TimeNodeAuthenticationConfigProps) => {
  const navigate = useNavigate();
  const { config: nodeManagerConfig, error: fetchError, loading, uploadConfig } = useNodeManagerConfig(nodeId);
  const defaultValues = useMemo(() => {
    return {
      sshKeys: nodeManagerConfig?.system?.sshKeys || [],
      adminPassword: nodeManagerConfig?.system?.adminPassword,
    };
  }, [nodeManagerConfig]);

  const formMethods = useForm<TimeNodeSystemAuthenticationConfig>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    control,
    reset: resetForm,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = formMethods;

  const { snackbar } = useSnackbarHelper();

  const submitConfig = useCallback(
    async (newSshKeyConfig: any) => {
      try {
        const config = mergeNodeManagerConfig(nodeManagerConfig!, newSshKeyConfig);
        await uploadConfig(config);
        snackbar.notifySuccess('Authentication configuration update');
      } catch (e: any) {
        snackbar.notifyError('Authentication configuration update', null, e.cause?.message || e.message);
      }
    },
    [nodeManagerConfig, uploadConfig, snackbar],
  );

  // Make sure default values are set correctly
  useEffect(() => {
    resetForm(defaultValues);
  }, [resetForm, defaultValues]);

  const styles = useFormStyles();

  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  if (loading) {
    return <Progress />;
  }

  return (
    <InfoCard title="Authentication Configuration">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitConfig)} noValidate>
          <Grid container direction="column">
            <Box width="200px">
              <Controller
                name="adminPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    type="password"
                    label="Admin Password"
                    id="adminPassword"
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <InputTooltip text="For enhanced security, it is strongly recommended to utilize SSH keys instead of an admin password." />
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <div className={styles.formContainer}>
              <Alert
                severity="info"
                action={
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate('/nodes/globalsettings/')}
                  >
                    Global Settings
                  </Button>
                }
              >
                <Typography variant="body2">
                  SSH keys specific to this node are managed here. Global SSH keys, which apply to all nodes, are
                  managed in the Global Settings menu.
                </Typography>
              </Alert>
              <SSHKeysConfigForm />
            </div>
            <Box marginTop={2} className={styles.buttonContainer}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid || isSubmitting}
                data-testid="btn-submit-authentication-form"
                startIcon={isSubmitting ? <CircularProgress size="1.5rem" /> : null}
              >
                Apply
              </Button>
              <Button
                color="primary"
                variant="outlined"
                disabled={!isDirty}
                onClick={() => resetForm(defaultValues)}
                data-testid="btn-cancel-authentication-form"
              >
                Reset
              </Button>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
