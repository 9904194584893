import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { UserSettingsAppearanceCard } from '@backstage/plugin-user-settings';
import { Box, Button, CardActions, Container } from '@material-ui/core';
import { errorApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';

const ProfileCard = () => {
  const errorApi = useApi(errorApiRef);
  const identityApi = useApi(identityApiRef);

  return (
    <InfoCard title="Account">
    <CardActions>
      <Button size="small" onClick={() =>
        identityApi.signOut().catch(error => errorApi.post(error))
      }>
        Sign out
      </Button>
      </CardActions>
    </InfoCard>);
};

export const GeneralSettingsTab = () => {
  return (
    <Container maxWidth="sm">
    <Box m={2}>
      <ProfileCard />
      </Box>
    <Box m={2}>
      <UserSettingsAppearanceCard />
      </Box>
    </Container>
  );
};
