import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// TODO: This is a temporary solution to introduce a prompt when navigating away from a page with unsaved changes.
// react-router v6 has implemented a new API for this (https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/CHANGELOG.md#670)
// but it requires using new Data routers (https://reactrouter.com/en/main/routers/picking-a-router) which does not seem compatible with Backstage yet.

type ExtendNavigator = Navigator & Pick<History, 'block'>;
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return undefined;

    if (!navigator || typeof (navigator as ExtendNavigator).block !== 'function') {
      return undefined;
    }

    const unblock = (navigator as ExtendNavigator).block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

const DEFAULT_PROMPT = 'You have some unsaved data, do you really want to leave the page?';

export function usePrompt(when = true, message = DEFAULT_PROMPT) {
  const blocker = useCallback(
    (tx: Transition) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message],
  );

  useBlocker(blocker, when);
}
