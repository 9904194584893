import { makeStyles, Theme } from '@material-ui/core';

export const useInfoCardStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      position: 'relative',
    },
    topRightAction: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
      },
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: theme.spacing(0.5),
    },
    contentContainer: {
      ['& > * + *']: {
        marginTop: theme.spacing(3),
      },
    },
  }),
  { name: 'Neti' },
);
