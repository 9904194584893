import React, { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { ResponseErrorPanel } from '@backstage/core-components';
import { LinearProgress } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import { useFormStyles, useNodeNameMap } from '@netinsight/management-app-common-react';
import { SyncRegionConfigEntry } from './SyncRegionConfigEntry';
import { SyncRegionCreateForm } from './SyncRegionCreateForm';
import { useSyncRegions } from '../../hooks';

export const SyncRegionConfig = () => {
  const { data: syncRegions, isLoading, error } = useSyncRegions();
  const { data: nodeNameMap } = useNodeNameMap();
  const [nodeIdsBySyncRegion, setNodeIdsBySyncRegion] = useState<Record<string, string[]>>({});
  const prevSyncRegions = usePrevious(syncRegions);
  useEffect(() => {
    if (!F.equals(prevSyncRegions, syncRegions)) {
      setNodeIdsBySyncRegion(Object.fromEntries(syncRegions?.map(r => [r.name, r.nodeIds]) ?? []));
    }
  }, [prevSyncRegions, syncRegions]);
  const formStyles = useFormStyles();

  return (
    <div className={formStyles.formContainer}>
      {syncRegions?.map(({ id, name }, index) => (
        <SyncRegionConfigEntry
          key={id}
          id={id}
          name={name}
          index={index}
          nodeNameMap={nodeNameMap ?? {}}
          nodeIdsBySyncRegion={nodeIdsBySyncRegion}
          setNodeIdsBySyncRegion={setNodeIdsBySyncRegion}
          syncRegions={syncRegions.filter(r => r.id !== id) ?? []}
        />
      ))}
      {isLoading ? <LinearProgress /> : null}
      {error ? <ResponseErrorPanel error={error} /> : null}
      <SyncRegionCreateForm syncRegions={syncRegions ?? []} />
    </div>
  );
};
