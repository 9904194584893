import { initContract } from '@ts-rest/core';
import { configApi } from './config';
import { nodeApi } from './node';
import { onboardingApi } from './onboarding';
import { syncRegionApi } from './sync-regions';
import { systemApi } from './system';
import { ttLinksApi } from './time-transfer';
import { groupCalibrationApi } from './group-calibration';
import { grafanaDashboardsApi } from './grafana-dashboard';
import { alarmSubscriptionApi } from './alarmsubscription';

export * from './config';
export * from './grafana-dashboard';
export * from './group-calibration';
export * from './node';
export * from './onboarding';
export * from './system';
export * from './sync-regions';
export * from './time-transfer';
export * from './link';
export * from './types';
export * from './utils';
export * from './alarmsubscription';

const c = initContract();

export const v1alpha1 = c.router({
  system: systemApi,
  node: nodeApi,
  onboarding: onboardingApi,
  config: configApi,
  syncRegion: syncRegionApi,
  ttlinks: ttLinksApi,
  groupcalibration: groupCalibrationApi,
  grafanaDashboardsApi: grafanaDashboardsApi,
  alarmSubscriptionApi: alarmSubscriptionApi,
});
