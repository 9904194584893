import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Link,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import Devices from '@material-ui/icons/Devices';

const useSidebarLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 0),
  },
  link: {},
}));

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home" data-testid="menu-link-home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<object>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={AccessTimeIcon} to="nodes" text="TimeNodes" data-testid="menu-link-time-nodes" />
        <SidebarItem icon={DeviceHubIcon} to="network" text="Time Network" data-testid="menu-link-time-network" />
        <SidebarItem icon={Devices} to="system" text="System" data-testid="menu-systems" />
        {/* End global nav */}
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup label="Settings">
        <SidebarItem icon={SettingsIcon} to="/settings" text="Settings" data-testid="menu-link-settings" />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
