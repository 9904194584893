import { useMemo } from 'react';
import { G } from '@mobily/ts-belt';
import { DEFAULT_SYNC_REGION } from '@netinsight/crds';
import { TimeNodeSyncRegionWithNodeIds } from '@netinsight/management-app-common-api';

export const useSyncRegionByNodeIdLookup = (regions: TimeNodeSyncRegionWithNodeIds[] = []) => {
  return useMemo(() => {
    const inverseMap = new Map<string, string>(
      regions.flatMap(({ name: regionName, nodeIds }) => nodeIds.map(nodeId => [nodeId, regionName])),
    );
    return (nodeId: string) =>
      G.isNotNullable(nodeId) ? inverseMap.get(nodeId) ?? DEFAULT_SYNC_REGION : DEFAULT_SYNC_REGION;
  }, [regions]);
};
