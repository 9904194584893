import React, { FunctionComponent } from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/PauseCircleFilled';
import UnknownIcon from '@material-ui/icons/Help';

export type SyncActiveStatusIconProps = {
  label: string;
  isActive?: boolean;
  showIcon?: boolean;
};

const useStyles = makeStyles(
  theme => ({
    icon: {
      margin: 0,
    },
    activeIcon: {
      fill: theme.palette.success.main,
    },
    unknownIcon: {
      fill: theme.palette.grey[500],
    },
    inactiveIcon: {
      fill: theme.palette.warning.main,
    },
  }),
  { name: 'neti-sync-icon' },
);

export const SyncActiveStatusIcon: FunctionComponent<SyncActiveStatusIconProps> = ({
  isActive,
  showIcon = true,
  label,
}) => {
  const styles = useStyles();

  const [Icon, iconProps] =
    // eslint-disable-next-line no-nested-ternary
    isActive === undefined
      ? [
          UnknownIcon,
          {
            className: styles.unknownIcon,
            titleAccess: 'Unknown status',
          },
        ]
      : isActive === true
        ? [ActiveIcon, { className: styles.activeIcon, titleAccess: 'Active' }]
        : [InactiveIcon, { className: styles.inactiveIcon, titleAccess: 'Inactive' }];

  return typeof label === 'string' ? (
    <Chip
      size="small"
      variant="outlined"
      className={styles.icon}
      label={label}
      icon={showIcon ? <Icon {...iconProps} /> : undefined}
    />
  ) : null;
};
