import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto';
import '@fontsource/roboto-slab';
import App from './App';

interface BACKSTAGE_BUILD_INFO {
  cliVersion: string; // The version of the CLI package
  gitVersion: string; // output of `git describe --always`
  packageVersion: string; // The version of the app package itself
  timestamp: number; // Date.now() when the build started
  commit: string; // output of `git rev-parse HEAD`
}
declare global {
  interface Window {
    appInfo: BACKSTAGE_BUILD_INFO;
  }
}

function setAppInfo() {
  // Backstage sets build info as an object in the following env variable.
  // Details: https://backstage.io/docs/local-dev/cli-build-system
  window.appInfo = process.env.BUILD_INFO as unknown as BACKSTAGE_BUILD_INFO;
}

setAppInfo();

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
