import { createPlugin, createComponentExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const ptpConfigPlugin = createPlugin({
  id: 'ptp-config-ui',
  routes: {
    root: rootRouteRef,
  },
});

export const PtpConfigPage = ptpConfigPlugin.provide(
  createComponentExtension({
    name: 'PtpConfigPage',
    component: {
      lazy: () => import('./components/PtpConfigPage').then(module => module.PtpConfigPage),
    },
  }),
);
