import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  configApi as configApiContract,
  nodeApi,
  onboardingApi,
  systemApi,
  ttLinksApi,
  groupCalibrationApi,
  syncRegionApi,
  grafanaDashboardsApi,
  alarmSubscriptionApi,
} from '@netinsight/management-app-common-api';
import {
  AlarmsApi,
  alarmsApiRef,
  createClient,
  InventoryApi,
  inventoryApiRef,
  k8sConfigApiRef,
  k8sNodeApiRef,
  k8sOnboardingApiRef,
  k8sSystemApiRef,
  k8sTTLinksApiRef,
  MetricsApi,
  metricsApiRef,
  DiagnosticsApi,
  diagnosticsApiRef,
  TimenetManagerApi,
  timenetManagerApiRef,
  k8sGroupCalibrationApiRef,
  k8sSyncRegionApiRef,
  k8sGrafanaDashboardsApiRef,
  k8sAlarmSubscriptionsApiRef,
} from './api';

const systemApiFactory = createApiFactory({
  api: k8sSystemApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(systemApi, { discoveryApi, identityApi }),
});

const nodeApiFactory = createApiFactory({
  api: k8sNodeApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(nodeApi, { discoveryApi, identityApi }),
});

const onboardingApiFactory = createApiFactory({
  api: k8sOnboardingApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(onboardingApi, { discoveryApi, identityApi }),
});

const configApiFactory = createApiFactory({
  api: k8sConfigApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(configApiContract, { discoveryApi, identityApi }),
});

const syncRegionsApiFactory = createApiFactory({
  api: k8sSyncRegionApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(syncRegionApi, { discoveryApi, identityApi }),
});

const ttlinksApiFactory = createApiFactory({
  api: k8sTTLinksApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(ttLinksApi, { discoveryApi, identityApi }),
});

const grafanaDashboardsApiFactory = createApiFactory({
  api: k8sGrafanaDashboardsApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(grafanaDashboardsApi, { discoveryApi, identityApi }),
});

const groupCalibrationApiFactory = createApiFactory({
  api: k8sGroupCalibrationApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(groupCalibrationApi, { discoveryApi, identityApi }),
});

const metricsApi = createApiFactory({
  api: metricsApiRef,
  deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef, configApi: configApiRef },
  factory: ({ discoveryApi, fetchApi, configApi }) => new MetricsApi({ discoveryApi, fetchApi, configApi }),
});

const alarmsApi = createApiFactory({
  api: alarmsApiRef,
  deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef, configApi: configApiRef },
  factory: ({ discoveryApi, fetchApi, configApi }) => new AlarmsApi({ discoveryApi, fetchApi, configApi }),
});

const inventoryStateApi = createApiFactory({
  api: inventoryApiRef,
  deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef, fetchApi: fetchApiRef },
  factory: ({ discoveryApi, identityApi, fetchApi }) => new InventoryApi({ discoveryApi, identityApi, fetchApi }),
});

const timenetManagerApi = createApiFactory({
  api: timenetManagerApiRef,
  deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
  factory: ({ discoveryApi, fetchApi }) => new TimenetManagerApi({ discoveryApi, fetchApi }),
});

const diagnosticsApi = createApiFactory({
  api: diagnosticsApiRef,
  deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef, configApi: configApiRef },
  factory: ({ discoveryApi }) => new DiagnosticsApi({ discoveryApi }),
});

const alarmSubscriptionApiFactory = createApiFactory({
  api: k8sAlarmSubscriptionsApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ discoveryApi, identityApi }) => createClient(alarmSubscriptionApi, { discoveryApi, identityApi }),
});

export const apis: AnyApiFactory[] = [
  systemApiFactory,
  nodeApiFactory,
  onboardingApiFactory,
  configApiFactory,
  syncRegionsApiFactory,
  ttlinksApiFactory,
  groupCalibrationApiFactory,
  grafanaDashboardsApiFactory,
  metricsApi,
  alarmsApi,
  inventoryStateApi,
  timenetManagerApi,
  diagnosticsApi,
  alarmSubscriptionApiFactory,
];
