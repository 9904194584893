import { useMemo } from 'react';
import { D } from '@mobily/ts-belt';
import { InventoryKinds, useAllInventories } from '@netinsight/management-app-common-react';
import { NodeState } from '@netinsight/node-manager-schema';
import { getInterfaceDisplayNameMap } from '../helpers';

export const useInterfaceDisplayNameMap = (...nodeIds: string[]) => {
  const swrResult = useAllInventories<{ [InventoryKinds.NodeManager]: { data: NodeState.NodeState } }>({
    nodeIds: nodeIds,
    kinds: [InventoryKinds.NodeManager],
  });
  const data = useMemo(
    () =>
      D.map(swrResult.data ?? {}, ({ [InventoryKinds.NodeManager]: nodeState }) =>
        getInterfaceDisplayNameMap(nodeState.data),
      ),
    [swrResult.data],
  );

  return {
    ...swrResult,
    data,
  };
};
