import { createApiRef, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  GroupCalibrationResults,
  GroupCalibrationRunResponse,
  GroupCalibrationNextScheduledRunResponse,
  GroupCalibrationOptions,
} from '@netinsight/group-calibration-api';

export interface TimenetManagerOptions {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
}

const DEFAULT_PROXY_PATH = '/timenet';

export const timenetManagerApiRef = createApiRef<TimenetManagerApi>({
  id: 'plugin.timenet',
});

export class TimenetManagerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: TimenetManagerOptions) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  private async getUrl(path: string) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${DEFAULT_PROXY_PATH}/api/v1/group-calibration/${path}`;
  }

  async getResults(): Promise<GroupCalibrationResults> {
    const url = await this.getUrl('results');
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw new Error(`${url} failed, status ${response.status}: ${response.statusText}`);
    }
    return (await response.json()) as GroupCalibrationResults;
  }

  async getDetailedResult(jobId: number) {
    const url = await this.getUrl(`result/${jobId}/details`);
    const response = await this.fetchApi.fetch(url);
    const body = await response.json();
    if (!response.ok) {
      if (body.error) {
        throw new Error(body.error);
      }
      throw new Error(`${url} failed, status ${response.status}: ${response.statusText}`);
    }
    return body;
  }

  async getNextScheduledRun(): Promise<GroupCalibrationNextScheduledRunResponse> {
    const url = await this.getUrl('run/next');
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw new Error(`${url} failed, status ${response.status}: ${response.statusText}`);
    }
    return (await response.json()) as GroupCalibrationNextScheduledRunResponse;
  }

  async run(options: GroupCalibrationOptions): Promise<GroupCalibrationRunResponse> {
    const url = await this.getUrl('run');
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });
    if (!response.ok) {
      throw new Error(`${url} failed, status ${response.status}: ${response.statusText}`);
    }
    return (await response.json()) as GroupCalibrationRunResponse;
  }
}
