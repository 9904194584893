import React from 'react';
import { ResponseErrorPanel, Progress, InfoCard, StructuredMetadataTable } from '@backstage/core-components';
import { Box } from '@material-ui/core';
import { useInventoryWatch, InventoryKinds } from '@netinsight/management-app-common-react';
import { NodeState } from '@netinsight/node-manager-schema';

export const TimeNodeInventoryTab = ({ nodeId }: { nodeId: string }) => {
  const {
    state: inventoryState,
    loading,
    error: fetchError,
  } = useInventoryWatch<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });

  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  if (loading) {
    return <Progress />;
  }
  return (
    <InfoCard title="Inventory" noPadding>
      {inventoryState?.ipmcInventory ? (
        <StructuredMetadataTable
          metadata={{
            productName: inventoryState.ipmcInventory?.productName ?? 'N/A',
            articleNumber: inventoryState.ipmcInventory?.productArticleNumber ?? 'N/A',
            revision: inventoryState.ipmcInventory?.productRevision ?? 'N/A',
            serialNumber: inventoryState.ipmcInventory?.productSerialNumber ?? 'N/A',
          }}
          dense
        />
      ) : (
        <Box p={2}>Inventory not available</Box>
      )}
    </InfoCard>
  );
};
