import { createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const nodeGraphUiPlugin = createPlugin({
  id: 'node-graph-ui',
  routes: {
    root: rootRouteRef,
  },
});

export const NodeGraphPageRedirector = nodeGraphUiPlugin.provide(
  createComponentExtension({
    name: 'NodeGraphPageRedirector',
    component: {
      lazy: () => import('./components').then(m => m.NodeGraphPageRedirector),
    },
  }),
);

export const NodeGraphPage = nodeGraphUiPlugin.provide(
  createComponentExtension({
    name: 'NodeGraphPage',
    component: {
      lazy: () => import('./components').then(m => m.NodeGraphPage),
    },
  }),
);
