import React from 'react';
import { TextField, Button, Tooltip, Paper } from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTime, Duration } from 'luxon';
import { configSchema } from '@netinsight/node-manager-schema';
import { SSHKeysConfig } from '@netinsight/management-app-common-api';
import { useFormStyles } from '@netinsight/management-app-common-react';

const {
  pubKey: { pattern: pubKeyPattern },
} = configSchema.properties.system.properties.sshKeys.items.properties;

const SSH_KEY_PATTERN = new RegExp(pubKeyPattern);

export const SSHKeysConfigForm = () => {
  const formStyles = useFormStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext<SSHKeysConfig>();

  const {
    fields,
    append: addSshKey,
    remove: removeSshKey,
  } = useFieldArray({
    control,
    name: 'sshKeys',
  });

  return (
    <>
      {fields.map(({ id }, index) => {
        return (
          <Paper className={formStyles.formSection} key={id}>
            <Controller
              name={`sshKeys.${index}.pubKey`}
              control={control}
              rules={{
                required: true,
                pattern: SSH_KEY_PATTERN,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  multiline
                  fullWidth
                  label="SSH Key"
                  id={`sshKeys.${index}.pubKey`}
                  variant="filled"
                  error={errors.sshKeys?.[index]?.pubKey ? true : false}
                  helperText={errors.sshKeys?.[index]?.pubKey ? 'Must be ssh-rsa or ssh-ed25519 format' : ''}
                />
              )}
            />
            <Controller
              name={`sshKeys.${index}.expiryTime`}
              control={control}
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <Tooltip title="Not setting expiry date means the ssh key does not expire!">
                    <div>
                      <DesktopDatePicker
                        format="dd/MM/yyyy"
                        label="Expiry Date"
                        minDate={DateTime.now().plus(Duration.fromObject({ days: 1 }))}
                        value={field.value ? DateTime.fromISO(field.value) : null}
                        onChange={date => {
                          // null seems necessary to be able to clear the date field
                          const value = date?.toFormat('yyyyMMdd') || null;
                          field.onChange(value);
                        }}
                        slotProps={{
                          textField: {
                            id: `sshKeys.${index}.expiryTime`,
                            variant: 'standard',
                          },
                        }}
                      />
                    </div>
                  </Tooltip>
                </LocalizationProvider>
              )}
            />
            <Button
              onClick={() => {
                removeSshKey(index);
              }}
              color="secondary"
              variant="outlined"
              startIcon={<RemoveIcon />}
              data-testid={`btn-remove-ssh-key.${index}`}
            >
              Remove
            </Button>
          </Paper>
        );
      })}
      <div className={formStyles.buttonContainer}>
        <Button
          type="button"
          onClick={() => {
            addSshKey({
              pubKey: '',
              expiryTime: undefined,
            });
          }}
          variant="outlined"
          color="default"
          startIcon={<AddIcon />}
          data-testid="btn-add-ssh-key"
        >
          Add SSH Key
        </Button>
      </div>
    </>
  );
};
