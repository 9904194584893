import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, Box, Button, makeStyles } from '@material-ui/core';
import { ResponseErrorPanel, Progress, InfoCard } from '@backstage/core-components';
import {
  useSnackbarHelper,
  useTextFieldController,
  useFormStyles,
  TextField,
  getSelectOptionFromPair,
} from '@netinsight/management-app-common-react';
import { TimeNodeSpec, TimeNodeSpecSchema } from '@netinsight/crds';
import { useSyncRegions } from '@netinsight/plugin-sync-region-ui';
import { useTimeNodeConfig } from '../../hooks';
import { F } from '@mobily/ts-belt';
import { usePrevious } from 'react-use';

const useStyles = makeStyles(() => ({
  nodeNameFieldContainer: {
    width: 'calc(max(30%, 30rem))',
  },
}));

interface TimeNodeConfigProps {
  nodeId: string;
}

const TimeNodeFormValuesSchema = z.object({
  name: TimeNodeSpecSchema.shape.name,
  syncRegion: TimeNodeSpecSchema.shape.syncRegion,
});

type TimeNodeFormValues = z.infer<typeof TimeNodeFormValuesSchema>;

export const TimeNodeConfig = ({ nodeId }: PropsWithChildren<TimeNodeConfigProps>) => {
  const { data: timeNodeConfig, error: fetchError, loading, uploadConfig } = useTimeNodeConfig(nodeId);
  const { data: syncRegions } = useSyncRegions();
  const { snackbar } = useSnackbarHelper();
  const formStyles = useFormStyles();
  const styles = useStyles();
  const regionOptions = useMemo(
    () => syncRegions?.map(({ name }) => [name, name] as [string, string]).map(getSelectOptionFromPair) ?? [],
    [syncRegions],
  );

  const defaultValues = useMemo<TimeNodeFormValues>(
    () => ({
      name: timeNodeConfig?.name,
      syncRegion: timeNodeConfig?.syncRegion,
    }),
    [timeNodeConfig],
  );

  const {
    control,
    reset: resetForm,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<TimeNodeFormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(TimeNodeFormValuesSchema),
  });

  const prevDefaultValues = usePrevious(defaultValues);
  useEffect(() => {
    if (!F.equals(defaultValues, prevDefaultValues)) {
      resetForm(defaultValues);
    }
  }, [resetForm, defaultValues, prevDefaultValues]);

  const { field: nameProps } = useTextFieldController({
    control,
    name: 'name',
    schema: TimeNodeSpecSchema.shape.name,
    label: 'Node name',
  });

  const { field: syncRegionProps } = useTextFieldController({
    control,
    name: 'syncRegion',
    schema: TimeNodeSpecSchema.shape.syncRegion,
    label: 'Sync region',
  });

  const onSubmit = async (newConfig: TimeNodeFormValues) => {
    try {
      const config: TimeNodeSpec = {
        ...timeNodeConfig,
        name: newConfig.name,
        syncRegion: newConfig.syncRegion,
      };
      await uploadConfig(config);
      snackbar.notifySuccess('Update');
    } catch (e: any) {
      snackbar.notifyError('Update', null, e.cause?.message || e.message);
    }
  };

  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  if (loading) {
    return <Progress />;
  }

  return (
    <InfoCard title="General Settings">
      <form onSubmit={handleSubmit(onSubmit)} className={formStyles.formContainer} noValidate>
        <Box className={formStyles.formRow}>
          <TextField {...nameProps} id="name" rootClassName={styles.nodeNameFieldContainer} />
          <TextField {...syncRegionProps} id="syncRegion" select SelectProps={{ native: true }}>
            {regionOptions}
          </TextField>
        </Box>
        <div className={formStyles.formRow}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            data-testid="btn-apply-general-settings"
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting && <CircularProgress size="1.5rem" />}
            Apply
          </Button>
          <Button color="primary" variant="outlined" disabled={!isDirty} onClick={() => resetForm(defaultValues)}>
            Cancel
          </Button>
        </div>
      </form>
    </InfoCard>
  );
};
