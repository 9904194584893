import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SSHKeysConfigForm } from '../SSHKeysConfigForm';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useFormStyles, useGlobalSSHConfig, useGlobalSSHConfigUpdate } from '@netinsight/management-app-common-react';
import { GlobalSSHConfig } from '@netinsight/management-app-common-api';
import Alert from '@mui/material/Alert';
import { F } from '@mobily/ts-belt';
import { usePrevious } from 'react-use';

export const GlobalSSHConfigCard = () => {
  const { data: defaultValues, isLoading, error } = useGlobalSSHConfig();
  const formMethods = useForm<GlobalSSHConfig>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    reset: resetForm,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = formMethods;
  const prevDefaultFormValues = usePrevious(defaultValues);
  useEffect(() => {
    if (!F.equals(prevDefaultFormValues, defaultValues)) {
      resetForm(defaultValues);
    }
  }, [resetForm, defaultValues, prevDefaultFormValues]);

  const { trigger: updateGlobalSSHConfig } = useGlobalSSHConfigUpdate();
  const submitNewSshConfig = useCallback(
    async (newSshKeyConfig: any) => {
      return updateGlobalSSHConfig(newSshKeyConfig);
    },
    [updateGlobalSSHConfig],
  );

  const styles = useFormStyles();

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (isLoading) {
    return <Progress />;
  }

  return (
    <InfoCard title="Global SSH Configuration">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitNewSshConfig)} noValidate className={styles.formContainer}>
          <Alert severity="info">
            <Typography variant="body2">SSH keys managed here are applied to all nodes.</Typography>
          </Alert>
          <SSHKeysConfigForm />
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || isSubmitting}
              data-testid="btn-submit-authentication-form"
              startIcon={isSubmitting ? <CircularProgress size="1.5rem" /> : null}
            >
              Apply
            </Button>
            <Button
              color="default"
              variant="outlined"
              disabled={!isDirty}
              onClick={() => resetForm(defaultValues)}
              data-testid="btn-cancel-authentication-form"
            >
              Reset
            </Button>
          </div>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
