import React from 'react';
import { ResponseErrorPanel, Progress, InfoCard } from '@backstage/core-components';
import { useInventoryWatch, InventoryKinds } from '@netinsight/management-app-common-react';
import { NodeState } from '@netinsight/node-manager-schema';
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface TimeNodeHostFingerprintsProps {
  nodeId: string;
}

export const TimeNodeHostFingerprints = ({ nodeId }: TimeNodeHostFingerprintsProps) => {
  const {
    state: inventoryState,
    loading,
    error: fetchError,
  } = useInventoryWatch<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });

  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  if (loading) {
    return <Progress />;
  }

  const hostFingerprints = inventoryState?.hostFingerprints?.fingerPrints ?? [];

  return (
    <InfoCard title="Host Fingerprints" noPadding>
      {hostFingerprints.length ? (
        <List dense>
          {hostFingerprints.map(fingerprint => (
            <ListItem key={fingerprint.fingerPrint}>
              <ListItemText primary={fingerprint.fingerPrint} secondary={fingerprint.algo} />
              <ListItemSecondaryAction>
                <IconButton
                  title="Copy to clipboard"
                  size="small"
                  onClick={() => {
                    void navigator.clipboard.writeText(fingerprint.fingerPrint);
                  }}
                  aria-label="copy to clipboard"
                >
                  <FileCopyIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box p={2}>No host fingerprints available</Box>
      )}
    </InfoCard>
  );
};
