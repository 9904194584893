import React, { FunctionComponent, PropsWithChildren, createContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { F } from '@mobily/ts-belt';

type SyncRegionContextValue = { currentValue: string[]; setCurrentValue: (input?: string[]) => void };

const DefaultValue: SyncRegionContextValue = { currentValue: [], setCurrentValue: F.ignore };

export const SyncRegionContext = createContext(DefaultValue);
SyncRegionContext.displayName = 'Sync Region Context';

export const SyncRegionContextProvider: FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const [currentSyncRegion, setCurrentSyncRegion] = useLocalStorage<string[]>(`sync-regions:current`);
  const contextValue: SyncRegionContextValue = useMemo(
    () => ({
      currentValue: currentSyncRegion ?? [],
      setCurrentValue: (val?: string[]) => setCurrentSyncRegion(val ?? []),
    }),
    [currentSyncRegion, setCurrentSyncRegion],
  );
  return <SyncRegionContext.Provider value={contextValue}>{children}</SyncRegionContext.Provider>;
};
