import { merge } from 'ts-deepmerge';
import type z from 'zod';
import { F, G } from '@mobily/ts-belt';
import { GlobalLinkOptions } from '@netinsight/crds';
import { ClusterNodeNetwork, PersistedSyncLink } from '@netinsight/management-app-common-api';
import { InventoryKinds } from '@netinsight/management-app-common-react';
import type { SyncdStatusSchema } from '@netinsight/syncd-schema';
import { useLinkMetrics } from '../hooks';

type SyncdStatus = z.infer<typeof SyncdStatusSchema>;

type Metrics = Partial<Exclude<ReturnType<typeof useLinkMetrics>['data'], undefined>>;

const sum = (xs: number[]) => xs.reduce((x1, x2) => x1 + x2, 0);

const average = (xs: number[]) => (xs.length === 0 ? NaN : sum(xs) / xs.length);

export type ResolveLinkDataParams = {
  nodeNameMap: Record<string, string>;
  syncRegionLookup: (nodeId: string) => string;
  network?: ClusterNodeNetwork;
  globalLinkOptions: GlobalLinkOptions;
  nodeSyncStatuses?: Record<string, { [InventoryKinds.SyncStatus]: { data: SyncdStatus } }>;
  metricsState: 'ok' | 'loading' | 'error';
} & Metrics;

export const getLinkDataResolver = ({
  nodeNameMap,
  syncRegionLookup,
  nodeSyncStatuses,
  metricsState,
  linksStable,
  timeError,
  rtt,
  nodesStable,
  selectedProfileIndices,
  globalLinkOptions,
}: ResolveLinkDataParams) => {
  const nodeLinkStatuses = Object.fromEntries(
    Object.entries(nodeSyncStatuses ?? {}).map(([id, kinds]) => [
      id,
      Object.fromEntries(
        kinds[InventoryKinds.SyncStatus]?.data?.links?.map(linkStatus => [linkStatus.id, linkStatus.active]) ?? [],
      ),
    ]),
  );

  const resolveEndpointLinkData = (endpoint: PersistedSyncLink['endpointA' | 'endpointB']) => {
    return {
      ...endpoint,
      nodeName: nodeNameMap[endpoint.node] ?? endpoint.node,
      syncRegion: syncRegionLookup(endpoint.node),
      nodeStable: nodesStable?.[endpoint.node],
    };
  };

  return (model: PersistedSyncLink) => {
    const pathDiffsByProfileIndex = new Map<number, number>(
      model.profiles?.map(({ index, delayDifference }) => [index, delayDifference]),
    );
    const profileIndexA = selectedProfileIndices?.[model.id]?.[model.endpointA.node] ?? -1;
    const profileIndexB = selectedProfileIndices?.[model.id]?.[model.endpointB.node] ?? -1;

    return {
      id: model.id,
      name: model.name,
      endpointA: {
        ...resolveEndpointLinkData(model.endpointA),
      },
      endpointB: {
        ...resolveEndpointLinkData(model.endpointB),
      },
      metricsState,
      linkStable:
        linksStable?.[model.id]?.[model.endpointA.node] === true &&
        linksStable?.[model.id]?.[model.endpointB.node] === true,
      syncActive:
        nodeLinkStatuses[model.endpointA.node]?.[model.id] === true &&
        nodeLinkStatuses[model.endpointB.node]?.[model.id] === true,
      timeError: average([
        Math.abs(timeError?.[model.id]?.[model.endpointA.node] ?? NaN),
        Math.abs(timeError?.[model.id]?.[model.endpointB.node] ?? NaN),
      ]),
      rtt: average([
        Math.abs(rtt?.[model.id]?.[model.endpointA.node] ?? NaN),
        Math.abs(rtt?.[model.id]?.[model.endpointB.node] ?? NaN),
      ]),
      pathDiff: pathDiffsByProfileIndex.get(profileIndexA) ?? pathDiffsByProfileIndex.get(profileIndexB) ?? NaN,
      isAutoCalibrated:
        model.autoCalibration === true || (G.isNullable(model.autoCalibration) && globalLinkOptions.autoCalibration),
      deleteProfilesOnChange:
        model.options?.deleteProfilesOnChange === true ||
        (G.isNullable(model.options?.deleteProfilesOnChange) && globalLinkOptions.deleteProfilesOnChange),
      deleteProfilesOnAutoCalibration:
        model.options?.autoCalibrationDeletesAllProfiles === true ||
        (G.isNullable(model.options?.autoCalibrationDeletesAllProfiles) &&
          globalLinkOptions.autoCalibrationDeletesAllProfiles),
      isDefaultLinkOptionsOverriden:
        // this is not accurate for virtual nodes but fetching that data might be too costly for big table
        !F.equals(merge(globalLinkOptions, model.options ?? {}), globalLinkOptions) ||
        (G.isNotNullable(model.autoCalibration) && model.autoCalibration !== globalLinkOptions.autoCalibration),
    };
  };
};
