import useSwrMutation from 'swr/mutation';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sGroupCalibrationApiRef } from '@netinsight/management-app-common-react';
import { GroupCalibrationSpec } from '@netinsight/group-calibration-api';
import { Key } from './useGroupCalibration';

export const useGroupCalibrationSpecUpdate = () => {
  const groupCalibrationApi = useApi(k8sGroupCalibrationApiRef);

  const update = useCallback(
    async (_: any, { arg: updatedSpec }: { arg: GroupCalibrationSpec }) => {
      const response = await groupCalibrationApi.updateGroupCalibrationSpec({ body: updatedSpec });
      if (response.status !== 200) {
        throw errorFromWrappedError(response.status, response.body);
      }
      return response.body;
    },
    [groupCalibrationApi],
  );
  return useSwrMutation([Key], update, {
    populateCache: true,
  });
};
