import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { k8sNodeApiRef, errorFromWrappedError } from '../api';

export const useTimeNetworkConfigs = (options?: SWRConfiguration) => {
  const nodeApi = useApi(k8sNodeApiRef);

  const fetcher = useCallback(async () => {
    const response = await nodeApi.getClusterTimeTransferConfigs({});
    if (response.status !== StatusCodes.OK) {
      throw errorFromWrappedError(response.status, response.body);
    }
    return response.body;
  }, [nodeApi]);

  return useSwr(['clusterTimeNetworkConfigs'], fetcher, options);
};
