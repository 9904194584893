import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sNodeApiRef } from '@netinsight/management-app-common-react';
import { getSyncSourceUsageConfigMap } from '@netinsight/plugin-sync-inputs-ui';

export const useSyncSourceConfigs = (swrConfig?: SWRConfiguration) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const fetcher = useCallback(async () => {
    const timetransferResponse = await nodeApi.getClusterTimeTransferConfigs({});
    if (timetransferResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(timetransferResponse.status, timetransferResponse.body);
    }

    return Object.fromEntries(
      Object.entries(timetransferResponse.body).map(([nid, config]) => [nid, getSyncSourceUsageConfigMap(config)]),
    );
  }, [nodeApi]);

  return useSwr('useSyncSourceConfigs', fetcher, swrConfig);
};
