import { errorFromWrappedError, k8sNodeApiRef } from '@netinsight/management-app-common-react';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';
import useSwr from 'swr';

export const useNodeWorkload = (nodeId: string, interval = 7500) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const fetcher = useCallback(
    async ([nodeIdParam]: [any]) => {
      const result = await nodeApi.getNodeWorkload({ params: { nodeId: nodeIdParam } });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, result.body as any);
      }
      return result.body;
    },
    [nodeApi],
  );
  return useSwr([nodeId, 'getNodeWorkload'], fetcher, { refreshInterval: interval });
};
