import React, { useCallback, useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  useFormStyles,
  useTimeTransferConfig,
  useTimeTransferConfigUpdate,
  CheckboxField,
} from '@netinsight/management-app-common-react';
import { FrequencyOutputSourceConfigSchema, TIMETRANSFER_DEFAULT_CONFIG } from '@netinsight/crds';
import { z } from 'zod';

type FrequencyOutFormValues = z.infer<typeof FrequencyOutputSourceConfigSchema>;

export const TimeNodeFrequencyOutTab = ({ nodeId }: { nodeId: string }) => {
  const { data: config, isLoading, error } = useTimeTransferConfig(nodeId);
  const { trigger: updateConfig } = useTimeTransferConfigUpdate(nodeId);
  const styles = useFormStyles();
  const initialFormValues = useMemo(() => config?.freqOut ?? TIMETRANSFER_DEFAULT_CONFIG.freqOut, [config]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FrequencyOutFormValues>({
    mode: 'onChange',
    defaultValues: initialFormValues,
    resolver: zodResolver(FrequencyOutputSourceConfigSchema),
  });
  const prevFormValues = usePrevious(initialFormValues);
  useEffect(() => {
    if (!F.equals(prevFormValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevFormValues, reset]);

  const enableCheckboxFieldProps = useController({
    control,
    name: 'enabled',
  });

  const handleResult = useCallback(
    async (newConfig: FrequencyOutFormValues) => {
      return updateConfig(existingConfig => ({
        ...existingConfig,
        freqOut: newConfig,
      }));
    },
    [updateConfig],
  );

  if (isLoading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="10M/Sync out" titleTypographyProps={{ component: 'h5' }}>
      <form onSubmit={handleSubmit(handleResult)} className={styles.formContainer}>
        <CheckboxField label="Enabled" fieldProps={enableCheckboxFieldProps} />
        <Box>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            data-testid="btn-submit"
            startIcon={isSubmitting ? <CircularProgress size="1.5rem" /> : null}
          >
            Apply
          </Button>
        </Box>
      </form>
    </InfoCard>
  );
};
