import useSwr, { SWRConfiguration } from 'swr';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';

export const TTLinksKey = 'listTimeTransferLinks';

export const useTTLinks = (swrConfig?: SWRConfiguration) => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const getLinks = useCallback(async () => {
    const response = await ttLinksApi.listTimeTransferLinks({});
    if (response.status !== 200) {
      throw errorFromWrappedError(response.status, response.body);
    }
    return response.body.links;
  }, [ttLinksApi]);

  return useSwr<PersistedSyncLink[]>(TTLinksKey, getLinks, swrConfig);
};
