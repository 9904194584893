import React, { FunctionComponent } from 'react';
import { Control, useController } from 'react-hook-form';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  CheckboxField,
  getSelectOptionsFromSchema,
  GrafanaLink,
  SliderField,
  TextField,
  useFormStyles,
  useSliderController,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { DefaultPTPTelemetryInterval, LogLevelSchema } from '@netinsight/crds';
import { ServiceLoggingConfiguration } from '../../hooks';
import { LogLevelWarning } from './LogLevelWarning';

type PtpRecvTransKeys = keyof Pick<ServiceLoggingConfiguration, 'ptpReceivers' | 'ptpTransmitters'>;
const schema = LogLevelSchema.optional();

const TelemetryIntervalMarks = [
  { label: '1', value: 1 },
  { label: `${DefaultPTPTelemetryInterval} (default)`, value: DefaultPTPTelemetryInterval },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
  { label: '60', value: 60 },
];

const useStyles = makeStyles(() => ({
  sliderWrapper: {
    [`& > [data-index="${TelemetryIntervalMarks.findIndex(({ value }) => value === DefaultPTPTelemetryInterval)}"]`]: {
      transform: 'translate(-5px, 0)',
    },
  },
}));

export const PtpLoggingFormSection: FunctionComponent<{
  nodeId: string;
  control: Control<ServiceLoggingConfiguration>;
  dashboardUrl?: string;
  prefix: PtpRecvTransKeys;
  field: ServiceLoggingConfiguration[PtpRecvTransKeys][number];
  index: number;
}> = ({ nodeId, control, field: fieldItem, index, prefix, dashboardUrl }) => {
  const formStyles = useFormStyles();
  const styles = useStyles();

  const { field: controllerLogLevelFieldProps } = useTextFieldController({
    control,
    name: `${prefix}.${index}.controllerLogLevel`,
    label: 'Log level',
    defaultValue: 'info',
    schema,
  });
  const telemetryEnableP2PCountersProps = useController({
    control,
    name: `${prefix}.${index}.telemetryEnableP2PCounters`,
  });

  const telemetryIntervalProps = useSliderController({
    control,
    name: `${prefix}.${index}.telemetryInterval`,
    label: 'Telemetry interval (seconds)',
    min: 1,
    max: 60,
    step: 1,
    marks: TelemetryIntervalMarks,
    labelScale: val => `${val ?? DefaultPTPTelemetryInterval}s`,
    fromValueScale: val => val ?? DefaultPTPTelemetryInterval,
    toValueScale: F.identity,
    defaultValue: DefaultPTPTelemetryInterval,
  });

  return (
    <Paper className={formStyles.formSection}>
      <Typography component="h6" variant="h6">
        {fieldItem.displayName}
      </Typography>
      <div className={formStyles.formGrid}>
        <TextField
          {...controllerLogLevelFieldProps}
          fullWidth
          InputProps={{
            startAdornment: <LogLevelWarning name={controllerLogLevelFieldProps.name!} control={control} />,
          }}
        >
          {getSelectOptionsFromSchema(schema)}
        </TextField>
        <CheckboxField label="Enable P2P counters telemetry" fieldProps={telemetryEnableP2PCountersProps} />
      </div>
      <SliderField {...telemetryIntervalProps} className={styles.sliderWrapper} />
      <div className={formStyles.buttonContainer}>
        <GrafanaLink
          nodeIds={[nodeId]}
          containers={fieldItem.containerNames}
          dashboardUrl={dashboardUrl}
          size="small"
          label="Logs"
        />
      </div>
    </Paper>
  );
};
