import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import z from 'zod';
import { WrappedErrorSchema } from './types';

const c = initContract();

export const SystemServiceVersionSchema = z.object({
  tag: z.string(),
});
export type SystemServiceVersion = z.infer<typeof SystemServiceVersionSchema>;

export const SystemSoftwareSchema = z.object({
  version: z.object({
    release: z.string().describe('Release version that is currently installed'),
    helmChart: z.string().describe('Version of the Helm chart'),
  }),
  services: z.record(SystemServiceVersionSchema),
});
export type SystemSoftware = z.infer<typeof SystemSoftwareSchema>;

export const systemApi = c.router({
  getSystemSoftware: {
    method: 'GET',
    path: '/system/software',
    summary: 'Get software versions',
    responses: {
      [StatusCodes.OK]: SystemSoftwareSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: WrappedErrorSchema,
    },
  },
});
