import useSwr, { SWRConfiguration } from 'swr';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { F, G, S } from '@mobily/ts-belt';

const isNullableOrEmpty = F.either(G.isNullable, F.both(G.isString, S.isEmpty));

export const useTTLink = (id?: string, swrConfig?: SWRConfiguration) => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const getById = useCallback(
    async ([idParam]: [string]) => {
      if (isNullableOrEmpty(idParam)) {
        return undefined;
      }
      const getLinkResponse = await ttLinksApi.getTimeTransferLink({
        params: { id: idParam },
      });
      if (getLinkResponse.status !== 200) {
        throw errorFromWrappedError(getLinkResponse.status, getLinkResponse.body);
      }
      return getLinkResponse.body.config;
    },
    [ttLinksApi],
  );

  return useSwr<PersistedSyncLink | undefined>([id, 'getTimeTransferLink'], getById, swrConfig);
};
