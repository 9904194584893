import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialTable, { Column, OrderByCollection, MaterialTableProps, Query } from '@material-table/core';
import { useSnackbarHelper } from '../../hooks';
import { useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

type MaterialTablePropsOmitted<T extends object> = Omit<
  MaterialTableProps<T>,
  'data' | 'columns' | 'onRowAdd' | 'onRowUpdate' | 'onRowAdd' | 'editable'
>;
export interface CrudTableProps<T extends { id: string }> extends MaterialTablePropsOmitted<T> {
  columns: Column<T>[];
  rows: T[];
  onRowAdd?: (newData: T) => Promise<unknown>;
  onRowUpdate?: (newData: T, oldData?: T) => Promise<unknown>;
  onRowDelete?: (oldData: T) => Promise<unknown>;
  onRowEditingEnded?: VoidFunction;
  validateRow?: (rowData: T) => string | null | undefined;
  disableNotifications?: boolean;
}

function filterRows<T extends { id: string }>(rows: T[], columns: Column<T>[], query?: Query<T>) {
  if (!query) {
    return rows;
  }

  let result = [...rows];
  if (query.search && query.search !== '') {
    result = result.filter(item => {
      return Object.values(item).some((value: any) => {
        return (value?.toString() ?? '').search(query.search) >= 0;
      });
    });
  }

  const sorters = query.orderByCollection
    .filter((sorter: OrderByCollection) => sorter.sortOrder !== undefined)
    .map((sorter: OrderByCollection) => {
      return { ...sorter, column: columns[sorter.orderBy] };
    });

  if (sorters.length) {
    result.sort((a, b) => {
      return sorters
        .map((sorter): number => {
          const { column, orderDirection } = sorter;
          const { customSort, field } = column;
          const v = orderDirection === 'asc' ? [a, b] : [b, a];
          if (customSort) return customSort(v[0], v[1], 'row');
          return (v[0] as any)[field]?.localeCompare((v[1] as any)[field]);
        })
        .reduce((acc: number, method: number) => acc || method, 0);
    });
  }
  return result;
}

export function CrudTable<T extends { id: string }>({
  columns,
  rows: initialRows = [],
  options: providedOptions,
  onRowAdd,
  onRowDelete,
  onRowUpdate,
  onRowEditingEnded = () => void 0,
  validateRow,
  disableNotifications,
  ...otherProps
}: CrudTableProps<T>) {
  const { snackbar } = useSnackbarHelper(disableNotifications);

  const theme = useTheme();

  const defaultOptions: MaterialTableProps<any>['options'] = useMemo(
    () => ({
      showTitle: false,
      pageSize: 10,
      showFirstLastPageButtons: false,
      pageSizeOptions: [5, 10, 20, 50, 100],
      columnsButton: true,
      draggable: false,
      sorting: undefined, // removes deprecation warning
      maxColumnSort: 1,
      cellStyle: {
        padding: theme.spacing(1),
      },
      headerStyle: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        padding: theme.spacing(2, 1),
        minWidth: 96,
      },
      maxBodyHeight: 650,
    }),
    [theme],
  );

  const tableStyle = {
    width: '100%',
  };

  const options = useMemo(() => {
    return {
      ...defaultOptions,
      actionsColumnIndex: columns.length,
      ...providedOptions,
    };
  }, [defaultOptions, columns.length, providedOptions]);

  const [rows, setRows] = useState<T[]>([]);

  const filteredRows = useMemo(() => filterRows(initialRows, columns), [initialRows, columns]);

  useEffect(() => {
    setRows(filteredRows);
  }, [filteredRows]);

  const handleRowValidation = useCallback(
    (rowData: T) => {
      return new Promise<void>((resolve, reject) => {
        if (typeof validateRow === 'function') {
          const rowValidationResult = validateRow(rowData);
          if (typeof rowValidationResult === 'string' && rowValidationResult.length > 0) {
            snackbar.notifyError('validate', null, rowValidationResult, 'warning');
            reject(new Error(rowValidationResult));
            return;
          }
        }
        resolve();
      });
    },
    [snackbar, validateRow],
  );

  const handleRowUpdate = useCallback(
    (newData: T, oldData?: T) => {
      if (typeof onRowUpdate !== 'function') {
        return Promise.resolve();
      }
      return handleRowValidation(newData)
        .then(
          () => onRowUpdate(newData, oldData) as Promise<T | undefined>,
          () => void 0,
        )
        .then(
          updatedRow => {
            snackbar.notifySuccess('Updated');
            if (!updatedRow || typeof updatedRow !== 'object' || !updatedRow.id) {
              return;
            }
            setRows(existingRows =>
              filterRows(
                existingRows.map(row => (row.id === updatedRow.id ? updatedRow : row)),
                columns,
              ),
            );
          },
          error => {
            snackbar.notifyError('Update', error.response, null);
          },
        )
        .finally(onRowEditingEnded);
    },
    [columns, handleRowValidation, onRowEditingEnded, onRowUpdate, snackbar],
  );

  const handleRowAdd = useCallback(
    (newData: T) => {
      if (typeof onRowAdd !== 'function') {
        return Promise.resolve();
      }
      return handleRowValidation(newData)
        .then(() => onRowAdd(newData) as Promise<T | undefined>)
        .then(
          newRow => {
            snackbar.notifySuccess('Added');
            if (!newRow || typeof newRow !== 'object' || !newRow.id) {
              return;
            }
            setRows(existingRows => filterRows([...existingRows, newRow], columns));
          },
          error => {
            snackbar.notifyError('Add', error.response, null);
          },
        )
        .finally(onRowEditingEnded);
    },
    [columns, handleRowValidation, onRowAdd, onRowEditingEnded, snackbar],
  );

  const handleRowDelete = useCallback(
    (oldData: T) => {
      if (typeof onRowDelete !== 'function') {
        return Promise.resolve();
      }

      return onRowDelete(oldData)
        .then(
          () => {
            snackbar.notifySuccess('Deleted');
            setRows(existingRows =>
              filterRows(
                existingRows.filter(row => row.id !== oldData.id),
                columns,
              ),
            );
          },
          error => {
            snackbar.notifyError('Delete', error.response, null);
          },
        )
        .finally(onRowEditingEnded);
    },
    [columns, onRowDelete, onRowEditingEnded, snackbar],
  );

  const handleQueryChanged = useCallback(
    (query?: Query<any>) => {
      setRows(existingRows => filterRows(existingRows, columns, query));
    },
    [columns],
  );

  const editable = useMemo<MaterialTableProps<T>['editable']>(
    () => ({
      onRowDelete: typeof onRowDelete === 'function' ? handleRowDelete : undefined,
      onRowAdd: typeof onRowAdd === 'function' ? handleRowAdd : undefined,
      onRowUpdate: typeof onRowUpdate === 'function' ? handleRowUpdate : undefined,
      onRowAddCancelled: onRowEditingEnded,
      onRowUpdateCancelled: onRowEditingEnded,
    }),
    [handleRowAdd, handleRowDelete, handleRowUpdate, onRowAdd, onRowDelete, onRowEditingEnded, onRowUpdate],
  );
  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        style={tableStyle}
        options={options}
        data={rows}
        totalCount={rows.length}
        onQueryChange={handleQueryChanged}
        columns={columns}
        editable={editable}
        {...otherProps}
      />
    </ThemeProvider>
  );
}
