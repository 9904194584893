import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import z from 'zod';
import { WrappedErrorSchema } from './types';
import { SyncRegionName } from '@netinsight/crds';

export const TimeNodeSyncRegionSchema = z.object({
  id: z.string(),
  name: SyncRegionName,
});

export const TimeNodeSyncRegionWithNodeIdsSchema = TimeNodeSyncRegionSchema.extend({
  nodeIds: z.array(z.string()),
});

export type TimeNodeSyncRegion = z.infer<typeof TimeNodeSyncRegionSchema>;

export type TimeNodeSyncRegionWithNodeIds = z.infer<typeof TimeNodeSyncRegionWithNodeIdsSchema>;

const c = initContract();

export const syncRegionApi = c.router({
  listSyncRegions: {
    method: 'GET',
    path: '/sync-regions',
    summary: 'Get a list of sync regions and their associated nodes',
    responses: {
      [StatusCodes.OK]: z.array(TimeNodeSyncRegionWithNodeIdsSchema),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
  createSyncRegion: {
    method: 'POST',
    path: '/sync-regions',
    summary: 'Create a new sync region',
    body: TimeNodeSyncRegionSchema,
    responses: {
      [StatusCodes.OK]: TimeNodeSyncRegionSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
  removeSyncRegion: {
    method: 'DELETE',
    path: '/sync-regions/:syncRegionId',
    summary: 'Delete a sync region',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
});
