import { useApi } from '@backstage/core-plugin-api';
import { inventoryApiRef } from '../api/inventory';
import useSwr, { SWRConfiguration } from 'swr';

export function useAllInventories<T extends Record<string, { data: any }> = Record<string, { data: any }>>(
  { nodeIds, kinds }: { nodeIds: string[]; kinds: string[] },
  options?: SWRConfiguration,
) {
  const inventoryApi = useApi(inventoryApiRef);
  return useSwr(
    [nodeIds, kinds, 'inventory-all'],
    ([nodeIdsArg, kindsArg]) => {
      if (nodeIdsArg.length === 0) {
        return Promise.resolve({} as Record<string, T>);
      }
      return inventoryApi.getAll<T>({ nodeIds: nodeIdsArg, kinds: kindsArg });
    },
    options,
  );
}
