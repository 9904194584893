import useSwr, { SWRConfiguration } from 'swr';
import { merge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { groupMetricsFromPromise, metricsApiRef } from '@netinsight/management-app-common-react';
import { SYNC_SOURCE_NAMES } from '@netinsight/plugin-sync-inputs-ui';

const getNodeId = (m: any) => m.nodeid;

export const useTimeNodesMetrics = (options?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  return useSwr(
    ['timeNodesMetrics'],
    async () => {
      const [
        systemCpuTimeResult,
        systemMemoryUsageResult,
        boardTempResult,
        controlStateResult,
        ptpStateResult,
        ptpOffsetResult,
        pps1inErrorResult,
        gnssErrorResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: 'sum by(nodeid) (rate(system_cpu_time{state!="idle", cpu=~"cpu0|cpu1"}[5m])) / 2',
        }),
        metricsApi.instantQuery({
          query: 'sum by(nodeid) (system_memory_usage{state=~"buffered|slab_reclaimable|slab_unreclaimable|used"})',
        }),
        metricsApi.instantQuery({
          // when node name is changed we get duplicate metrics so average them to get the correct value
          query: 'avg by(nodeid) (neti_BOARD_TEMP_1)',
        }),
        metricsApi.instantQuery({
          query: 'neti_hicc_hicc_control_state',
        }),
        metricsApi.instantQuery({
          query: 'neti_ptp_port_dataset_state{type="receiver"}',
        }),
        metricsApi.instantQuery({
          query: 'neti_hicc_ptpreceiver_time_error',
        }),
        metricsApi.instantQuery({
          query: 'neti_hicc_giraffe_pps1_in_err',
        }),
        metricsApi.instantQuery({
          query: 'neti_hicc_gnss_time_error',
        }),
      ]);
      const result = merge(
        groupMetricsFromPromise(systemCpuTimeResult, getNodeId, val => ({ systemCpuTime: parseFloat(val) })),
        groupMetricsFromPromise(systemMemoryUsageResult, getNodeId, val => ({ systemMemoryUsage: parseFloat(val) })),
        groupMetricsFromPromise(boardTempResult, getNodeId, val => ({ boardTemp: parseFloat(val) })),
        groupMetricsFromPromise(controlStateResult, getNodeId, val => ({ controlState: val })),
        groupMetricsFromPromise(ptpStateResult, getNodeId, (val, m) => ({
          ptpStates: {
            [m.service_name]: parseInt(val, 10),
          },
        })),
        groupMetricsFromPromise(pps1inErrorResult, getNodeId, val => ({
          timeErrors: { [SYNC_SOURCE_NAMES.ppsIn]: Math.abs(parseFloat(val)) },
        })),
        groupMetricsFromPromise(gnssErrorResult, getNodeId, val => ({
          timeErrors: { [SYNC_SOURCE_NAMES.gnss]: Math.abs(parseFloat(val)) },
        })),
        groupMetricsFromPromise(ptpOffsetResult, getNodeId, (val, m) => ({
          timeErrors: {
            [m.name]: Math.abs(parseFloat(val)),
          },
        })),
      );
      return result;
    },
    options,
  );
};

export type TimeNodesMetrics = Exclude<ReturnType<typeof useTimeNodesMetrics>['data'], undefined>;
