import classNames from 'classnames';
import React, {
  DetailedHTMLProps,
  DetailsHTMLAttributes,
  FunctionComponent,
  HTMLAttributes,
  HtmlHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Progress } from '@backstage/core-components';
import { Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import MoreIcon from '@material-ui/icons/MoreHorizRounded';
import { G, S } from '@mobily/ts-belt';

const useStyles = makeStyles(theme => ({
  statusContainer: {
    width: '100%',
    ['&> * + *']: {
      marginTop: theme.spacing(2),
      borderTop: '1px dashed',
      borderColor: theme.palette.divider,
    },
    ['&> button, &> [role="button"]']: {
      borderStyle: 'solid',
    },
  },
  infoBox: {
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(2),
  },
  infoBoxMessage: {
    width: '100%',
    padding: 0,
  },
  infoBoxIcon: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  infoBoxAction: {
    height: '1.5rem',
  },
  section: {
    ['&> summary']: {
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightBold,
      cursor: 'pointer',
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
  },
  statuses: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(4),
    width: '100%',
    margin: 0,
    ['&> div']: {
      display: 'flex',
      flexDirection: 'row',
      minWidth: '8rem',
      alignItems: 'center',
    },
    ['& dt']: {
      paddingRight: theme.spacing(0.5),
      ['&::after']: {
        content: '":"',
      },
    },
    ['& dd']: {
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      gap: theme.spacing(0.25),
    },
    ['& + *']: {
      marginTop: theme.spacing(2),
    },
  },
}));

type StatusTuple = [string, ReactNode] | [string, ReactNode, HtmlHTMLAttributes<HTMLDivElement>];

export type StatusBoxProps = {
  isLoading?: boolean;
  statuses?: StatusTuple[];
  showToggle?: boolean;
} & AlertProps;

export const StatusList: FunctionComponent<
  { statuses: StatusTuple[] } & DetailedHTMLProps<HTMLAttributes<HTMLDListElement>, HTMLDListElement>
> = ({ statuses, ...otherProps }) => {
  const styles = useStyles();
  return (
    <dl className={styles.statuses} {...otherProps}>
      {statuses.map(([name, value, divProps], index) =>
        (G.isString(value) && S.isNotEmpty(value)) || (!G.isString(value) && G.isNotNullable(value)) ? (
          <div key={index} {...divProps}>
            <Typography variant="body1" component="dt">
              {name}
            </Typography>
            <Typography
              component="dd"
              data-key={name
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')}
            >
              {value}
            </Typography>
          </div>
        ) : null,
      )}
    </dl>
  );
};

export const StatusBoxSection: FunctionComponent<
  {
    summary: ReactNode;
    statuses: StatusTuple[];
  } & DetailedHTMLProps<DetailsHTMLAttributes<HTMLDetailsElement>, HTMLDetailsElement>
> = ({ summary, statuses, ...otherProps }) => {
  const styles = useStyles();
  const hasValues = useMemo(() => statuses.map(([, v]) => v).some(G.isNotNullable), [statuses]);

  return hasValues ? (
    <details className={styles.section} {...otherProps}>
      <summary>{summary}</summary>
      <StatusList statuses={statuses} />
    </details>
  ) : null;
};

export const StatusBox: FunctionComponent<PropsWithChildren<StatusBoxProps>> = ({
  statuses = [],
  isLoading,
  className,
  showToggle = true,
  children,
  ...otherProps
}) => {
  const styles = useStyles();

  const [isInfoExpanded, setIsInfoExpanded] = useState(!showToggle);
  const handleInfoExpansion = useCallback(() => setIsInfoExpanded(expanded => !expanded), []);
  const hasStatuses = useMemo(
    () => G.isNotNullable(statuses) && statuses.length > 0 && statuses.map(([, v]) => v).some(G.isNotNullable),
    [statuses],
  );

  return (
    <>
      {isLoading && <Progress style={{ width: '100%' }} />}
      {hasStatuses ? (
        <Alert
          severity="info"
          variant="standard"
          className={classNames(styles.infoBox, className)}
          classes={{
            icon: styles.infoBoxIcon,
            message: styles.infoBoxMessage,
            action: styles.infoBoxAction,
          }}
          action={
            showToggle ? (
              <IconButton onClick={handleInfoExpansion} size="small" data-testid="btn-expand-status-box">
                <MoreIcon />
                <Typography variant="srOnly">Expand</Typography>{' '}
              </IconButton>
            ) : null
          }
          {...otherProps}
        >
          <Collapse in={isInfoExpanded} collapsedSize={24} classes={{ wrapperInner: styles.statusContainer }}>
            <StatusList statuses={statuses} />
            {children}
          </Collapse>
        </Alert>
      ) : null}
    </>
  );
};
