import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import {
  metricsApiRef,
  groupMetricsFromPromise,
  groupMetricsFromPromiseBy2Levels,
} from '@netinsight/management-app-common-react';

export const useLinkMetrics = (swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  const fetcher = useCallback(async () => {
    const [
      linkStablePromiseResult,
      timeErrorPromiseResult,
      rttPromiseResult,
      nodeStablePromiseResult,
      linkSelectedProfileResult,
    ] = await Promise.allSettled([
      metricsApi.instantQuery({ query: 'last_over_time(neti_hicc_link_stable[30s])' }),
      metricsApi.instantQuery({ query: 'last_over_time(neti_hicc_multilink_link_time_error[30s])' }),
      metricsApi.instantQuery({ query: 'last_over_time(neti_hicc_multilink_filtered_rtt[30s])' }),
      metricsApi.instantQuery({ query: 'last_over_time(neti_hicc_nodestability_is_stable[30s])' }),
      metricsApi.instantQuery({ query: `last_over_time(neti_hicc_multilink_selected_profile[30s])` }),
    ]);

    if (linkStablePromiseResult.status === 'rejected') {
      throw linkStablePromiseResult.reason;
    }

    return {
      timeError: groupMetricsFromPromiseBy2Levels(
        timeErrorPromiseResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseFloat(val),
      ),
      rtt: groupMetricsFromPromiseBy2Levels(
        rttPromiseResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseFloat(val),
      ),
      linksStable: groupMetricsFromPromiseBy2Levels(
        linkStablePromiseResult,
        m => m.link_id,
        m => m.nodeid,
        val => Boolean(parseInt(val, 10)),
      ),
      nodesStable: groupMetricsFromPromise(
        nodeStablePromiseResult,
        metrics => metrics.nodeid,
        val => Boolean(parseInt(val, 10)),
      ),
      selectedProfileIndices: groupMetricsFromPromiseBy2Levels(
        linkSelectedProfileResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseInt(val, 10),
      ),
    };
  }, [metricsApi]);
  return useSwr('useLinkMetrics', fetcher, swrConfig);
};
