export const InventoryKinds = {
  SyncStatus: 'syncd-syncstatus',
  GNSS: 'gnssctld',
  NodeManager: 'node-manager',
  SyncdProfileDelete: 'syncd-profiledelete',
  SyncdProfileRequest: 'syncd-profilerequest',
  NodeGraphState: 'node-graph-state',
} as const;

export const NetiColors = {
  blue: {
    light: '#B2CCFF',
    main: '#0055FF',
    dark: '#001EAA',
  },
  green: {
    light: '#B6F8EC',
    main: '#0AE6BE',
    dark: '#0A6E78',
  },
  red: {
    light: '#FDC5CE',
    main: '#FA3C5A',
    dark: '#96281E',
  },
  yellow: {
    light: '#FFF3B2',
    main: '#FFD800',
    dark: '#DC8C28',
  },
  orange: {
    light: '#FDA455',
    main: '#FD8E2B',
    dark: '#DB6802',
  },
};
