import { G } from '@mobily/ts-belt';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { useLinkSelectedProfileIndices, useSingleLinkMetrics } from '../hooks';

export const getCombinedLinkDetailMetrics = ({
  data,
  selectedProfileIndices,
  metrics: { rtts = {}, linkTimeErrors = {}, linkStable = {}, nodeStable = {} } = {} as any,
}: {
  data: PersistedSyncLink;
  metrics: ReturnType<typeof useSingleLinkMetrics>['data'];
  selectedProfileIndices: Exclude<ReturnType<typeof useLinkSelectedProfileIndices>['data'], undefined>;
}) => {
  const pathDiffsByProfileIndex = new Map<number, number>(
    data.profiles?.map(({ index, delayDifference }) => [index, delayDifference]),
  );
  const profileIndexA = selectedProfileIndices[data.endpointA.node];
  const profileIndexB = selectedProfileIndices[data.endpointB.node];
  const pathDiffA = G.isNotNullable(profileIndexA) ? pathDiffsByProfileIndex.get(profileIndexA) : undefined;
  const pathDiffB = G.isNotNullable(profileIndexB) ? pathDiffsByProfileIndex.get(profileIndexB) : undefined;
  const rttA = rtts?.[data.id]?.[data.endpointA?.node];
  const rttB = rtts?.[data.id]?.[data.endpointB?.node];
  const linkTimeErrorA = linkTimeErrors?.[data.id]?.[data.endpointA?.node];
  const linkTimeErrorB = linkTimeErrors?.[data.id]?.[data.endpointB?.node];

  return {
    rtt: rttA ?? rttB,
    linkTimeError: linkTimeErrorA ?? linkTimeErrorB,
    profileIndex: profileIndexA ?? profileIndexB,
    pathDiff: pathDiffA ?? pathDiffB,
    linkStable:
      linkStable?.[data.id]?.[data.endpointA.node] === true && linkStable?.[data.id]?.[data.endpointB.node] === true,
    endpointAStable: nodeStable?.[data.endpointA.node] === true,
    endpointBStable: nodeStable?.[data.endpointB.node] === true,
  };
};

export type CombinedLinkDetailMetrics = Partial<ReturnType<typeof getCombinedLinkDetailMetrics>>;
