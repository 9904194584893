import { Page, TabbedLayout } from '@backstage/core-components';
import React from 'react';
import { HeaderWithAlarms } from '../HeaderWithAlarms/HeaderWithAlarms';
import { VersionsTab } from './VersionsTab';
import { DiagnosticsPage } from './DiagnosticsPage';

export const SystemsPage = () => {
  return (
    <Page themeId="systems">
      <HeaderWithAlarms title="System" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Diagnostics" tabProps={{ id: 'help-diagnostics-tab' }}>
          <DiagnosticsPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/versions" title="Versions">
          <VersionsTab />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
