import { useCallback } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { F, G, flow } from '@mobily/ts-belt';
import { SliderFieldProps } from '../components';

export const useSliderController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  defaultValue,
  min,
  max,
  step,
  toValueScale = F.identity,
  fromValueScale = F.identity,
  labelScale = flow(F.identity, String),
  marks,
  ...controllerInputs
}: UseControllerProps<TFieldValues, TName> & {
  label: string;
  description?: string;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  toValueScale?: (val: number) => number;
  fromValueScale?: (val: number) => number;
  labelScale?: (val: number) => string;
  marks: Array<{ value: number; label: string }>;
}): Partial<SliderFieldProps> & {
  label: string;
} => {
  const {
    field: { value, onChange: innerOnChange },
  } = useController(controllerInputs);

  const handleChange = useCallback(
    (_evt: any, val: any) => {
      innerOnChange(toValueScale(G.isArray(val) && val.length ? val[0] : val));
    },
    [innerOnChange, toValueScale],
  );
  return {
    value: fromValueScale(value),
    onChange: handleChange as SliderFieldProps['onChange'],
    defaultValue: defaultValue,
    label,
    min,
    max,
    step,
    valueScale: toValueScale,
    labelScale,
    marks,
    description,
  };
};
