/* eslint-disable no-nested-ternary */
import { useCallback, useMemo } from 'react';
import StatusCodes from 'http-status-codes';
import { cloneDeep } from 'lodash';
import useSwr from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { G } from '@mobily/ts-belt';
import { k8sNodeApiRef, errorFromWrappedError, k8sConfigApiRef } from '@netinsight/management-app-common-react';
import { TimeTransferSpec } from '@netinsight/crds';
import { isSyncSourceUsed } from '../components/SyncStatusOverview/utils';
import { EXTERNAL_SYNC_SOURCE_CONFIGS } from '../components/SyncStatusOverview/constants';

export const useSyncSources = () => {
  const nodeApi = useApi(k8sNodeApiRef);
  const configApi = useApi(k8sConfigApiRef);
  const fetcher = useCallback(async () => {
    const timeNodesResponse = await nodeApi.listNodes();
    const timetransferResponse = await nodeApi.getClusterTimeTransferConfigs({});
    if (timeNodesResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(timeNodesResponse.status, timeNodesResponse.body);
    }
    if (timetransferResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(timetransferResponse.status, timetransferResponse.body);
    }

    const timeNodes = timeNodesResponse.body.items.filter(
      timeNode =>
        G.isNotNullable(timetransferResponse.body[timeNode.id]) &&
        (isSyncSourceUsed(timetransferResponse.body[timeNode.id].ppsIn?.usage) ||
          isSyncSourceUsed(timetransferResponse.body[timeNode.id].gnss?.usage) ||
          timetransferResponse.body[timeNode.id].ptpReceiver?.instances?.some(instance =>
            isSyncSourceUsed(instance.usage),
          )),
    );

    const timeTransferConfig = Object.fromEntries(
      timeNodes.map(timeNode => [timeNode.id, timetransferResponse.body[timeNode.id]]),
    );

    return { timeTransferConfig };
  }, [nodeApi]);

  const { data, isLoading, mutate, error } = useSwr('syncSources', fetcher, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });

  const update = useCallback(
    async (updatedTimeTransferConfig: Record<string, TimeTransferSpec>) => {
      if (typeof data?.timeTransferConfig === 'undefined') {
        return 0;
      }
      const updatedNodes: Record<string, TimeTransferSpec> = {};
      Object.entries(updatedTimeTransferConfig).forEach(([id, updatedConfig]) => {
        const existingNodeConfig = data.timeTransferConfig[id];
        Object.entries(EXTERNAL_SYNC_SOURCE_CONFIGS).forEach(([, configGetter]) => {
          const updatedPriority = configGetter(updatedConfig)?.priority;
          const existingConfig = configGetter(existingNodeConfig);
          if (
            G.isNumber(updatedPriority) &&
            G.isNotNullable(existingConfig) &&
            existingConfig.priority !== updatedPriority
          ) {
            existingConfig.priority = updatedPriority;
            updatedNodes[id] = existingNodeConfig;
          }
        });
      });
      const updatePromises = Object.entries(updatedNodes).map(([id, config]) =>
        configApi.setNodeTimeTransferConfig({
          params: { nodeId: id },
          body: config,
        }),
      );

      await Promise.all(updatePromises);
      await mutate(cloneDeep(data));
      return updatePromises.length;
    },
    [configApi, data, mutate],
  );

  const dataCloned = useMemo(() => cloneDeep(data), [data]);

  return { data: dataCloned, isLoading, error, update };
};
