const NANOSECOND = 1;
const MICROSECOND = 1000 * NANOSECOND;
const MILLISECOND = 1000 * MICROSECOND;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

const UNIT_NANOSECOND = 'ns';
const UNIT_MICROSECOND = 'μs';
const UNIT_MILLISECOND = 'ms';
const UNIT_SECOND = 's';
const UNIT_MINUTE = 'm';
const UNIT_HOUR = 'h';

const round = (val: number, decimals: number) =>
  +`${Math.round(+`${val.toFixed(decimals)}e+${decimals}`)}e-${decimals}`;

export class DurationFormatter {
  private constructor(private readonly value: number) {}

  static fromNanoseconds(d: number): DurationFormatter {
    return new DurationFormatter(d * NANOSECOND);
  }

  static fromMicroseconds(d: number): DurationFormatter {
    return new DurationFormatter(d * MICROSECOND);
  }

  static fromMilliseconds(d: number): DurationFormatter {
    return new DurationFormatter(d * MILLISECOND);
  }

  static fromSeconds(d: number): DurationFormatter {
    return new DurationFormatter(d * SECOND);
  }

  static fromMinutes(d: number): DurationFormatter {
    return new DurationFormatter(d * MINUTE);
  }

  static fromHours(d: number): DurationFormatter {
    return new DurationFormatter(d * HOUR);
  }

  public toMicroSeconds(precision = 2): string | undefined {
    if (isNaN(this.value)) {
      return undefined;
    }
    if (this.value === 0) {
      return `${(0).toFixed(precision)}${UNIT_MICROSECOND}`;
    }
    const abs = Math.abs(this.value);
    const prefix = this.value < 0 ? '-' : '';
    const roundedPrecision = round(abs / MICROSECOND, precision);
    return `${prefix}${roundedPrecision.toFixed(precision)}${UNIT_MICROSECOND}`;
  }

  public toNanoSeconds(precision = 2): string | undefined {
    if (isNaN(this.value)) {
      return undefined;
    }
    if (this.value === 0) {
      return `${(0).toFixed(precision)}${UNIT_NANOSECOND}`;
    }
    const abs = Math.abs(this.value);
    const prefix = this.value < 0 ? '-' : '';
    const roundedPrecision = round(abs / NANOSECOND, precision);
    return `${prefix}${roundedPrecision.toFixed(precision)}${UNIT_NANOSECOND}`;
  }

  public absolute(): DurationFormatter {
    return new DurationFormatter(Math.abs(this.value));
  }

  public toString(precision = 2): string {
    if (this.value === 0) {
      return `0${UNIT_SECOND}`;
    }
    const abs = Math.abs(this.value);
    const prefix = this.value < 0 ? '-' : '';
    if (abs < MICROSECOND) {
      return `${prefix}${round(abs, precision)}${UNIT_NANOSECOND}`;
    } else if (abs < MILLISECOND) {
      return `${prefix}${round(abs / MICROSECOND, precision)}${UNIT_MICROSECOND}`;
    } else if (abs < SECOND) {
      return `${prefix}${round(abs / MILLISECOND, precision)}${UNIT_MILLISECOND}`;
    } else if (abs < MINUTE) {
      return `${prefix}${round(abs / SECOND, precision)}${UNIT_SECOND}`;
    } else if (abs < HOUR) {
      const seconds = round((abs % MINUTE) / SECOND, precision);
      const minutes = Math.floor(abs / MINUTE);
      return `${prefix}${minutes}${UNIT_MINUTE}${seconds}${UNIT_SECOND}`;
    }
    const seconds = round((abs % MINUTE) / SECOND, precision);
    const minutes = Math.floor((abs % HOUR) / MINUTE);
    const hours = Math.floor(abs / HOUR);
    return `${prefix}${hours}${UNIT_HOUR}${minutes}${UNIT_MINUTE}${seconds}${UNIT_SECOND}`;
  }
}
