import { useApi } from '@backstage/core-plugin-api';
import { inventoryApiRef } from '../api/inventory';
import useSwr, { SWRConfiguration } from 'swr';

export function useInventory<T = any>({ nodeId, kind }: { nodeId: string; kind: string }, options?: SWRConfiguration) {
  const inventoryApi = useApi(inventoryApiRef);
  return useSwr(
    [nodeId, kind, 'inventory'],
    ([nodeIdArg, kindArg]) => inventoryApi.get<T>({ nodeId: nodeIdArg, kind: kindArg }),
    options,
  );
}
