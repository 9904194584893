import {
  TimeTransferSpecSchema,
  TimeNodeSpecSchema,
  TimeNodeStatusSchema,
  TimeNodeManifestStatusSchema,
  TimeNodeManifestPodSchema,
} from '@netinsight/crds';
import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import z from 'zod';
import { WrappedErrorSchema } from './types';
import { GLOBAL_SSH_CONFIG_KEY, GLOBAL_SSH_DEFAULT_CONFIG, GlobalSSHConfig } from './config';
import { V1ConfigMap } from '@kubernetes/client-node';

const NetworkInterfaceSchema = z.object({
  name: z.string(),
  ip: z.string().optional(),
  vlanId: z.number().optional(),
  networkNamespace: z.string().optional(),
});
export type NetworkInterface = z.infer<typeof NetworkInterfaceSchema>;

export const NodeNetworkSchema = z.object({
  interfaces: z.array(NetworkInterfaceSchema),
});
export type NodeNetwork = z.infer<typeof NodeNetworkSchema>;

export const ClusterNodeNetworkSchema = z.record(NodeNetworkSchema);
export type ClusterNodeNetwork = z.infer<typeof ClusterNodeNetworkSchema>;

export const ClusterTimeTransferSchema = z.record(TimeTransferSpecSchema);
export type ClusterTimeTransfer = z.infer<typeof ClusterTimeTransferSchema>;

export const TimeNodeSchema = z.object({
  id: z.string(),
  deletionTimestamp: z.string().optional(),
  spec: TimeNodeSpecSchema,
  status: TimeNodeStatusSchema.optional(),
});
export type TimeNode = z.infer<typeof TimeNodeSchema>;

export const TimeNodeManifestSchema = z.object({
  id: z.string(),
  status: TimeNodeManifestStatusSchema.optional(),
});
export type TimeNodeManifest = z.infer<typeof TimeNodeManifestSchema>;

export type NodeWorkload = z.infer<typeof TimeNodeManifestPodSchema>;

const c = initContract();

export const nodeApi = c.router({
  getClusterNetwork: {
    method: 'GET',
    path: '/cluster/network',
    summary: 'Get network configuration for all nodes',
    responses: {
      [StatusCodes.OK]: ClusterNodeNetworkSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
  getClusterTimeTransferConfigs: {
    method: 'GET',
    path: '/cluster/timetransfer',
    summary: 'Get timetransfer configuration for all nodes',
    responses: {
      [StatusCodes.OK]: ClusterTimeTransferSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  listNodes: {
    method: 'GET',
    path: '/nodes',
    summary: 'List all time nodes',
    responses: {
      [StatusCodes.OK]: z.object({ items: z.array(TimeNodeSchema) }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getNode: {
    method: 'GET',
    path: '/node/:nodeId',
    summary: 'Get time node spec and status',
    responses: {
      [StatusCodes.OK]: TimeNodeSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  listNodeManifests: {
    method: 'GET',
    path: '/node-manifests',
    summary: 'List all time node manifests',
    responses: {
      [StatusCodes.OK]: z.object({ items: z.array(TimeNodeManifestSchema) }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getNodeStatus: {
    method: 'GET',
    path: '/nodes/:nodeId/status',
    summary: 'Get node status',
    responses: {
      [StatusCodes.OK]: TimeNodeManifestSchema.shape.status,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getNodeNetwork: {
    method: 'GET',
    path: '/nodes/:nodeId/network',
    summary: 'Get node network configuration',
    responses: {
      [StatusCodes.OK]: NodeNetworkSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  getNodeWorkload: {
    method: 'GET',
    path: '/nodes/:nodeId/workload',
    summary: 'Get workload running on node',
    responses: {
      [StatusCodes.OK]: TimeNodeManifestPodSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  upgradeNode: {
    method: 'POST',
    path: '/nodes/:nodeId/upgrade',
    summary: 'Upgrade node',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  rollbackNode: {
    method: 'POST',
    path: '/nodes/:nodeId/rollback',
    summary: 'Rollback node',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  deleteNode: {
    method: 'DELETE',
    path: '/nodes/:nodeId',
    summary: 'Delete node',
    query: z.object({
      force: z.string().optional(),
      retainNetwork: z.string().optional(),
    }),
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
});

export function parseGlobalSSHConfig(configMap?: V1ConfigMap): GlobalSSHConfig {
  try {
    if (configMap?.data && GLOBAL_SSH_CONFIG_KEY in configMap.data) {
      return JSON.parse(configMap.data[GLOBAL_SSH_CONFIG_KEY]);
    }
  } catch (_e) {
    // ignore json parse error
  }
  return GLOBAL_SSH_DEFAULT_CONFIG;
}
