/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
  useWatch,
} from 'react-hook-form';
import { usePrevious } from 'react-use';
import { ZodOptional, ZodNumber, ZodString, ZodUnion, ZodLiteral, ZodDefault, ZodNativeEnum } from 'zod';
import { G, S } from '@mobily/ts-belt';
import { TextFieldProps } from '../components';
import {
  getTooltipForNumberSchema,
  isZodDefault,
  isZodNativeEnum,
  isZodNumber,
  isZodOptional,
  isZodString,
  isZodUnion,
} from '../utils';

const roundToPrecision = (value: number, precision: number) =>
  String(Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision));

type ZodSelectType<T extends string | number> = ZodUnion<[ZodLiteral<T>, ...ZodLiteral<T>[]]>;

type ZodInputType =
  | ZodNumber
  | ZodString
  | ZodNativeEnum<any>
  | ZodSelectType<string | number>
  | ZodDefault<ZodNumber | ZodString>
  | ZodDefault<ZodSelectType<string | number>>
  | ZodDefault<ZodNativeEnum<any>>
  | ZodDefault<ZodOptional<ZodNumber | ZodString | ZodNativeEnum<any>> | ZodSelectType<string | number>>;

export const useTextFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  schema,
  isOptional = schema?.isOptional() ?? false,
  description = schema?.description,
  valueAsNumber = false,
  numberPrecision,
  label,
  placeholder,
  showInlineError = true,
  ...props
}: UseControllerProps<TFieldValues, TName> & {
  schema?: ZodOptional<ZodInputType> | ZodInputType;
  description?: string;
  valueAsNumber?: boolean;
  numberPrecision?: number;
  isOptional?: boolean;
  showInlineError?: boolean;
} & Pick<TextFieldProps, 'placeholder' | 'label'>): { field: Partial<TextFieldProps> } & Pick<
  UseControllerReturn<TFieldValues, TName>,
  'fieldState' | 'formState'
> => {
  const {
    field: { value, onChange: innerOnChange, ref, onBlur, name },
    ...otherControllerProps
  } = useController(props);

  const [textValue, setTextValue] = useState(
    (typeof value === 'number' && G.isNotNullable(numberPrecision)
      ? roundToPrecision(value, numberPrecision)
      : `${value ?? ''}`) ?? '',
  );
  const currVal = useWatch({ name, control: props.control });
  const prevCurrVal = usePrevious(currVal);
  useEffect(() => {
    if (currVal === prevCurrVal) {
      return;
    }
    if (typeof currVal === 'number') {
      const valueToSet = G.isNotNullable(numberPrecision)
        ? roundToPrecision(currVal, numberPrecision)
        : currVal.toString(10);
      if (valueToSet !== textValue) {
        setTextValue(valueToSet);
      }
    } else {
      setTextValue(currVal ?? '');
    }
  }, [currVal, prevCurrVal, textValue, numberPrecision]);

  const handleChangeAsNumber: TextFieldProps['onChange'] = useCallback(
    (evt: any) => {
      const evtVal = evt.target?.value;
      setTextValue(evtVal);
      if (G.isNullable(evtVal) || S.isEmpty(evtVal)) {
        innerOnChange(isOptional ? undefined : evtVal);
        return;
      }

      const inputValAsNumber = (evt.target as HTMLInputElement).valueAsNumber;
      const numVal = G.isNotNullable(inputValAsNumber) && !isNaN(inputValAsNumber) ? inputValAsNumber : Number(evtVal);
      if (!isNaN(numVal)) {
        innerOnChange(numVal);
        return;
      }
      innerOnChange(evtVal);
    },
    [innerOnChange, isOptional],
  );

  const handleChange: TextFieldProps['onChange'] = useCallback(
    (evt: any) => {
      const evtVal = evt.target?.value;
      if (G.isNullable(!evtVal)) {
        innerOnChange(isOptional ? undefined : evtVal);
        return;
      }
      innerOnChange(evtVal);
    },
    [innerOnChange, isOptional],
  );
  const commonProps: Partial<TextFieldProps> = useMemo(() => {
    const error = otherControllerProps.fieldState.error;
    return {
      label,
      description,
      inputRef: ref,
      onBlur,
      id: name,
      name,
      value,
      ...(error && showInlineError && (error.message?.length ?? 0) > 0
        ? { error: true, helperText: error.message }
        : {}),
    };
  }, [description, label, name, onBlur, otherControllerProps.fieldState.error, ref, showInlineError, value]);
  const commonSelectFieldProps: Partial<TextFieldProps> = useMemo(
    () => ({
      ...commonProps,
      select: true,
      SelectProps: {
        native: true,
        placeholder,
      },
    }),
    [commonProps, placeholder],
  );
  const commonTextFieldProps: Partial<TextFieldProps> = useMemo(
    () => ({
      ...commonProps,
      inputProps: {
        ...commonProps.inputProps,
        placeholder,
        ...(G.isNotNullable(numberPrecision)
          ? {
              step: roundToPrecision(Math.pow(10, -numberPrecision), numberPrecision),
            }
          : {}),
        ...(G.isNotNullable(props.rules)
          ? {
              min: props.rules?.min,
              max: props.rules?.max,
            }
          : {}),
      },
    }),
    [commonProps, placeholder, numberPrecision, props.rules],
  );

  const fieldProps = useMemo(() => {
    let result: Partial<TextFieldProps>;

    if (G.isNullable(schema)) {
      result = {
        ...commonTextFieldProps,
        onChange: valueAsNumber ? handleChangeAsNumber : handleChange,
        value: valueAsNumber ? textValue : commonTextFieldProps.value,
      };
      return result;
    }

    let innerSchema = isZodOptional(schema) ? schema.unwrap() : isZodDefault(schema) ? schema.removeDefault() : schema;
    innerSchema = isZodDefault(innerSchema)
      ? innerSchema.removeDefault()
      : isZodOptional(innerSchema)
        ? innerSchema.unwrap()
        : innerSchema;

    if (isZodUnion(innerSchema, 'string') || isZodNativeEnum(innerSchema, 'string')) {
      result = {
        ...commonSelectFieldProps,
        // avoid error when input element switch from uncontrolled to controlled state
        value: commonSelectFieldProps.value ?? '',
        onChange: handleChange,
      };
    } else if (isZodUnion(innerSchema, 'number') || isZodNativeEnum(innerSchema, 'number')) {
      result = {
        ...commonSelectFieldProps,
        value: commonSelectFieldProps.value ?? '',
        onChange: handleChangeAsNumber,
      };
    } else if (isZodString(innerSchema)) {
      result = {
        ...commonTextFieldProps,
        value: commonTextFieldProps.value ?? '',
        onChange: handleChange,
        // eslint-disable-next-line no-nested-ternary
        type: innerSchema.isEmail ? 'email' : innerSchema.isURL ? 'url' : 'text',
        inputProps: {
          ...(commonTextFieldProps.inputProps ?? {}),
          maxLength: innerSchema.maxLength !== Infinity ? innerSchema.maxLength : undefined,
        },
      };
    } else if (isZodNumber(innerSchema)) {
      result = {
        ...commonTextFieldProps,
        type: 'number',
        value: textValue,
        description: getTooltipForNumberSchema(innerSchema, description),
        inputProps: {
          ...(commonTextFieldProps.inputProps ?? {}),
          min: innerSchema.minValue,
          max: innerSchema.maxValue,
          noValidate: true,
        },
        onChange: handleChangeAsNumber,
      };
    } else {
      result = {
        ...commonTextFieldProps,
        onChange: innerOnChange,
      };
    }
    return result;
  }, [
    commonSelectFieldProps,
    commonTextFieldProps,
    description,
    handleChange,
    handleChangeAsNumber,
    innerOnChange,
    schema,
    textValue,
    valueAsNumber,
  ]);

  return { field: fieldProps, ...otherControllerProps };
};
