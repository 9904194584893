import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { k8sTTLinksApiRef, errorFromWrappedError } from '@netinsight/management-app-common-react';
import { TTLinksKey } from './useTTLinks';

export const useTTLinkDelete = () => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const remove = useCallback(
    async (_: any, { arg: deletedLink }: { arg: PersistedSyncLink }) => {
      const deleteResponse = await ttLinksApi.deleteTimeTransferLink({
        params: { id: deletedLink.id },
      });
      if (deleteResponse.status !== 200) {
        throw errorFromWrappedError(deleteResponse.status, deleteResponse.body);
      }
      return [deletedLink];
    },
    [ttLinksApi],
  );

  return useSwrMutation(TTLinksKey, remove, {
    populateCache: ([deletedLink], links) => {
      return links?.filter(link => link.id !== deletedLink.id) ?? [];
    },
    revalidate: false,
  });
};
