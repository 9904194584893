export const formatProfileSelectionStatus = (
  indices: Record<string, number>,
  endpointA: string,
  endpointB: string,
  index: number,
): string | undefined => {
  if (indices[endpointA] === index && indices[endpointB] === index) {
    return 'Selected';
  } else if (indices[endpointA] === index) {
    return 'Selected on A';
  } else if (indices[endpointB] === index) {
    return 'Selected on B';
  }
  return undefined;
};
