import { ReactNode, Reducer, useReducer, useCallback, useRef } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { G } from '@mobily/ts-belt';

type State<T> = {
  message: ReactNode;
  isConfirmed: boolean;
  isCheckingConfirmation: boolean;
  submittedValues: T | undefined;
};

type Action =
  | { type: 'submit'; wasConfirmed: boolean; submittedValues?: any }
  | { type: 'checkedConfirmation'; message: ReactNode }
  | { type: 'confirmed' }
  | { type: 'cancelled' }
  | { type: 'submitted' };

const initialState: State<any> = {
  message: null,
  isConfirmed: false,
  isCheckingConfirmation: false,
  submittedValues: undefined,
};

const reducer: Reducer<State<any>, Action> = (state, action) => {
  switch (action.type) {
    case 'submit':
      return { ...state, isCheckingConfirmation: !action.wasConfirmed, submittedValues: action.submittedValues };
    case 'submitted':
      return { ...state, isConfirmed: false, message: null, submittedValues: undefined };
    case 'checkedConfirmation':
      return { ...state, isCheckingConfirmation: false, message: action.message };
    case 'confirmed':
      return { ...state, isConfirmed: true };
    case 'cancelled':
      return { ...state, isConfirmed: false, message: null, isCheckingConfirmation: false };
    default:
      return state;
  }
};

export type ConfirmationCallback<T = any> = (submitValues: T) => ReactNode | Promise<ReactNode>;

export function useSubmitConfirmation<T extends FieldValues = FieldValues>({
  confirmationCallback,
  submitCallback,
}: {
  confirmationCallback: ConfirmationCallback<T>;
  submitCallback: SubmitHandler<T>;
}) {
  const stateRef = useRef<State<T>>(initialState);
  const [state, dispatch] = useReducer(reducer, initialState);
  stateRef.current = state;

  const submitHandler: SubmitHandler<T> = useCallback(
    async (submittedValues: T) => {
      let submitResult: unknown;
      if (stateRef.current.isConfirmed) {
        try {
          dispatch({ type: 'submit', wasConfirmed: true, submittedValues });
          submitResult = submitCallback(submittedValues ?? stateRef.current?.submittedValues);
          if (G.isPromise(submitResult)) {
            submitResult = await submitResult;
          }
        } finally {
          dispatch({ type: 'submitted' });
        }
        return submitResult;
      }

      dispatch({ type: 'submit', wasConfirmed: false, submittedValues });
      const evaluatedConfirmationResult = confirmationCallback(submittedValues);
      const evaluatedConfirmation = G.isPromise(evaluatedConfirmationResult)
        ? await evaluatedConfirmationResult
        : evaluatedConfirmationResult;
      if (G.isNullable(evaluatedConfirmation)) {
        dispatch({ type: 'checkedConfirmation', message: null });
        try {
          submitResult = submitCallback(submittedValues);
          if (G.isPromise(submitResult)) {
            submitResult = await submitResult;
          }
        } finally {
          dispatch({ type: 'submitted' });
        }
        return submitResult;
      }
      dispatch({ type: 'checkedConfirmation', message: evaluatedConfirmation });
      return void 0;
    },
    [confirmationCallback, submitCallback, dispatch],
  );

  const confirmHandler = useCallback(() => {
    dispatch({ type: 'confirmed' });
  }, [dispatch]);

  const cancelHandler = useCallback(() => {
    dispatch({ type: 'cancelled' });
  }, [dispatch]);

  const confirmAndSubmitHandler = useCallback(async () => {
    dispatch({ type: 'confirmed' });
    await Promise.resolve();
    if (stateRef.current?.submittedValues) {
      await submitHandler(stateRef.current.submittedValues);
    }
  }, [dispatch, submitHandler]);

  return {
    confirmationMessage: state.message,
    isConfirmed: state.isConfirmed,
    isCheckingConfirmation: state.isCheckingConfirmation,
    onSubmit: submitHandler,
    onSubmitConfirmed: confirmHandler,
    onClick: submitHandler,
    onConfirmed: confirmAndSubmitHandler,
    onCanceled: cancelHandler,
  };
}
