import React, { useCallback } from 'react';
import { InfoCard } from '@backstage/core-components';
import { Box, Button, TextField, makeStyles } from '@material-ui/core';
import { DateTime, Duration } from 'luxon';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { AlertHistoryResponse, useAlertHistory } from '@netinsight/management-app-common-react';
import { AlarmsHistoryTable } from './AlarmsHistoryTable';
import { useDebounce } from 'react-use';

interface DateRange {
  start: DateTime;
  end: DateTime;
}

const useStyles = makeStyles({
  datePicker: {
    minWidth: 256,
  },
});

const maxDate = DateTime.now();
const DEFAULT_QUERY_LIMIT = 25;

function isMoreToLoad(data: AlertHistoryResponse[] | undefined): boolean {
  return data ? !!data[data.length - 1].limitDate : false;
}

export const validateRange = ({ start, end }: DateRange) => {
  if (start > end) {
    return 'Start date should be earlier than end date';
  } else if (end.diff(start).as('days') >= 30) {
    // loki range query has a 721h0m0s limit
    return 'Date difference should not be more than 30 days';
  }
  return undefined;
};

export const AlarmsHistoryTableCard = () => {
  const [range, setRange] = React.useState<DateRange>({
    start: DateTime.now().plus(Duration.fromObject({ hours: -24 })),
    end: DateTime.now(),
  });
  const [search, setSearch] = React.useState<string | undefined>();
  const [debouncedSearch, setDebouncedSearch] = React.useState<string | undefined>();
  useDebounce(() => setDebouncedSearch(search), 500, [search]);
  const [error, setError] = React.useState<string | undefined>();

  const classes = useStyles();

  const validateAndSetRange = useCallback(
    ({ start, end }: { start?: string; end?: string }) => {
      const newRange = {
        start: start ? DateTime.fromISO(start) : range.start,
        end: end ? DateTime.fromISO(end) : range.end,
      };
      const rangeError = validateRange(newRange);
      setError(rangeError);

      if (!rangeError) {
        setRange(newRange);
      }
    },
    [range],
  );

  const { data, isLoading, size, setSize } = useAlertHistory(
    {
      start: range.start,
      end: range.end,
      search: debouncedSearch,
      limit: DEFAULT_QUERY_LIMIT,
    },
    !error, // re-fetch data only when error is resolved
  );

  const moreToLoad = isMoreToLoad(data);
  const allItems = (data ?? []).map(d => d.items).flat();

  return (
    <InfoCard title="Resolved Alarms">
      <Box maxWidth={800} marginTop={2} display="flex" justifyContent="space-between">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DesktopDateTimePicker
            className={classes.datePicker}
            label="Start Date"
            ampm={false}
            maxDate={maxDate}
            value={range.start}
            onChange={date => {
              validateAndSetRange({ start: date?.toString() });
            }}
            slotProps={{
              textField: {
                id: 'start-date',
                name: 'start',
                error: !!error,
                variant: 'standard',
                helperText: error,
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DesktopDateTimePicker
            className={classes.datePicker}
            label="End Date"
            ampm={false}
            maxDate={maxDate}
            value={range.end}
            onChange={date => {
              validateAndSetRange({ end: date?.toString() });
            }}
            slotProps={{
              textField: {
                name: 'end',
                id: 'end-date',
                variant: 'standard',
              },
            }}
          />
        </LocalizationProvider>
        <TextField name="search" label="Search" variant="standard" onChange={e => setSearch(e.target.value)} />
      </Box>
      <Box marginTop={2}>
        <AlarmsHistoryTable data={allItems} isLoading={isLoading} />
      </Box>
      <Box marginTop={2}>
        <Button variant="contained" onClick={() => setSize(size + 1)} disabled={isLoading || !moreToLoad}>
          {moreToLoad ? 'Load more alarms' : 'No more alarms to load'}
        </Button>
      </Box>
    </InfoCard>
  );
};
