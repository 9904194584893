import { A, F, flow, G } from '@mobily/ts-belt';
import { PrometheusQueryResponse, PrometheusVectorResultArray } from '../types';
import { isFulfilledVectorResult, isPrometheusResultType, isSuccessPrometheusResult } from './guards';
import { merge } from 'ts-deepmerge';

export const getVectorResult = (result?: PromiseSettledResult<PrometheusQueryResponse>): PrometheusVectorResultArray =>
  G.isNotNullable(result) && result && isFulfilledVectorResult(result) ? result.value.data.result : [];

const getValue =
  <T>(valueMapper: (value: string) => T) =>
  ({ value: [_, value] }: PrometheusVectorResultArray[number]): T =>
    valueMapper(value);

export const getScalarResult = <T>(valueMapper: (value: string) => T, defaultValue: T = {} as any) =>
  flow(getVectorResult, A.map(getValue(valueMapper)), A.head, F.when(G.isNullable, F.always(defaultValue)));

export const groupMetricsVector = <T>(
  data: PrometheusVectorResultArray | undefined,
  keyMapper: (metric: any) => string,
  valueMapper: (val: string, metric: any) => T,
): Record<string, T> => {
  return (data ?? []).reduce(
    (accumulator, { metric, value: [, val] }) =>
      merge(accumulator, {
        [keyMapper(metric)]: valueMapper(val, metric),
      }) as Record<string, T>,
    {},
  );
};

export const groupMetrics = <T>(
  result: PrometheusQueryResponse,
  keyMapper: (metric: any) => string,
  valueMapper: (val: string, metric: any) => T,
): Record<string, T> => {
  return isSuccessPrometheusResult(result) && isPrometheusResultType(result.data, 'vector')
    ? groupMetricsVector(result.data.result, keyMapper, valueMapper)
    : {};
};

export function groupMetricsVectorBy2Levels<T>(
  data: PrometheusVectorResultArray | undefined,
  level1KeyMapper: (metric: any) => string,
  level2KeyMapper: (metric: any) => string,
  valueMapper: (val: string, metric: any) => T,
): Record<string, Record<string, T>> {
  return (data ?? []).reduce(
    (accumulator, { metric, value: [, val] }) =>
      merge(accumulator, {
        [level1KeyMapper(metric)]: {
          [level2KeyMapper(metric)]: valueMapper(val, metric),
        },
      }) as Record<string, Record<string, T>>,
    {},
  );
}

export function groupMetricsBy2Levels<T>(
  response: PrometheusQueryResponse,
  level1KeyMapper: (metric: any) => string,
  level2KeyMapper: (metric: any) => string,
  valueMapper: (val: string, metric: any) => T,
): Record<string, Record<string, T>> {
  return isSuccessPrometheusResult(response) && isPrometheusResultType(response.data, 'vector')
    ? groupMetricsVectorBy2Levels(response.data.result, level1KeyMapper, level2KeyMapper, valueMapper)
    : {};
}

export const getScalarResultFromPromise =
  <T>(valueMapper: (value: string) => T, defaultValue: T = {} as any) =>
  (result: PromiseSettledResult<PrometheusQueryResponse>) =>
    isFulfilledVectorResult(result) ? getScalarResult(valueMapper, defaultValue)(result) : defaultValue;

export const groupMetricsFromPromise = <T>(
  result: PromiseSettledResult<PrometheusQueryResponse>,
  keyMapper: (metric: Record<string, string>) => string,
  valueMapper: (val: string, metric: Record<string, string>) => T,
): Record<string, T> => {
  return isFulfilledVectorResult(result) ? groupMetrics(result.value, keyMapper, valueMapper) : {};
};

export function groupMetricsFromPromiseBy2Levels<T>(
  response: PromiseSettledResult<PrometheusQueryResponse>,
  level1KeyMapper: (metric: Record<string, string>) => string,
  level2KeyMapper: (metric: Record<string, string>) => string,
  valueMapper: (val: string, metric: Record<string, string>) => T,
): Record<string, Record<string, T>> {
  return isFulfilledVectorResult(response)
    ? groupMetricsBy2Levels(response.value, level1KeyMapper, level2KeyMapper, valueMapper)
    : {};
}
