import React from 'react';
import {
  BackstageTypography,
  SupportedThemes,
  UnifiedThemeProvider,
  createUnifiedTheme,
  palettes,
  themes,
} from '@backstage/theme';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/NightsStay';
import { AppTheme } from '@backstage/core-plugin-api';
import { NetiColors } from '@netinsight/management-app-common-react';

const netiFontFamilies = {
  body: "'Roboto', sans-serif",
  heading: "'Roboto Slab', serif",
};

// TODO: backstage is not doing a good job with BackstageTypography (vs mui Typography)
// and UnifiedTheme (vs mui Theme) types so casting some types here to make it work.
// This may be fixed by importing defaultTypography from @backstage/theme when we upgrade to v1.24
const createThemeTypography = (theme: SupportedThemes) => {
  return {
    ...theme.typography,
    fontFamily: netiFontFamilies.body,
    h1: {
      ...theme.typography.h1,
      fontFamily: netiFontFamilies.heading,
    },
    h2: {
      ...theme.typography.h2,
      fontFamily: netiFontFamilies.heading,
    },
    h3: {
      ...theme.typography.h3,
      fontFamily: netiFontFamilies.heading,
    },
    h4: {
      ...theme.typography.h4,
      fontFamily: netiFontFamilies.heading,
    },
    h5: {
      ...theme.typography.h5,
      fontFamily: netiFontFamilies.heading,
    },
    h6: {
      ...theme.typography.h6,
      fontFamily: netiFontFamilies.heading,
    },
    subtitle1: {
      ...theme.typography.subtitle1,
      fontFamily: netiFontFamilies.body,
    },
    subtitle2: {
      ...theme.typography.subtitle2,
      fontFamily: netiFontFamilies.body,
    },
    body1: {
      ...theme.typography.body1,
      fontFamily: netiFontFamilies.body,
    },
    body2: {
      ...theme.typography.body2,
      fontFamily: netiFontFamilies.body,
    },
    button: {
      ...theme.typography.button,
      fontFamily: netiFontFamilies.body,
    },
    caption: {
      ...theme.typography.caption,
      fontFamily: netiFontFamilies.body,
    },
    overline: {
      ...theme.typography.overline,
      fontFamily: netiFontFamilies.body,
    },
  } as unknown as BackstageTypography;
};

const { dark: darkPalette, light: lightPalette } = palettes;

const netiLightTheme = createUnifiedTheme({
  palette: {
    ...lightPalette,
    primary: NetiColors.blue,
    secondary: NetiColors.red,
  },
  defaultPageTheme: 'home',
  fontFamily: netiFontFamilies.body,
  typography: createThemeTypography(themes.light.getTheme('v4')!),
});

const netiDarkTheme = createUnifiedTheme({
  palette: darkPalette,
  defaultPageTheme: 'home',
  fontFamily: netiFontFamilies.body,
  typography: createThemeTypography(themes.dark.getTheme('v4')!),
});

export const NetiLightAppTheme: AppTheme = {
  id: 'neti-light-theme',
  title: 'Light Theme',
  variant: 'light',
  icon: <LightIcon />,
  Provider: ({ children }) => <UnifiedThemeProvider theme={netiLightTheme} children={children} />,
};

export const NetiDarkAppTheme: AppTheme = {
  id: 'neti-dark-theme',
  title: 'Dark Theme',
  variant: 'dark',
  icon: <DarkIcon />,
  Provider: ({ children }) => <UnifiedThemeProvider theme={netiDarkTheme} children={children} />,
};
