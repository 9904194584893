import { makeStyles, Theme } from '@material-ui/core';

export const useFormStyles = makeStyles(
  (theme: Theme) => ({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flext-start',
      gap: theme.spacing(3),
    },
    formRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      gap: theme.spacing(3),
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(6),
      width: '100%',
    },
    formSection: {
      padding: theme.spacing(2),
      border: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(3),
      overflow: 'hidden',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    readonlyInput: {
      '& input[readonly], & input[aria-readonly]': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
    visuallyHiddenInput: {
      appearance: 'none',
      display: 'block',
      position: 'absolute',
      width: '1px',
      height: '1px',
      opacity: 0,
      top: '0',
      right: '0',
      overflow: 'hidden',
    },
  }),
  { name: 'Neti' },
);
