import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { TimeNodeListPage } from './components/TimeNodeListPage/TimeNodeListPage';
import { TimeNetworkPage } from './components/TimeNetworkPage/TimeNetworkPage';
import { TimeNodePage } from './components/TimeNodePage/TimeNodePage';
import { Root } from './components/Root';
import { apis, idpAuthApiRef, localAuthApiRef } from './apis';
import { SnackbarProvider } from 'notistack';
import { SettingsPage } from './components/Settings/SettingsPage';
import { AlarmsPage } from './components/AlarmsPage/AlarmsPage';
import { NetiDarkAppTheme, NetiLightAppTheme } from './theme';
import { LinkEndpointsDrawer } from '@netinsight/plugin-time-transfer-ui';
import { SystemsPage } from './components/SystemsPage';
import { SyncRegionContextProvider } from '@netinsight/plugin-sync-region-ui';
import { LinkDetailPage } from './components/LinkDetailPage/LinkDetailPage';
import { NotFoundErrorPage } from './components/NotFound';

const app = createApp({
  apis,
  themes: [NetiLightAppTheme, NetiDarkAppTheme],
  components: {
    NotFoundErrorPage: NotFoundErrorPage,
    SignInPage: props => (
      <SignInPage
        {...props}
        align="center"
        providers={[
          ...(process.env.NODE_ENV === 'development' ? ['guest' as const] : []),
          {
            id: 'idp',
            title: 'External Identity Provider',
            message: 'Sign In using third-party identity provider',
            apiRef: idpAuthApiRef,
          },
          {
            id: 'local',
            title: 'Static Credentials',
            message: 'Sign In using static credentials',
            apiRef: localAuthApiRef,
          },
        ]}
      />
    ),
  },
  featureFlags: [
    {
      name: 'show-advanced-settings',
      description: 'Show advanced settings',
      pluginId: '',
    },
    {
      name: 'show-experimental-features',
      description: 'Show Experimental Features',
      pluginId: '',
    },
    { name: 'enable-debug-mode', description: 'Enable debug mode', pluginId: '' },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="nodes" />} />
    <Route path="/alarms" element={<AlarmsPage />} />
    <Route path="/nodes" element={<TimeNodeListPage />} />
    <Route path="/nodes/info/:node" element={<TimeNodePage />} />
    <Route path="/network" element={<TimeNetworkPage />}>
      <Routes>
        <Route path="/new-link" element={<LinkEndpointsDrawer parentPath="/network" />} />
      </Routes>
    </Route>
    <Route path="/network/links/:linkId" element={<LinkDetailPage />} />
    <Route path="/system" element={<SystemsPage />} />
    <Route path="/settings" element={<SettingsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <SnackbarProvider
    maxSnack={5}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <AlertDisplay />
    <OAuthRequestDialog />
    <SyncRegionContextProvider>
      <AppRouter>
        <Root>{routes}</Root>
      </AppRouter>
    </SyncRegionContextProvider>
  </SnackbarProvider>,
);
