import React, { FunctionComponent } from 'react';
import { LinkButton, LinkButtonProps } from '@backstage/core-components';
import LaunchIcon from '@material-ui/icons/Launch';
import { G } from '@mobily/ts-belt';
import { makeStyles } from '@material-ui/core';
import GrafanaLogo from './grafana-icon.svg';

const useStyles = makeStyles(() => ({
  linkContainer: {
    alignSelf: 'center',
  },
  grafanaIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

export const GrafanaLink: FunctionComponent<
  Omit<LinkButtonProps, 'to'> & {
    dashboardUrl?: string;
    syncRegions?: string[];
    nodeIds?: string[];
    containers?: string[];
    linkIds?: string[];
    label?: string;
  }
> = ({ dashboardUrl, label = 'Metrics', syncRegions = [], nodeIds = [], linkIds = [], containers = [], ...props }) => {
  const styles = useStyles();
  const params = new URLSearchParams([
    ...syncRegions.map(syncRegion => ['var-region', syncRegion]),
    ...nodeIds.map(nodeId => ['var-node', nodeId]),
    ...linkIds.map(linkId => ['var-link_ids', linkId]),
    ...containers.map(container => ['var-container', container]),
  ]);
  return G.isNotNullable(dashboardUrl) ? (
    <LinkButton
      className={styles.linkContainer}
      startIcon={<img alt="Grafana logo" src={GrafanaLogo} className={styles.grafanaIcon} aria-hidden />}
      endIcon={<LaunchIcon />}
      variant="outlined"
      color="default"
      size="medium"
      to={`${dashboardUrl}${params.size > 0 ? '?' : ''}${params.toString()}`}
      {...props}
    >
      {label}
    </LinkButton>
  ) : null;
};
