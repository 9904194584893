import React, { FunctionComponent } from 'react';
import { Header } from '@backstage/core-components';
import { AlarmsSummaryContainer } from '@netinsight/management-app-common-react';

export type HeaderWithAlarmsProps = Parameters<typeof Header>[0];

export const HeaderWithAlarms: FunctionComponent<HeaderWithAlarmsProps> = props => {
  return (
    <Header {...props} style={{ minHeight: '5.5rem' }}>
      <AlarmsSummaryContainer linkTo="/alarms" />
    </Header>
  );
};
