import React from 'react';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { TimeNodeLogLevelForm } from './TimeNodeLogLevelForm';
import { useServiceLogging } from '../../hooks';
import { useGrafanaDashboards } from '@netinsight/management-app-common-react';

export const TimeNodeLoggingTab = ({ nodeId }: { nodeId: string }) => {
  const { config, isLoading, error, update } = useServiceLogging(nodeId);
  const { data: dashboards = {}, isLoading: isLoadingGrafanaDashboards } = useGrafanaDashboards();

  if (isLoading || isLoadingGrafanaDashboards) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="Logging">
      <TimeNodeLogLevelForm nodeId={nodeId} dashboards={dashboards} initialValues={config} onSubmit={update} />
    </InfoCard>
  );
};
