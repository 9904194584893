import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sNodeApiRef } from '../api';

export const useNodeNetwork = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const fetcher = useCallback(
    async ([nodeIdParam]: [string, string]) => {
      const result = await nodeApi.getNodeNetwork({
        params: {
          nodeId: nodeIdParam,
        },
      });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, result.body);
      }
      return result.body;
    },
    [nodeApi],
  );
  return useSwr([nodeId, 'getNodeNetwork'], fetcher, swrConfig);
};
