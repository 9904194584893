import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';
import { makeSearchParams } from './utils';
import { DateTime } from 'luxon';

export const diagnosticsApiRef = createApiRef<DiagnosticsApi>({
  id: 'diagnostics.api',
});

type Options = {
  discoveryApi: DiscoveryApi;
};

interface LokiQueryRangeOpts {
  basename: string;
  query: string;
  start: DateTime;
  end?: DateTime;
  mask?: boolean;
}

interface MetricsQueryRangeOpts {
  basename: string;
  timenodes: string[];
  start: DateTime;
  end?: DateTime;
  mask?: boolean;
}

export class DiagnosticsApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
  }

  private async getApiUrl() {
    return this.discoveryApi.getBaseUrl('diagnostics');
  }

  public async getLogsUrl({ basename, query, start, end, mask }: LokiQueryRangeOpts, zip?: boolean) {
    const apiUrl = await this.getApiUrl();
    const params = makeSearchParams({ basename, query, mask, zip, start: start.valueOf(), end: end?.valueOf() });
    return `${apiUrl}/logs?${params.toString()}`;
  }

  public async getMetricsUrl({ basename, timenodes, start, end, mask }: MetricsQueryRangeOpts, zip?: boolean) {
    const apiUrl = await this.getApiUrl();
    const params = makeSearchParams({ basename, timenodes, mask, zip, start: start.valueOf(), end: end?.valueOf() });
    return `${apiUrl}/metrics?${params.toString()}`;
  }
}
