import { useContext, useMemo } from 'react';
import { SyncRegionContext } from '../context';

export const useFilterBySyncRegion = <T>(items: T[], regionSelector: (input: T) => string[]) => {
  const { currentValue, setCurrentValue } = useContext(SyncRegionContext);

  const filteredItems = useMemo(() => {
    return currentValue && currentValue.length > 0
      ? items.filter(item => currentValue.some(region => regionSelector(item).includes(region)))
      : items;
  }, [regionSelector, items, currentValue]);

  return {
    items: filteredItems,
    syncRegion: currentValue,
    onSyncRegionChange: setCurrentValue,
  };
};
