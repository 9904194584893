/* eslint-disable no-nested-ternary */
import { useEffect, useState, useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sConfigApiRef } from '@netinsight/management-app-common-react';
import { TimeNodeSpec } from '@netinsight/management-app-common-api';
import StatusCodes from 'http-status-codes';

export const useTimeNodeConfig = (nodeId: string) => {
  const configApi = useApi(k8sConfigApiRef);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TimeNodeSpec | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const getConfig = useCallback(async () => {
    try {
      const response = await configApi.getNodeTimeNodeConfig({ params: { nodeId } });
      if (response.status === StatusCodes.OK) {
        setData(response.body.config);
      } else {
        setError(errorFromWrappedError(response.status, (response as any).body));
      }
    } catch (e: any) {
      setError(e);
      return;
    }
  }, [configApi, nodeId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getConfig().finally(() => setLoading(false));
  }, [getConfig]);

  const uploadConfig = useCallback(
    async (newConfig: TimeNodeSpec) => {
      const response = await configApi.setNodeTimeNodeConfig({ params: { nodeId }, body: newConfig });
      if (response.status !== StatusCodes.OK) {
        // TODO: this is not gonna work when body.errors array is set
        setError(errorFromWrappedError(response.status, response.body));
      }
      await getConfig();
    },
    [nodeId, configApi, getConfig],
  );

  return {
    data,
    error,
    loading,
    uploadConfig,
  };
};
