import React, { FunctionComponent, useCallback } from 'react';
import { F } from '@mobily/ts-belt';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { useSnackbarHelper } from '@netinsight/management-app-common-react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFormStyles, TextField, useTextFieldController } from '@netinsight/management-app-common-react';
import { AlarmSubscriptionSpec, AlarmSubscriptionSpecSchema } from '@netinsight/crds';
import { z } from 'zod';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

type AlarmSubscriptionFormData = z.infer<typeof AlarmSubscriptionSpecSchema>;

const DefaultFormValues: AlarmSubscriptionFormData = {
  name: '',
  purpose: '',
  target: '',
  format: 'prometheus',
};

export const AlarmSubscriptionDrawerContent: FunctionComponent<{
  onClose: VoidFunction;
  onCreate: (spec: AlarmSubscriptionSpec) => Promise<void>;
}> = ({ onClose = F.ignore, onCreate = F.ignore }) => {
  const { snackbar } = useSnackbarHelper();

  const formProps = useForm({
    defaultValues: DefaultFormValues,
    mode: 'onChange',
    resolver: zodResolver(AlarmSubscriptionSpecSchema),
  });
  const { control, handleSubmit } = formProps;

  const handleCreateOrUpdate = useCallback(
    async (submitData: AlarmSubscriptionFormData) => {
      try {
        await onCreate(submitData);
        snackbar.notifySuccess('Created subscription');
      } catch (_err) {
        snackbar.error('Subscription creation failed');
      }
      onClose();
    },
    [onCreate, onClose, snackbar],
  );

  const styles = useStyles();
  const formStyles = useFormStyles();

  const { field: nameInputProps } = useTextFieldController({
    control,
    name: 'name',
    label: 'Name',
    placeholder: 'Auto',
    description: 'Name for the subscription',
    schema: AlarmSubscriptionSpecSchema.shape.name,
  });

  const { field: purposeInputProps } = useTextFieldController({
    control,
    name: 'purpose',
    label: 'Purpose',
    placeholder: 'Auto',
    description: 'Freetext purpose for this subscription',
    schema: AlarmSubscriptionSpecSchema.shape.purpose,
  });

  const { field: targetInputProps } = useTextFieldController({
    control,
    name: 'target',
    label: 'Target',
    placeholder: 'Auto',
    description: 'Webhook to call',
    schema: AlarmSubscriptionSpecSchema.shape.target,
  });

  return (
    <FormProvider {...formProps}>
      <form
        onSubmit={handleSubmit(handleCreateOrUpdate as SubmitHandler<AlarmSubscriptionFormData>)}
        className={classNames(styles.container, formStyles.formContainer)}
        data-testid="alarm-subscription-add-form"
      >
        <TextField fullWidth {...nameInputProps} />
        <TextField fullWidth {...purposeInputProps} />
        <TextField fullWidth {...targetInputProps} />
        <div className={formStyles.buttonContainer}>
          <Button variant="contained" color="primary" size="small" type="submit" data-testid="btn-save-subscription">
            Create
          </Button>
          <Button
            variant="contained"
            color="default"
            size="small"
            type="button"
            data-testid="btn-cancel-subscription"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
