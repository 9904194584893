import React, { FunctionComponent } from 'react';
import { Drawer, DrawerProps, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';
import { F } from '@mobily/ts-belt';
import classNames from 'classnames';

const useStyles = makeStyles(
  theme => ({
    drawer: {
      paddingTop: theme.spacing(3),
      width: 'calc(max(30rem, 25vw))',
    },
    linkFormCancelButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }),
  { name: 'NetiDrawerPanel' },
);

export type DrawerPanelProps = DrawerProps & {
  onCloseClick?: VoidFunction;
};

export const DrawerPanel: FunctionComponent<DrawerPanelProps> = ({
  children,
  onCloseClick = F.ignore,
  onClose = F.ignore,
  className,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      classes={{ paper: classNames(styles.drawer, className) }}
      onClose={onClose}
      {...props}
    >
      <>
        <IconButton
          aria-label="Close"
          className={styles.linkFormCancelButton}
          onClick={onCloseClick}
          data-testid="btn-close-drawer"
        >
          <CloseIcon />
        </IconButton>
        {children}
      </>
    </Drawer>
  );
};
