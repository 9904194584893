import { useCallback } from 'react';
import useSwr from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sNodeApiRef } from '@netinsight/management-app-common-react';
import { TimeNode, TimeNodeManifest } from '@netinsight/management-app-common-api';
import StatusCodes from 'http-status-codes';

export interface TimeNodeWithManifest extends TimeNode {
  manifest?: TimeNodeManifest;
}

export const useTimeNodes = ({ interval }: { interval?: number } = {}) => {
  const nodeApi = useApi(k8sNodeApiRef);

  const getNodeConfig = useCallback(async () => {
    const result = await nodeApi.listNodes({});
    const manifestResult = await nodeApi.listNodeManifests({});
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, (result as any).body);
    }
    if (manifestResult.status !== StatusCodes.OK) {
      throw errorFromWrappedError(manifestResult.status, (manifestResult as any).body);
    }
    return result.body.items.map(timeNode => {
      const manifest = manifestResult.body.items.find(item => item.id === timeNode.id);
      return {
        ...timeNode,
        manifest,
      };
    });
  }, [nodeApi]);

  const { isLoading, error, data, mutate, isValidating } = useSwr<TimeNodeWithManifest[]>('listNodes', getNodeConfig, {
    revalidateOnFocus: false,
    refreshInterval: interval,
  });

  return {
    error,
    data,
    isLoading,
    isValidating,
    mutate,
  };
};
