import { G } from '@mobily/ts-belt';
import { TimeTransferSpec } from '@netinsight/crds';

export const getDuplicatedPriorities = (config: TimeTransferSpec) => {
  const priorityMap = {
    gnss: config.gnss?.priority,
    ppsIn: config.ppsIn?.priority,
  };
  return Object.entries(
    Object.entries(priorityMap)
      .filter(([, priority]) => G.isNotNullable(priority))
      .reduce(
        (acc, [key, priority]) => ({
          ...acc,
          [priority!.toString()]: [...(acc[priority!.toString()] ?? []), key],
        }),
        {} as Record<string, string[]>,
      ),
  ).filter(([, keys]) => keys.length > 1);
};
