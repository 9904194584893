import { useCallback, useRef } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Subscription } from '@backstage/types';
import { inventoryApiRef } from '../api/inventory';
import useSwr, { SWRSubscription } from 'swr/subscription';
import type { SWRConfiguration } from 'swr';

export const useInventoryWatch = <T = any>(
  { nodeId, kind }: { nodeId: string; kind: string },
  config?: SWRConfiguration,
) => {
  const inventoryApi = useApi(inventoryApiRef);
  const observableRef = useRef<Subscription>();
  const subscribe: SWRSubscription<[string, string, string], T> = useCallback(
    ([nodeIdParam, kindParam], { next }) => {
      inventoryApi.watch<T>({ nodeId: nodeIdParam, kind: kindParam }).then(
        observable => {
          observableRef.current = observable.subscribe(
            val => next(undefined, val.data),
            error => next(error),
          );
        },
        error => next(error),
      );

      return () => {
        if (observableRef.current) {
          observableRef.current.unsubscribe();
        }
      };
    },
    [inventoryApi],
  );

  const { data, error } = useSwr([nodeId, kind, 'inventory'], subscribe, config);
  return {
    state: data,
    error: error,
    loading: !data && !error,
  };
};
