import { useCallback } from 'react';
import StatusCodes from 'http-status-codes';
import useSwr from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sGrafanaDashboardsApiRef } from '../api';

export const useGrafanaDashboards = () => {
  const api = useApi(k8sGrafanaDashboardsApiRef);
  const fetch = useCallback(async () => {
    const result = await api.getGrafanaDashboards();
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, result.body as any);
    }
    return result.body;
  }, [api]);
  return useSwr('useGrafanaDashboards', fetch);
};
