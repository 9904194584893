import React from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export interface AutocompleteTextFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  name: TName;
  id?: string;
  label: string;
  control: Control<TFieldValues, any>;
  options: string[];
  style?: React.CSSProperties;
  loading?: boolean;
  rules?: React.ComponentProps<typeof Controller>['rules'];
}

export const AutocompleteTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  id,
  label,
  control,
  options,
  style,
  loading,
  rules,
}: AutocompleteTextFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control as Control<FieldValues, any>}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          freeSolo
          id={id}
          value={value}
          loading={loading}
          options={options}
          style={style}
          onInputChange={(_, newValue: string | null) => onChange(newValue)}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              label={label}
              helperText={error?.message}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size="1em" /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
