import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';
import { errorFromWrappedError, k8sNodeApiRef } from '../api';

export const useNodeNameMap = (swrConfig?: SWRConfiguration) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const fetcher = useCallback(async () => {
    const result = await nodeApi.listNodes({});
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, (result as any).body);
    }
    const nodeNameMap = Object.fromEntries(
      result.body?.items?.map(timeNode => [timeNode.id, timeNode.spec.name ?? timeNode.id] as [string, string]) ?? [],
    );

    return nodeNameMap;
  }, [nodeApi]);

  return useSwr('useNodeNameMap', fetcher, swrConfig);
};
