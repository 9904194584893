import { createPlugin, createComponentExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const nodeManagerPlugin = createPlugin({
  id: 'node-manager',
  routes: {
    root: rootRouteRef,
  },
});

export const NodeManagerInterfaces = nodeManagerPlugin.provide(
  createComponentExtension({
    name: 'NodeManagerInterfaces',
    component: {
      lazy: () => import('./components/NodeManagerInterfaces').then(m => m.NodeManagerInterfaces),
    },
  }),
);

export const NodeManagerNetworkNamespaces = nodeManagerPlugin.provide(
  createComponentExtension({
    name: 'NodeManagerNetworkNamespaces',
    component: {
      lazy: () => import('./components/NodeManagerNetworkNamespaces').then(m => m.NodeManagerNetworkNamespaces),
    },
  }),
);

export const NodeManagerDnsConfig = nodeManagerPlugin.provide(
  createComponentExtension({
    name: 'NodeManagerDnsConfig',
    component: {
      lazy: () => import('./components/DnsConfig').then(m => m.DnsConfig),
    },
  }),
);

export const NodeManagerSynceConfig = nodeManagerPlugin.provide(
  createComponentExtension({
    name: 'NodeManagerSynceConfig',
    component: {
      lazy: () => import('./components/SynceConfig').then(m => m.SyncEConfig),
    },
  }),
);
