import React from 'react';
import { Page, ResponseErrorPanel, TabbedLayout } from '@backstage/core-components';
import { LinkDetailForm, useTTLink } from '@netinsight/plugin-time-transfer-ui';
import { HeaderWithAlarms } from '../HeaderWithAlarms/HeaderWithAlarms';
import { useParams } from 'react-router';
import { LinearProgress } from '@material-ui/core';

export const LinkDetailPage = () => {
  const { linkId } = useParams<{ linkId?: string }>();
  const {
    data: link,
    error,
    isLoading: isLoadingLink,
  } = useTTLink(linkId, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });

  if (isLoadingLink) {
    return <LinearProgress />;
  }

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (link) {
    return (
      <Page themeId="service">
        <HeaderWithAlarms type="Links" typeLink="/network" title={link.name ?? link.id} />
        <TabbedLayout>
          <TabbedLayout.Route title="General" path="/" tabProps={{ id: 'link-detail-general-tab' }}>
            <LinkDetailForm mode="general" data={link} />
          </TabbedLayout.Route>
          <TabbedLayout.Route title="Endpoints" path="/endpoints" tabProps={{ id: 'link-detail-endpoints-tab' }}>
            <LinkDetailForm mode="endpoints" data={link} />
          </TabbedLayout.Route>
          <TabbedLayout.Route title="Profiles" path="/profiles" tabProps={{ id: 'link-detail-profiles-tab' }}>
            <LinkDetailForm mode="profiles" data={link} />
          </TabbedLayout.Route>
          <TabbedLayout.Route title="Options" path="/options" tabProps={{ id: 'link-detail-options-tab' }}>
            <LinkDetailForm mode="link-options" data={link} />
          </TabbedLayout.Route>
        </TabbedLayout>
      </Page>
    );
  }

  return null;
};
