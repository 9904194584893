import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { Table, TableProps, TableColumn } from '@backstage/core-components';
import { useTableState, useGroupCalibrationResults } from '@netinsight/management-app-common-react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PlayIcon from '@material-ui/icons/PlayArrow';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { GroupCalibrationResult } from '@netinsight/group-calibration-api';
import DetailPane from './DetailPane';

type GroupCalibrationRow = {
  id: number;
  startTime: string;
  endTime?: string;
  preset: string;
  trigger: string;
  success?: boolean;
  errorMessage?: string;
};

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  cellIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  warningIcon: {
    fill: theme.palette.warning.main,
  },
  statusWarningIcon: {
    width: '1.25rem',
    marginLeft: '0.125rem',
  },
  okIcon: {
    fill: theme.palette.success.main,
  },
  activeIcon: {
    fill: theme.palette.info.main,
  },
  errorIcon: {
    fill: theme.palette.error.main,
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  cellNoWrap: {
    flexWrap: 'nowrap',
  },
  cellActionIcon: {
    fill: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.grey[700],
  },
}));

export const GroupCalibrationTable = () => {
  const styles = useStyles({});
  const { data: results, isLoading } = useGroupCalibrationResults({ refreshInterval: 10000 });

  const columns: TableColumn<GroupCalibrationRow>[] = useMemo(() => {
    const successText = (success: boolean | undefined) => {
      if (success === true) {
        return 'Success';
      } else if (success === false) {
        return 'Error';
      }
      return 'Running';
    };

    const successIcon = (success: boolean | undefined) => {
      if (success === true) {
        return (
          <Grid direction="row" spacing={1}>
            <CheckCircleOutlineIcon className={cx(styles.cellIcon, styles.okIcon)} />
            <Typography component="span" variant="body2" noWrap data-testid="calibration-result-success">
              {successText(success)}
            </Typography>
          </Grid>
        );
      } else if (success === false) {
        return (
          <Grid direction="row" spacing={1}>
            <ErrorOutlineIcon className={cx(styles.cellIcon, styles.errorIcon)} />
            <Typography component="span" variant="body2" noWrap data-testid="calibration-result-success">
              {successText(success)}
            </Typography>
          </Grid>
        );
      }
      return (
        <Grid direction="row" spacing={1}>
          <CircularProgress size="1.25rem" />
          <Typography component="span" variant="body2" noWrap data-testid="calibration-result-success">
            {successText(success)}
          </Typography>
        </Grid>
      );
    };

    const triggerIcon = (row: GroupCalibrationRow) => {
      return row.trigger === 'schedule' ? (
        <Grid direction="row" spacing={1}>
          <ScheduleIcon className={cx(styles.cellIcon, styles.activeIcon)} />
          <Typography component="span" variant="body2" noWrap>
            Schedule
          </Typography>
        </Grid>
      ) : (
        <Grid direction="row" spacing={1}>
          <PlayIcon className={cx(styles.cellIcon, styles.activeIcon)} />
          <Typography component="span" variant="body2" noWrap>
            Manual
          </Typography>
        </Grid>
      );
    };

    const rowMessage = (row: GroupCalibrationRow): string => {
      if (row.errorMessage) {
        return row.errorMessage;
      }
      if (row.endTime) {
        if (row.success === true) {
          return 'Calibration successful.';
        }
        return 'Calibration error.';
      }
      return 'Calibration in progress...';
    };

    return [
      {
        title: 'ID',
        field: 'id',
        defaultSort: 'desc',
        width: '3rem',
        render: row => (
          <Typography component="span" variant="body2" data-testid="calibration-result-id">
            {row.id}
          </Typography>
        ),
      },
      {
        title: 'Start Time',
        field: 'startTime',
        defaultSort: 'desc',
        render: row => (
          <Typography component="span" variant="body2" noWrap>
            {row.startTime.toLocaleString()}
          </Typography>
        ),
      },
      {
        title: 'End Time',
        field: 'endTime',
        render: row => (
          <Typography component="span" variant="body2" noWrap>
            {row.endTime?.toLocaleString()}
          </Typography>
        ),
      },
      {
        title: 'Status',
        field: 'success',
        filtering: true,
        render: row => successIcon(row.success),
        customFilterAndSearch: (term, row) => successText(row.success).toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: 'Trigger',
        field: 'trigger',
        filtering: true,
        render: row => triggerIcon(row),
      },
      {
        title: 'Group',
        field: 'preset',
        filtering: true,
        render: row => (
          <Typography component="span" variant="body2" noWrap data-testid="calibration-result-group">
            <Box fontStyle="italic">{row.preset}</Box>
          </Typography>
        ),
      },
      {
        title: 'Message',
        field: 'errorMessage',
        filtering: true,
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: '10rem',
        },
        render: row => (
          <Typography component="span" variant="body2" noWrap data-testid="calibration-result-message">
            {rowMessage(row)}
          </Typography>
        ),
        customFilterAndSearch: (term, row) => rowMessage(row).toLowerCase().includes(term.toLowerCase()),
      },
    ];
  }, [styles.activeIcon, styles.cellIcon, styles.errorIcon, styles.okIcon]);

  const rows: GroupCalibrationRow[] = useMemo(() => {
    return (
      results?.map((result: GroupCalibrationResult) => {
        return {
          id: result.id,
          success: result.success,
          startTime: result.startTime,
          endTime: result.endTime,
          preset: result.options.preset,
          trigger: result.trigger,
          errorMessage: result.errorMessage,
        };
      }) ?? []
    );
  }, [results]);

  const options: TableProps<GroupCalibrationRow>['options'] = {
    sorting: true,
    columnsButton: true,
    actionsColumnIndex: -1,
    padding: 'dense',
    emptyRowsWhenPaging: false,
    thirdSortClick: false,
  };

  const tableStates = useTableState<GroupCalibrationRow>(columns, options, 'group-calibration-table');

  return (
    <Table<GroupCalibrationRow>
      data={rows}
      emptyContent={<Typography className={styles.empty}>No completed calibrations.</Typography>}
      isLoading={isLoading}
      detailPanel={[
        {
          tooltip: 'Show details',
          render: params => <DetailPane jobId={params.rowData.id} />,
        },
      ]}
      {...tableStates}
    />
  );
};
