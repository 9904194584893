import { createApiRef, DiscoveryApi, FetchApi, IdentityApi } from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types';
import { NodeGraphState } from '@netinsight/management-app-common-api';
import { EventSourcePolyfill } from 'event-source-polyfill';
import ObservableImpl from 'zen-observable';
import { errorFromWrappedError } from './errors';

const DEFAULT_PROXY_PATH = '/inventory';

const MAX_RETRY_TIMEOUT = 3000;

export const inventoryApiRef = createApiRef<InventoryApi>({
  id: 'plugin.metrics.inventory',
});

export interface InventoryEntry<T = any> {
  node: string;
  kind: string;
  data?: T;
}

export interface Options {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  fetchApi: FetchApi;
}

class Watcher {
  private sse?: EventSource;
  private reconnectTimer?: NodeJS.Timeout;
  private open = false;

  constructor(
    private readonly subscriber: ZenObservable.SubscriptionObserver<object>,
    private readonly url: string,
    private readonly identityApi: IdentityApi,
    private readonly params: object,
  ) {}

  async start() {
    const { token } = await this.identityApi.getCredentials();
    const sse = new EventSourcePolyfill(this.url, {
      withCredentials: true,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });
    this.sse = sse;

    sse.onmessage = event => {
      try {
        this.subscriber.next({ ...this.params, data: JSON.parse(event.data) });
      } catch (ex) {
        this.subscriber.error(ex);
        this.stop();
      }
    };

    sse.onopen = () => {
      this.open = true;
    };

    sse.onerror = () => {
      if (!this.open) {
        this.subscriber.error(new Error('An error occurred while attempting to connect'));
        this.stop();
        return;
      }
      if (this.sse) {
        this.sse?.close();
        this.open = false;
        const timeout = Math.random() * MAX_RETRY_TIMEOUT;
        this.reconnectTimer = setTimeout(async () => {
          await this.start();
        }, timeout);
      }
    };
  }

  stop() {
    this.open = false;
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
    if (this.sse) {
      this.sse.close();
    }
  }
}

type InventoryGetParams = { nodeId: string; kind: string };

export class InventoryApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
    this.fetchApi = options.fetchApi;
  }

  private async getUrl(node: string, kind: string) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${DEFAULT_PROXY_PATH}/api/${node}/${kind}`;
  }

  async get<T = any>({ nodeId, kind }: InventoryGetParams) {
    const url = await this.getUrl(nodeId, kind);
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    return (await response.json()) as InventoryEntry<T>;
  }

  async getAll<T>({ nodeIds, kinds }: { nodeIds: string[]; kinds: string[] }) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const params = new URLSearchParams(
      nodeIds.map(nodeId => ['node', nodeId]).concat(kinds.map(kind => ['kind', kind])),
    );
    const url = `${proxyUrl}${DEFAULT_PROXY_PATH}/api/nodes?${params.toString()}`;
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw new Error(`failed to fetch data, status ${response.status}: ${response.statusText}`);
    }
    return (await response.json()) as Record<string, T>;
  }

  private async getWatchUrl(nodeId: string, kind: string) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${DEFAULT_PROXY_PATH}/api/${nodeId}/${kind}/watch`;
  }

  async watch<T = any>({ nodeId, kind }: InventoryGetParams): Promise<Observable<InventoryEntry<T>>> {
    const url = await this.getWatchUrl(nodeId, kind);
    return new ObservableImpl(subscriber => {
      const watcher = new Watcher(subscriber, url, this.identityApi, { nodeId, kind });
      void watcher.start().catch(err => subscriber.error(err));
      return () => {
        if (watcher) {
          watcher.stop();
        }
      };
    });
  }

  private async getNodeGraphStateUrl(id: string) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${DEFAULT_PROXY_PATH}/api/node-graph-state/${id}`;
  }

  async getNodeGraphState(id: string): Promise<NodeGraphState> {
    const url = await this.getNodeGraphStateUrl(id);
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      const { status, statusText } = response;
      const body = { status, statusText, error: await response.text() };
      throw errorFromWrappedError(response.status, body);
    }
    const body = await response.json();
    return body;
  }

  async listNodeGraphStates(): Promise<{ id: string; name: string; timestamp?: number }[]> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${proxyUrl}${DEFAULT_PROXY_PATH}/api/node-graph-states`;
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      const { status, statusText } = response;
      const body = { status, statusText, error: await response.text() };
      throw errorFromWrappedError(response.status, body);
    }
    const body = await response.json();
    return body;
  }

  async postNodeGraphState(data: NodeGraphState): Promise<{ id: string }> {
    const url = await this.getNodeGraphStateUrl('');
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const { status, statusText } = response;
      const body = { status, statusText, error: await response.text() };
      throw errorFromWrappedError(response.status, body);
    }
    const body = await response.json();
    return body;
  }

  async putNodeGraphState(id: string, data: NodeGraphState) {
    const url = await this.getNodeGraphStateUrl(id);
    const response = await this.fetchApi.fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const { status, statusText } = response;
      const body = { status, statusText, error: await response.text() };
      throw errorFromWrappedError(response.status, body);
    }
  }

  async deleteNodeGraphState(id: string) {
    const url = await this.getNodeGraphStateUrl(id);
    const response = await this.fetchApi.fetch(url, {
      method: 'DELETE',
    });
    if (!response.ok) {
      const { status, statusText } = response;
      const body = { status, statusText, error: await response.text() };
      throw errorFromWrappedError(response.status, body);
    }
  }
}
