import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sSyncRegionApiRef } from '@netinsight/management-app-common-react';

export const SyncRegionSwrKey = 'useSyncRegions';

export const useSyncRegions = (swrConfig?: SWRConfiguration) => {
  const syncRegionApi = useApi(k8sSyncRegionApiRef);
  const fetcher = useCallback(async () => {
    const result = await syncRegionApi.listSyncRegions({});
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, (result as any).body);
    }

    return result.body;
  }, [syncRegionApi]);

  return useSwr(SyncRegionSwrKey, fetcher, swrConfig);
};
