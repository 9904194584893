import { alpha, darken, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { Children, Fragment, FunctionComponent, isValidElement, PropsWithChildren } from 'react';
import { Routes, Route, RouteProps, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      position: 'relative',
      background: theme.palette.background.paper,
      marginTop: -theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '6px',
        background: `linear-gradient(180deg, ${darken(theme.palette.background.paper, 0.125)} 0%, ${alpha(
          theme.palette.background.paper,
          0,
        )} 100%)`,
        opacity: 0.75,
        zIndex: theme.zIndex.mobileStepper,
      },
    },
    navLink: {
      padding: theme.spacing(2),
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&.active': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        borderStyle: 'solid',
        borderBottomWidth: '2px',
      },
      '&:not(.active)': {
        color: theme.palette.text.disabled,
      },
    },
  }),
  { name: 'NetiSubtab' },
);

type NestedTabRouteProps = RouteProps &
  PropsWithChildren<{
    title: string;
  }>;

const NestedTabRoute: FunctionComponent<NestedTabRouteProps> = () => null;

const routeType = (<NestedTabRoute path="" title="" />).type;

function getPropsFromChildren(children: React.ReactNode) {
  return Children.toArray(children)
    .filter(child => isValidElement(child))
    .flatMap((child: any): { navLink: { to: string; label: string }; route: RouteProps }[] => {
      if (child.type === Fragment) {
        return getPropsFromChildren(child.props.children);
      }
      if (child.type !== routeType) {
        throw new Error('Child of NestedTabLayout must be of type NestedTabLayout.Route');
      }

      const { title, ...routeProps } = child.props as NestedTabRouteProps;
      return [{ navLink: { to: routeProps.index === true ? '' : routeProps.path!, label: title }, route: routeProps }];
    });
}

export const NestedTabLayout = (props: PropsWithChildren<any>) => {
  const childrenProps = getPropsFromChildren(props.children);
  const navLinkProps = childrenProps.filter(cp => !cp.route.index).map(cp => cp.navLink);
  const routeProps = childrenProps.map(cp => cp.route);
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <Tabs
        textColor="primary"
        TabIndicatorProps={{ style: { display: 'none' } }}
        className={classes.container}
        value={0}
      >
        {navLinkProps.map((nlp, index) => (
          <Tab key={index} component={NavLink} className={classes.navLink} {...nlp} />
        ))}
      </Tabs>
      <Routes location={location}>
        {routeProps.map((rp, index) => (
          <Route key={index} {...rp} />
        ))}
      </Routes>
    </>
  );
};

NestedTabLayout.Route = NestedTabRoute;
