import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { OptionsObject, ProviderContext, SnackbarAction } from 'notistack';

export interface ISnackActions {
  notifySuccess(action: string): void;
  notifyError(action: string, response: any, message: string | null, variant?: 'error' | 'warning'): void;
  success(message: string, options?: OptionsObject): void;
  warning(message: string, options?: OptionsObject): void;
  info(message: string, options?: OptionsObject): void;
  error(message: string, options?: OptionsObject): void;
  toast(message: string, options?: OptionsObject): void;
}

export class SnackActions implements ISnackActions {
  constructor(private readonly snackbar: ProviderContext) {}

  notifySuccess(action: string) {
    this.success(`${action} successful`);
  }
  notifyError(action: string, response: any, message: string | null, variant: 'error' | 'warning' = 'error') {
    let snackMessage = '';
    const options: OptionsObject = { variant };

    if (response) {
      if (response.body.errors) {
        const errors = response.body.errors.map((e: any) => `${e.message} ${e.path.join(' ')}`).join('\n');
        snackMessage = `Failed to ${action}: Code ${response.status}\n${errors}`;
        options.style = { whiteSpace: 'pre-line' };
      }
      if (response.body.error) {
        snackMessage = `Failed to ${action}: Code ${response.status}  ${response.body.error.message}`;
      }
    }
    if (message) {
      snackMessage = `Failed to ${action}: ${message}`;
    }
    if (!response && !message) {
      snackMessage = `Failed to ${action}`;
    }

    options.persist = options.variant === 'error';
    this.toast(snackMessage, options);
  }

  // Default methods
  success(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'success' });
  }
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'warning' });
  }
  info(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'info' });
  }
  error(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'error' });
  }

  toast(msg: string, options: OptionsObject = { variant: 'default' }) {
    const action: SnackbarAction = snackbarId => (
      <IconButton
        size="small"
        onClick={() => {
          this.snackbar.closeSnackbar(snackbarId);
        }}
      >
        <CloseIcon htmlColor="white" />
      </IconButton>
    );

    this.snackbar.enqueueSnackbar(msg, { action, ...options });
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
export class SnackActionsNoop implements ISnackActions {
  notifySuccess() {}
  notifyError() {}
  success() {}
  warning() {}
  info() {}
  error() {}
  toast() {}
}
/* eslint-enable @typescript-eslint/no-explicit-any  */
