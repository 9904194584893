import { createApiRef } from '@backstage/core-plugin-api';
import {
  ttLinksApi,
  systemApi,
  nodeApi,
  onboardingApi,
  configApi,
  groupCalibrationApi,
  syncRegionApi,
  grafanaDashboardsApi,
  alarmSubscriptionApi,
} from '@netinsight/management-app-common-api';
import { InitClientReturn, InitClientArgs } from '@ts-rest/core';

export type SystemApiClient = InitClientReturn<typeof systemApi, InitClientArgs>;
export type NodeApiClient = InitClientReturn<typeof nodeApi, InitClientArgs>;
export type OnboardingApiClient = InitClientReturn<typeof onboardingApi, InitClientArgs>;
export type ConfigApiClient = InitClientReturn<typeof configApi, InitClientArgs>;
export type SyncRegionClient = InitClientReturn<typeof syncRegionApi, InitClientArgs>;
export type TTLinksClient = InitClientReturn<typeof ttLinksApi, InitClientArgs>;
export type GroupCalibrationClient = InitClientReturn<typeof groupCalibrationApi, InitClientArgs>;
export type GrafanaDashboardsClient = InitClientReturn<typeof grafanaDashboardsApi, InitClientArgs>;
export type AlarmSubscriptionClient = InitClientReturn<typeof alarmSubscriptionApi, InitClientArgs>;

export const k8sSystemApiRef = createApiRef<SystemApiClient>({
  id: 'plugin.k8s.system',
});

export const k8sNodeApiRef = createApiRef<NodeApiClient>({
  id: 'plugin.k8s.node',
});

export const k8sOnboardingApiRef = createApiRef<OnboardingApiClient>({
  id: 'plugin.k8s.onboarding',
});

export const k8sConfigApiRef = createApiRef<ConfigApiClient>({
  id: 'plugin.k8s.config',
});

export const k8sTTLinksApiRef = createApiRef<TTLinksClient>({
  id: 'plugin.k8s.ttlinks',
});

export const k8sSyncRegionApiRef = createApiRef<SyncRegionClient>({
  id: 'plugin.k8s.sync-region',
});

export const k8sGroupCalibrationApiRef = createApiRef<GroupCalibrationClient>({
  id: 'plugin.k8s.groupcalibration',
});

export const k8sGrafanaDashboardsApiRef = createApiRef<GrafanaDashboardsClient>({
  id: 'plugin.k8s.grafanadashboards',
});

export const k8sAlarmSubscriptionsApiRef = createApiRef<AlarmSubscriptionClient>({
  id: 'plugin.k8s.alarmsubscriptions',
});
