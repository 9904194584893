import { useMemo } from 'react';
import { merge } from 'ts-deepmerge';
import { DEFAULT_LINK_OPTIONS, DEFAULT_LINK_OPTIONS_VIRTUAL_EDGE } from '@netinsight/crds';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { useTimeNode } from '@netinsight/management-app-common-react';
import { useGlobalLinkOptions } from './useGlobalLinkOptions';

export const useDefaultLinkOptions = (data: PersistedSyncLink) => {
  const { data: nodeA } = useTimeNode(data.endpointA.node);
  const { data: nodeB } = useTimeNode(data.endpointB.node);
  const { data: globalLinkOptions = {} } = useGlobalLinkOptions();
  return useMemo(() => {
    const nodeType =
      nodeA?.spec?.nodeType === 'virtual-edge' || nodeB?.spec?.nodeType === 'virtual-edge'
        ? 'virtual-edge'
        : 'timenode';

    return nodeType === 'virtual-edge'
      ? DEFAULT_LINK_OPTIONS_VIRTUAL_EDGE
      : merge(DEFAULT_LINK_OPTIONS, globalLinkOptions);
  }, [nodeA, nodeB, globalLinkOptions]);
};
