import { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sSystemApiRef } from '@netinsight/management-app-common-react';
import { SystemSoftware } from '@netinsight/management-app-common-api';

export const useSystemSoftware = () => {
  const systemApi = useApi(k8sSystemApiRef);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<SystemSoftware | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const getSystemSoftware = async () => {
      try {
        const response = await systemApi.getSystemSoftware({});
        if (response.status === 200) {
          setData(response.body);
        } else {
          setError(errorFromWrappedError(response.status, response.body));
        }
      } catch (e: any) {
        setError(e);
        return;
      }
    };
    void getSystemSoftware().then(() => setLoading(false));
  }, [systemApi]);

  return {
    data,
    error,
    loading,
  };
};
