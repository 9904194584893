import useSwr from 'swr';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sConfigApiRef, useSnackbarHelper } from '@netinsight/management-app-common-react';
import { useCallback } from 'react';
import StatusCodes from 'http-status-codes';
import { GlobalSSHConfig } from '@netinsight/management-app-common-api';

const key = 'globalSSHConfig';

export const useGlobalSSHConfigUpdate = () => {
  const configApi = useApi(k8sConfigApiRef);

  const { snackbar } = useSnackbarHelper();

  const update = async (_url: string, { arg }: { arg: GlobalSSHConfig }) => {
    const response = await configApi.setGlobalSSHConfig({ body: arg });

    if (response.status !== StatusCodes.OK) {
      throw errorFromWrappedError(response.status, response.body);
    }
  };

  return useSwrMutation(key, update, {
    throwOnError: false, // handling error in onError
    onSuccess: () => {
      snackbar.notifySuccess('Update SSH keys');
    },
    onError: error => {
      snackbar.notifyError('update SSH keys', error.response, null);
    },
  });
};

export function useGlobalSSHConfig() {
  const configApi = useApi(k8sConfigApiRef);

  const fetcher = useCallback(async () => {
    const result = await configApi.getGlobalSSHConfig();
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, (result as any).body);
    }

    return result.body;
  }, [configApi]);

  return useSwr<GlobalSSHConfig>(key, fetcher);
}
